import React, { useState } from "react";
import Aside from "../modules/SideNav/Aside";
import Complaints from "../components/Help/Complaints";

const ComplaintsLayout = () => {

    const rtl = false;

    const [toggled, setToggled] = useState(false);
  
    const handleToggleSidebar = (value) => {
      setToggled(value);
    };
  return (
    <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
      <Aside
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />
       <Complaints handleToggleSidebar={handleToggleSidebar} />
    </div>
  )
}

export default ComplaintsLayout