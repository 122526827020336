import React, { useState, useEffect, useRef } from "react";
import config from "../../config/aws-exports";
import { API, Auth, JS } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Image from "react-bootstrap/Image";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import assessStyle from "./Assessment.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  TextareaAutosize,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
  Grid,
  Modal,
  Button,
  Box,
} from "@material-ui/core";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import Skeleton from "@material-ui/lab/Skeleton";
import { green } from "@material-ui/core/colors";
import { useFormik } from "formik";
import { Constants } from "../../config/constants";
import axios from "axios";
import Reject from "../../assets/svgjs/Reject";
import Done from "../../assets/svgjs/Done";

import "./Assign.scss";
import { each } from "jquery";
import swal from "sweetalert";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Assessment = ({ courseId, progId }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: "80%",
      backgroundColor: "transparent",
      padding: 0,
      height: "auto",
      overflowY:"auto",
      '@media (max-width: 520px)': {
        width: "90%",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    root: {
      width: "100%",
      backgroundColor: "transparent",
      "& .MuiBox-root": {
        margin: "-7px",
        padding: "8px",
        backgroundColor: "white",
      },
    },
  }));
  //const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD h:mm:ss a'));
  const [formatCurDate, setFormatCurDate] = useState(0);
  //const [time, setTime] = useState('20:00:00');
  //const [finishedAssessment, setFinishedAssessment] = useState(false);
  const [assesID, setAssesID] = useState(0);
  const [assessmentData, setAssessmentData] = useState([]);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userReponse, setUserReponse] = useState([]);
  const [score, setScore] = useState(0);
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [btnClick, setBtnClick] = useState(false);
  const [assess, setAssess] = useState({});
  const [assessAns, setAssessAns] = useState({});
  const [imgUrl, setImgUrl] = useState("");
  const [correct, setCorrect] = useState(false);
  const [submitDis, setSubmitDis] = useState(true);
  const [spin, setSpin] = useState(false);
  const [instructions, setInstructions] = useState([]);
  const [hoursv, setHoursv] = useState("");
  const [minutev, setMinutev] = useState("");
  const [secv, setSecv] = useState("");
  const [qsubmitted, setQsubmitted] = useState(false);
  const setIntervalRef = useRef(0);
  const [isLoading, setIsLoading] = useState(true);
  const [intervaltimer, setIntervaltimer] = useState();
  const [timerDelay, setTimerDelay] = useState(1000);
  const [textD, setTextD] = useState("");
  const apiTime = useRef("");

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [checkboxes, setCheckboxes] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getAssessmentData(userDetails);
    // formatDate();
    // getTimerModal();
    getTime();
    let sdata = { ...userDetails };

    dispatch(awsSignIn(sdata));
  }, []);
  const formik = useFormik({
    initialValues: {
      response: "",
      file: null,
    },

    onSubmit: (values, { setSubmitting, resetForm }) => {
      async function getPreSignedUrl(value) {
        const jwttoken = (await Auth.currentSession()).idToken.jwtToken;

        console.log(jwttoken);
        var folder = "assessment";
        console.log(assess);
        const bodyParam = {
          body: {
            type: "workbook",
            wbtype: "js",
            filename: value.file.name,
            filetype: value.file.type,
            oid: config.aws_org_id,
            eid: userDetails.eid,
            quizid: courseId,
            assessid: assess.assessid,
            folder: folder,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: jwttoken,
          },
        };
        console.log(bodyParam.body);
        try {
          const response = await API.post(
            config.aws_cloud_logic_custom_name,
            Constants.GET_PRESIGNED_URL,
            bodyParam
            //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
          );

          console.log(response);
          console.log(value.file.name);
          fileUpload(value.file, response);
          console.log(value.file);
          quizQuestion[currentQuestion].orgname = value.file.name;
          quizQuestion[currentQuestion].name = value.file.name;
          setSubmitting(false);
          resetForm();
        } catch (error) {
          console.log("getCategoryError", error);
        }
      }
      getPreSignedUrl(values);
    },
  });
  async function fileUpload(file, url) {
    console.log(url);
    console.log(file);
    console.log(file.type);
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
        console.log(res);
        handleAnswerBtnClick();
      })
      .catch((err) => {
        console.error(err);
      });
  }
  async function getAssessmentData(userDetails) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        bpid: progId,
        assessid: courseId,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESSMENT,
        bodyParam
      );
      const { assessment } = response;

      setAssessmentData(assessment);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1;
  }
  function formatDate() {
    console.log("function");
    //let some = toTimestamp(moment().format('YYYY-MM-DD h:mm:ss a'));
    /*  console.log("function");
    console.log("timest", toTimestamp(moment().format("YYYY-MM-DD h:mm:ss a"))); */
    // setFormatCurDate(toTimestamp(moment().format("YYYY-MM-DD h:mm:ss a")))
    setInterval(() => {
      getTime();
    }, 10000);

    /* console.log(currentDate);
  let res = currentDate.split("-");
      if(res[1] != 0){
        res[1] = res[1] - 1;
      }
  let d = new Date(res[0], res[1], res[2]);
  setFormatCurDate(d.getTime());
  console.log("d",formatCurDate);
  console.log("date",moment(formatCurDate).format("DD/MM/YYYY  h:mm:ss a")) */
  }

  useInterval(() => {
    // Your custom logic here
    getTime();
  }, timerDelay);

  async function getTime() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        bpid: progId,
        assessid: courseId,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      //console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.INDIAN_TIME,
        bodyParam
      );
      let date = new Date().getTime();

      console.log("date", date);

      console.log("time", response);
      setFormatCurDate(response.itime);
      apiTime.current = response.itime;
      //apiTime.current = response;
      //setApiTime(response)
      if (timerDelay != response.delay) {
        console.log(response.delay);
        setTimerDelay(response.delay);
      }

      //setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  //setFormatCurDate(toTimestamp(moment().format("YYYY-MM-DD h:mm:ss a")))

  async function getTimerModal() {
    setIsLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        bpid: progId,
        assessid: courseId,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      //console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.INDIAN_TIME,
        bodyParam
      );
      console.log("time", response);
      //setFormatCurDate(toTimestamp(response));
      apiTime.current = response;
      console.log("set", apiTime.current);
      //setApiTime(response)
      setIsLoading(false);
      //setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  function timerCount(valCheck) {
    if (valCheck === undefined) {
      let interval = setInterval(() => {
        setIntervalRef.current = setIntervalRef.current - 1;
        let hours = Math.floor(setIntervalRef.current / 3600); // get hours
        let minutes = Math.floor((setIntervalRef.current - hours * 3600) / 60); // get minutes
        let seconds = setIntervalRef.current - hours * 3600 - minutes * 60;
        /* console.log("t",setIntervalRef.current) */
        hours < 10 ? setHoursv("0" + hours) : setHoursv(hours);
        minutes < 10 ? setMinutev("0" + minutes) : setMinutev(minutes);
        seconds.toFixed() < 10
          ? setSecv("0" + seconds.toFixed())
          : setSecv(seconds.toFixed());

        if (setIntervalRef.current <= 2) {
          if (valCheck === undefined) {
            //setOpen(false);
            setQsubmitted(true);
            clearInterval(intervaltimer);
          }
        }
      }, 1000);
      setIntervaltimer(interval);
    }
    if (valCheck === true) {
      clearInterval(intervaltimer);
    }
  }
  const handleOpen = (assess) => {
    // moment().format("YYYY-MM-DD h:mm:ss a")
    setOpen(true);
    setAssess(assess);
    setIntervalRef.current = (assess.enddate - new Date().getTime()) / 1000;
    getQuiz(assess);
  };

  const handleAnswerModal = (assessAns) => {
    getAssessmentAns(assessAns);
    setOpenAnswer(true);
  };

  async function getAssessmentAns(assessAnsR) {
    setAssesID(assessAnsR.assessid);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        quizid: assessAnsR.assessid,
        assessid: courseId,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESSMENT_ANSWERS,
        bodyParam
      );

      console.log("assessA", response);
      setAssessAns(response);
    } catch (error) {
      console.error(error);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setUserReponse([]);
    setQuizQuestion([]);
    setCheckboxes([]);
    setCorrect(false);
    //setAssessmentData([]);
    setCurrentQuestion(0);
    setBtnClick(false);
    // window.location.reload(true);
    //getTimerModal();
    timerCount(true);
  };
  const handleAnswerOptionClick = (correct, index) => {
    quizQuestion[currentQuestion].response = index;
    console.log(quizQuestion[currentQuestion]?.response);
    let tmpobj = {};
    tmpobj.iid = quizQuestion[currentQuestion]?.iid;
      tmpobj.response = quizQuestion[currentQuestion]?.response;
    
    let tem = [...userReponse];
    let flag = -1;
    for(let i = 0; i < tem.length; i++){
      if(tem[i].iid == tmpobj.iid){
        flag = i;
      }
    }
    if(flag == -1){
      tem.push(tmpobj);
    } else {
      tem[flag] = tmpobj;
    }
    
    setUserReponse(tem);
    setCorrect(true);
    setSubmitDis(false);
    answerSave(tem);
  };

  function setCheckbox(index, checked) {
    console.log(checkboxes);
    const newCheckboxes = [...checkboxes];

    if (newCheckboxes[index].checked === undefined) {
      newCheckboxes[index].checked = false;
    }
    newCheckboxes[index].checked = checked;
    setCheckboxes(newCheckboxes);
    let tmp = 0;
    for (let k = 0; k < checkboxes.length; k++) {
      if (
        checkboxes[k].checked !== undefined &&
        checkboxes[k].checked === true
      ) {
        tmp = 1;
        setCorrect(true);
      }
      if (tmp === 0 && k == checkboxes.length - 1) {
        setCorrect(false);
      }
    }
  }
  const handleAnswerBtnClick = () => {
    const nextQuestion = currentQuestion + 1;
    
   
      // tem.push(tmpobj);
    

    if (correct === "true" || correct === true) {
      setScore(score + 1);
    }
    // ansaddedtmp();
    if (quizQuestion.length === userReponse.length) {
      responsesave(userReponse);
    } else {
      // swal("You have not answered some questions!", {
      //   buttons: [true],
      // }).then((value) => {
      //   if (value) {
      //     responsesave(userReponse);
      //   }
      // });
      swal({
        title: "You have not answered some questions!",
        // text: "You have not answered some questions!",
        icon: "warning",
        dangerMode: true,
    })
        .then(val => {
            if (val) {
              // responsesave(userReponse);
            }
        });
    }
    // responsesave(userReponse);
//     if(userReponse.length != 0){
//       responsesave(userReponse);
//     } else {
// alert('Please answer atleast one question!');
//     }
    
    if (nextQuestion < quizQuestion.length) {
      // setCurrentQuestion(nextQuestion);
      if (
        quizQuestion[nextQuestion].atype == 3 ||
        quizQuestion[nextQuestion].atype == 5
      ) {
        setCheckboxes(quizQuestion[nextQuestion].iopts);
      }

      let tem = [...userReponse];
    let flag = -1;
    for(let i = 0; i < tem.length; i++){
      
      if(nextQuestion < quizQuestion.length && tem[i].iid == quizQuestion[nextQuestion].iid){
        flag = tem[i];
      }
    } 
  console.log(flag)
    if(flag == -1){

      for ( let i = 0; i < quizQuestion[nextQuestion].iopts.length; i++) {
        quizQuestion[nextQuestion].iopts[i].Selected = false;
      }

    setCorrect(false);
    
    } else {
      quizQuestion[nextQuestion].response = flag.response;
      quizQuestion[
        nextQuestion
      ].iopts[flag.response].Selected = true;
      setCorrect(true);
    }

    } else {
      // handleClose();
    }
    

  // } else {
  //   // tem[flag] = tmpobj;
  //   setCurrentQuestion(nextQuestion);
  //   if (
  //     quizQuestion[nextQuestion].atype == 3 ||
  //     quizQuestion[nextQuestion].atype == 5
  //   ) {
  //     setCheckboxes(quizQuestion[nextQuestion].iopts);
  //   }
  //   setCorrect(true);
  //   quizQuestion[nextQuestion].response = flag.response;
  // quizQuestion[
  //   nextQuestion
  // ].iopts[flag.response].Selected = true;
  // }
  
  };

  const handlePrevBtnClick = () => {
    // if (correct === "true" || correct === true) {
    //   setScore(score + 1);
    // }
    // ansaddedtmp1();
    const nextQuestion = currentQuestion - 1;
console.log('Prev '+JSON.stringify(quizQuestion));
    if (nextQuestion >= 0) {
      setCurrentQuestion(nextQuestion);
      if (
        quizQuestion[nextQuestion].atype == 3 ||
        quizQuestion[nextQuestion].atype == 5
      ) {
        setCheckboxes(quizQuestion[nextQuestion].iopts);
      }

      let tem = [...userReponse];
      let flag = -1;
      for(let i = 0; i < tem.length; i++){
        if(tem[i].iid == quizQuestion[nextQuestion].iid){
          flag = tem[i];
        }
      }
      if(flag == -1){
        // tem.push(tmpobj);
        quizQuestion[nextQuestion].response = "";
        if(quizQuestion[nextQuestion]?.atype != 7 && quizQuestion[nextQuestion]?.atype != 8){
        for ( let i = 0; i < quizQuestion[nextQuestion].iopts.length; i++) {
          quizQuestion[nextQuestion].iopts[i].Selected = false;
        }}
        setCorrect(false);
      } else {
        // tem[flag] = tmpobj;

        quizQuestion[nextQuestion].response = flag.response;
        if(quizQuestion[nextQuestion]?.atype != 7 && quizQuestion[nextQuestion]?.atype != 8){
      quizQuestion[
        nextQuestion
      ].iopts[flag.response].Selected = true;
    }
      setCorrect(true);
      }

      
    } else {
      handleClose();
    }
    
  };

  const handleNextBtnClick = () => {
    // if (correct === "true" || correct === true) {
    //   setScore(score + 1);
    // }
    // ansaddedtmp1();
    const nextQuestion = currentQuestion + 1;
console.log('Next '+JSON.stringify(quizQuestion));
    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
      if (
        quizQuestion[nextQuestion].atype == 3 ||
        quizQuestion[nextQuestion].atype == 5
      ) {
        setCheckboxes(quizQuestion[nextQuestion].iopts);
      }

      let tem = [...userReponse];
      let flag = -1;
      for(let i = 0; i < tem.length; i++){
        if(tem[i].iid == quizQuestion[nextQuestion].iid){
          flag = tem[i];
        }
      }
      if(flag == -1){
        // tem.push(tmpobj);
        quizQuestion[nextQuestion].response = "";
        if(quizQuestion[nextQuestion]?.atype != 7 && quizQuestion[nextQuestion]?.atype != 8){
        for ( let i = 0; i < quizQuestion[nextQuestion].iopts.length; i++) {
          quizQuestion[nextQuestion].iopts[i].Selected = false;
        }}
        setCorrect(false);
      } else {
        // tem[flag] = tmpobj;

        quizQuestion[nextQuestion].response = flag.response;
        if(quizQuestion[nextQuestion]?.atype != 7 && quizQuestion[nextQuestion]?.atype != 8){
      quizQuestion[
        nextQuestion
      ].iopts[flag.response].Selected = true;
    }
      setCorrect(true);
      }

      
    } else {
      handleClose();
    }
    
  };

  const handleQNumBtnClick = (num) => {
    // if (correct === "true" || correct === true) {
    //   setScore(score + 1);
    // }
    // ansaddedtmp1();
    const nextQuestion = num;
console.log('QNum '+JSON.stringify(quizQuestion));
    if (nextQuestion >= 0 && nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
      if (
        quizQuestion[nextQuestion].atype == 3 ||
        quizQuestion[nextQuestion].atype == 5
      ) {
        setCheckboxes(quizQuestion[nextQuestion].iopts);
      }

      let tem = [...userReponse];
      let flag = -1;
      for(let i = 0; i < tem.length; i++){
        if(tem[i].iid == quizQuestion[nextQuestion].iid){
          flag = tem[i];
        }
      }
      if(flag == -1){
        // tem.push(tmpobj);
        quizQuestion[nextQuestion].response = "";
        if(quizQuestion[nextQuestion]?.atype != 7 && quizQuestion[nextQuestion]?.atype != 8){
        for ( let i = 0; i < quizQuestion[nextQuestion].iopts.length; i++) {
          quizQuestion[nextQuestion].iopts[i].Selected = false;
        }}
        setCorrect(false);
      } else {
        // tem[flag] = tmpobj;

        quizQuestion[nextQuestion].response = flag.response;
        if(quizQuestion[nextQuestion]?.atype != 7 && quizQuestion[nextQuestion]?.atype != 8){
      quizQuestion[
        nextQuestion
      ].iopts[flag.response].Selected = true;
    }
      setCorrect(true);
      }

      
    } else {
      handleClose();
    }
    
  };

  async function ansaddedtmp1() {
    let tmpobj = {};

    if (
      quizQuestion[currentQuestion]?.atype == 5 ||
      quizQuestion[currentQuestion]?.atype == 3
    ) {
      tmpobj.response = [];
      checkboxes.forEach(function (element, index, array) {
        if (element.checked === true || element.checked === "true") {
          tmpobj.iid = quizQuestion[currentQuestion]?.iid;
          tmpobj.response.push(index);
        }
      });
    }

    if (quizQuestion[currentQuestion]?.atype == 7) {
      tmpobj.iid = quizQuestion[currentQuestion]?.iid;
      tmpobj.response = quizQuestion[currentQuestion]?.response;
    }
    if (
      quizQuestion[currentQuestion]?.atype == 2 ||
      quizQuestion[currentQuestion]?.atype == 1
    ) {
      tmpobj.iid = quizQuestion[currentQuestion]?.iid;
      tmpobj.response = quizQuestion[currentQuestion]?.response;
    }

    if (quizQuestion[currentQuestion]?.atype == 8) {
      tmpobj.iid = quizQuestion[currentQuestion]?.iid;
      tmpobj.fname = quizQuestion[currentQuestion]?.name;
      tmpobj.orgname = quizQuestion[currentQuestion]?.orgname;
    }
    tmpobj.atype = quizQuestion[currentQuestion]?.atype;
    console.log("tmpobj" + JSON.stringify(tmpobj));
    let tem = [...userReponse];
    tem.push(tmpobj);

    // setUserReponse(tem);
    // responsesave(tem);
  }

  async function ansaddedtmp() {
    let tmpobj = {};

    if (
      quizQuestion[currentQuestion]?.atype == 5 ||
      quizQuestion[currentQuestion]?.atype == 3
    ) {
      tmpobj.response = [];
      checkboxes.forEach(function (element, index, array) {
        if (element.checked === true || element.checked === "true") {
          tmpobj.iid = quizQuestion[currentQuestion]?.iid;
          tmpobj.response.push(index);
        }
      });
    }

    if (quizQuestion[currentQuestion]?.atype == 7) {
      tmpobj.iid = quizQuestion[currentQuestion]?.iid;
      tmpobj.response = quizQuestion[currentQuestion]?.response;
    }
    if (
      quizQuestion[currentQuestion]?.atype == 2 ||
      quizQuestion[currentQuestion]?.atype == 1
    ) {
      tmpobj.iid = quizQuestion[currentQuestion]?.iid;
      tmpobj.response = quizQuestion[currentQuestion]?.response;
    }

    if (quizQuestion[currentQuestion]?.atype == 8) {
      tmpobj.iid = quizQuestion[currentQuestion]?.iid;
      tmpobj.fname = quizQuestion[currentQuestion]?.name;
      tmpobj.orgname = quizQuestion[currentQuestion]?.orgname;
    }
    tmpobj.atype = quizQuestion[currentQuestion]?.atype;
    console.log("tmpobj" + JSON.stringify(tmpobj));
    let tem = [...userReponse];
    let flag = -1;
    for(let i = 0; i < tem.length; i++){
      if(tem[i].iid == tmpobj.iid){
        flag = i;
      }
    }
    if(flag == -1){
      tem.push(tmpobj);
    } else {
      tem[flag] = tmpobj;
    }


    // setUserReponse(tem);
    responsesave(tem);
  }
  async function responsesave(jsondata) {
    setSpin(true);
    var obj = {};
    obj.response = jsondata;

    if (quizQuestion.length === jsondata.length) {
      obj.status = 1;
    } else {
      obj.status = 0;
    }

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: progId,
        cid: courseId,
        quizid: assess.assessid,
        eid: userDetails.eid,
        quizdata: obj,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log(JSON.stringify(bodyParam.body));
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      Constants.UPDATE_ASSESSMENT,
      bodyParam
    );
    console.log(response);
    setSubmitDis(true);
    setSpin(false);
    // handleClose();
    swal("Submitted successfully!");
    
    if (quizQuestion.length === jsondata.length) {
      handleClose();
    } else {

    }
  }

  async function answerSave(jsondata) {
    // setSpin(true);
    var obj = {};
    obj.response = jsondata;

    // if (quizQuestion.length === jsondata.length) {
    //   obj.status = 1;
    // } else {
    //   obj.status = 0;
    // }
    obj.status = 0;
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: progId,
        cid: courseId,
        quizid: assess.assessid,
        eid: userDetails.eid,
        quizdata: obj,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log(JSON.stringify(bodyParam.body));
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      Constants.UPDATE_ASSESSMENT,
      bodyParam
    );
    console.log(response);
    // setSubmitDis(true);
    // setSpin(false);
    // if (quizQuestion.length === jsondata.length) {
    //   handleClose();
    // } else {
      
    // }
  }

  function saveAn (quizQuestion,currentQuestion) {
    let tmpobj = {};
  tmpobj.iid = quizQuestion[currentQuestion]?.iid;
  tmpobj.type = quizQuestion[currentQuestion]?.atype;
    tmpobj.response =  quizQuestion[currentQuestion]?.response;
    console.log("desAn "+tmpobj.response);
    

    
  let tem = [...userReponse];
  let flag = -1;
  for(let i = 0; i < tem.length; i++){
    if(tem[i].iid == tmpobj.iid){
      flag = i;
    }
  }
  if(flag == -1){
    if(tmpobj.response != undefined && tmpobj.response.trim() != ""){
    tem.push(tmpobj);
    }
  } else {
    if(tmpobj.response != undefined && tmpobj.response.trim() != ""){
    tem[flag] = tmpobj;
    } else {
     tem.splice(flag,1);
    }
  }
  
  setUserReponse(tem);
  setCorrect(true);
  setSubmitDis(false);
  // setTimeout(() => answerSave(tem), 1000);
  answerSave(tem);

  }


  async function getQuiz(assess1) {
    setQsubmitted(false);
    timerCount();
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        assessid: courseId,
        quizid: assess1.assessid,
        eid: userDetails.eid,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body));

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESSMENT_QUIZ,
        bodyParam
      );

      setInstructions(assess1.instructions);
      
      if (response.qitems[0].atype == 3 || response.qitems[0].atype == 5) {
        setCheckboxes(response.qitems[0].iopts);
      }
      console.log("qitms", response.qitems);
      if (response.response !== undefined && response.response !== "") {
        var resp = JSON.stringify(response.response);

        resp = JSON.parse(resp);
        //currentQuestion = response.length  + 1;

        if (resp.status == 1) {
          setQsubmitted(true);
        } else {
          setQsubmitted(false);
        }
        resp.response = resp.response === undefined ? [] : resp.response;
        setUserReponse(resp.response );
        // setCurrentQuestion(
        //   resp.response === undefined ? 0 : resp.response.length
        // );
        //setBtnClick(true);

        let tem = [...resp.response];
        let flag = -1;
        for(let i = 0; i < tem.length; i++){
          
          if(0 < response.qitems.length && tem[i].iid == response.qitems[0].iid){
            flag = tem[i];
          }
        } 
      console.log(flag)
        if(flag == -1){
          
        setCorrect(false);
        } else {
          // response.qitems[0].response = flag.response;
          // response.qitems[
          //   0
          // ].iopts[flag.response].Selected = true;
          

      //     quizQuestion[0].response = flag.response;
      // quizQuestion[
      //   0
      // ].iopts[flag.response].Selected = true;

          // setQuizQuestion(response.qitems);
          response.qitems[0].response = flag.response;
          if (response.qitems[0]?.atype != 7 && response.qitems[0]?.atype != 8) {
            response.qitems[
              0
            ].iopts[flag.response].Selected = true;
          }
          console.log('response.qitems ' + JSON.stringify(response.qitems));
          setCorrect(true);
        }

        // if (
        //   response.qitems[resp.response.length -1].atype == 3 ||
        //   response.qitems[resp.response.length -1].atype == 5
        // ) {
        //   setCheckboxes(response.qitems[resp.response.length].iopts);
        // }
        //let img = config.aws_content_delivery_cloudfront_domain+"jssaher-resources/images/assessment-images/"+userDetails.data.bcids[0]+"/"+assess1.assessid+"/";
        const imgUrl = `https://${
          Constants.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${courseId}/${
          assess1.assessid
        }/`;
        setImgUrl(imgUrl);
        console.log(imgUrl);
        setQuizQuestion(response.qitems);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function answerPrev() {
    console.log("curr", currentQuestion);
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    // console.log("curr", currentQuestion);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < assessAns.qitems.length) {
      setCurrentQuestion(nextQuestion);
    }
    console.log("curr", currentQuestion);
  }
  function viewAns() {
    if (
      assessAns.response !== undefined &&
      assessAns.response.response !== undefined
    ) {
      let l = assessAns.response.response.length - 1;
      let questionsA = assessAns.qitems.length -1;
      if (questionsA >= currentQuestion) {

        let tem = [...assessAns.response.response];
    let flag = -1;
    for(let i = 0; i < tem.length; i++){
      if(tem[i].iid == assessAns.qitems[currentQuestion].iid){
        flag = i;
      }
    }
        if (
          flag != -1
        ) {
          if (assessAns.qitems[currentQuestion]?.atype != 7 && assessAns.qitems[currentQuestion]?.atype != 8) {
            return assessAns.qitems[currentQuestion].iopts[
              assessAns.response.response[flag].response
            ].content;
          } else {
            return assessAns?.response?.response[flag]?.response;
          }
          
        } else {
          return <div style={{ color: "red" }}> You have not answerd </div>;
        }
      } else {
        return <div style={{ color: "red" }}> You have not answerd </div>;
      }
    } else {
      return <div style={{ color: "red" }}> You have not answerd </div>;
    }
  }

  function startButtonView(assess) {
    var options = {
      timeZone: "Asia/Kolkata",
      hour12: false,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    let indianTimeZoneVal = new Date(assess.startdate).toLocaleString(
      "en-US",
      options
    );
    let endndianTimeZoneVal = new Date(assess.enddate).toLocaleString(
      "en-US",
      options
    );
    let answerFormat = new Date(assess.answerdate).toLocaleString(
      "en-US",
      options
    );

    // console.log("assess" + JSON.stringify(assess));
    // console.log("start" + indianTimeZoneVal);
    // console.log("end" + endndianTimeZoneVal);
    // console.log("ans" + answerFormat);
    // console.log("formatC" + formatCurDate);

    /* let timeformate = new Date(assess.enddate);
    var endndianTimeZoneVal = moment(timeformate).format("DD/MM/YYYY h:mm:ss"); */

    return (
      <Box p={1}>
        {formatCurDate >= indianTimeZoneVal &&
        formatCurDate <= endndianTimeZoneVal ? (
          <>
            <Button
              variant="contained"
              color="primary"
              className="startAssessmentButton"
              onClick={() => {
                handleOpen(assess);
              }}
            >
              Start
            </Button>
          </>
        ) : (
          <>
            {moment(formatCurDate).format("YYYY-MM-DD HH:mm:ss")  > moment(endndianTimeZoneVal).format("YYYY-MM-DD HH:mm:ss")   ? (
              assess.userlist !== undefined ? (
                <>
                  <Box component="div" display="inline" p={1} m={1}>
                    <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Score : {assess.userlist[0].score}
                    </p>
                  </Box>
                </>
              ) : (
                <> </>
              )
            ) : (
              <></>
            )}
          </>
        )}
        {moment(formatCurDate).format("YYYY-MM-DD HH:mm:ss")  >= moment(answerFormat).format("YYYY-MM-DD HH:mm:ss")   ? (
          <Box component="div" display="inline" p={1} m={1}>
            <p
              style={{
                fontSize: "15px",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={() => {
                handleAnswerModal(assess);
              }}
            >
              Answers
            </p>
          </Box>
        ) : null}
      </Box>
    );
  }

  //console.log("err", assessmentData);
  return (
    <div className={"container " + assessStyle.assesscon}>
      {spin === true ? <div className={assessStyle.spinLoad}><CircularProgress style={{position: "fixed" , top: "48%", left: "48%", background: "transparent", zIndex: "2001"}}/> </div>: null}
       <Modal
          disableBackdropClick
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={open}
          className={classes.modal}
          backdrop="static"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <div className={assessStyle.body}>
              {qsubmitted ? (
                <>
                  <Box
                    component="div"
                    display="inline"
                    p={1}
                    m={1}
                    className={assessStyle.closestyle1}
                  >
                    <Button
                      className={assessStyle.closebtn}
                      onClick={handleClose}
                    >
                      X
                    </Button>
                  </Box>
                  <Box
                    component="div"
                    display="inline"
                    p={1}
                    m={1}
                    style={{ "font-size": "25px" }}
                  >
                    Assessment already submitted.
                  </Box>
                </>
              ) : (
                <>
                  {btnClick ? (
                    <div className={assessStyle.app1}>
                      <>
                        <p className={assessStyle.assesstimertext}>
                          <Box
                            display="inline"
                            p={1}
                            bgcolor="background.paper"
                          >
                            {hoursv}h
                          </Box>
                          <Box
                            display="inline"
                            p={1}
                            bgcolor="background.paper"
                          >
                            {minutev}m
                          </Box>
                          <Box
                            display="inline"
                            p={1}
                            bgcolor="background.paper"
                          >
                            {secv}s
                          </Box>
                        </p>
                        <Box
                          component="div"
                          display="inline"
                          p={1}
                          m={1}
                          className={assessStyle.closestyle}
                        >
                          <Button
                            className={assessStyle.closebtn}
                            onClick={handleClose}
                          >
                            X
                          </Button>
                        </Box>

                        <div className={assessStyle.questionsection}>
                          <div className={assessStyle.questioncount}>
                            <span>Question {currentQuestion + 1}</span>/
                            {quizQuestion.length}
                          </div>
                          <div className={assessStyle.questiontext}>
                            {quizQuestion.length !== 0 ||
                            quizQuestion !== undefined ||
                            quizQuestion[currentQuestion] !== undefined ? (
                              quizQuestion[currentQuestion]?.istem ==
                              undefined ? (
                                <></>
                              ) : quizQuestion[currentQuestion]?.atype ==
                                  undefined ||
                                quizQuestion[currentQuestion]?.atype == 6 ? (
                                <></>
                              ) : (
                                <div style={{ fontSize: "15px" }}>
                                  {" "}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        quizQuestion[currentQuestion]?.istem,
                                    }}
                                  ></p>
                                </div>
                              )
                            ) : null}

                            {quizQuestion[currentQuestion]?.img ? (
                              <div className="img_container">
                                <img
                                  src={`${`https://${
                                    Constants.DOMAIN
                                  }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${courseId}/${
                                    assess.assessid
                                  }/`}${quizQuestion[currentQuestion]?.img}`}
                                  alt="item"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {quizQuestion[currentQuestion]?.atype == 7 ? (
                          <>
                            <TextareaAutosize
                              className={assessStyle.textarea}
                              rowsMax={6}
                              aria-label="maximum height"
                              placeholder="Minimum 6 rows"
                              value={quizQuestion[currentQuestion].response}
                              onChange={ (e) => {
                                setCorrect(true);
                                // setTextD(e.target.value);
                                // if(e.target.value != undefined && e.target.value.trim() != ""){
                                  quizQuestion[currentQuestion].response =
                                  e.target.value;
                               
                                
                                  // await saveAn (quizQuestion,currentQuestion,e);
                                  saveAn(quizQuestion,currentQuestion,textD);
                                // } else {
                                //   quizQuestion[currentQuestion].response =
                                //   e.target.value;
                                // }
                                  
                              }}
                              // onKeyDown={(event) => {
                              //   if (event.key === 'Enter') {
                              //     saveAn (quizQuestion,currentQuestion,textD);
                              //   }
                              // }}
                            />
                          </>
                        ) : (
                          <>
                            {quizQuestion[currentQuestion]?.atype == 1 ||
                            quizQuestion[currentQuestion]?.atype == 2 ||
                            quizQuestion[currentQuestion]?.atype == 4 ? (
                              <>
                                <div className={assessStyle.answersection}>
                                  <div
                                    className={
                                      "btn-group " +
                                      assessStyle.btngroupvertical
                                    }
                                  >
                                    {quizQuestion[currentQuestion]?.iopts.map(
                                      (answerOption, index, arrayobj) => (
                                        <>
                                          {quizQuestion[currentQuestion]
                                            .atype == 4 ? (
                                            <>
                                              <div className={assessStyle.btncheck}>
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name="options"
                                                  id={answerOption.content}
                                                />
                                                <label
                                                  className={
                                                    assessStyle.labelstyle +
                                                    " " +
                                                    assessStyle.buttonQuiz
                                                  }
                                                  for={answerOption.content}
                                                  onClick={() =>
                                                    handleAnswerOptionClick(
                                                      answerOption.correct,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <Image
                                                    src={
                                                      imgUrl + answerOption.url
                                                    }
                                                    rounded
                                                  />
                                                </label>
                                              </div>
                                            </>
                                          ) : (
                                            <div className={assessStyle.btncheck}>
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                name="options"
                                                id={answerOption.content}
                                                value={answerOption.content}
                                                checked={answerOption.Selected}
                                                onChange={() => {
                                                  for (
                                                    let i = 0;
                                                    i <
                                                    quizQuestion[
                                                      currentQuestion
                                                    ].iopts.length;
                                                    i++
                                                  ) {
                                                    quizQuestion[
                                                      currentQuestion
                                                    ].iopts[i].Selected = false;
                                                  }
                                                  answerOption.Selected = true;
                                                }}
                                              />
                                              <label
                                                className={
                                                  assessStyle.labelstyle +
                                                  " " +
                                                  assessStyle.buttonQuiz
                                                }
                                                for={answerOption.content}
                                                onClick={() =>
                                                  handleAnswerOptionClick(
                                                    answerOption.correct,
                                                    index
                                                  )
                                                }
                                              >
                                                {answerOption.content}
                                              </label>
                                              {/* <Button className={assessStyle.buttonQuiz + ' ' +className}
                                                            onClick={() => 
                                                              handleAnswerOptionClick(answerOption.correct,index)
                                                            } >
                                                            {answerOption.content}
                                                            {quizQuestion[currentQuestion]?.atype }
                                                          </Button> */}
                                            </div>
                                          )}
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {quizQuestion[currentQuestion]?.atype == 3 ||
                                quizQuestion[currentQuestion]?.atype == 5 ? (
                                  <div className={assessStyle.answersection}>
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        {quizQuestion[
                                          currentQuestion
                                        ].iopts.map(
                                          (answerOption, index, arrayobj) => (
                                            <>
                                              {quizQuestion[currentQuestion]?.atype == 5 ? (
                                                <>
                                                  <div>
                                                    <FormControlLabel
                                                      className={
                                                        assessStyle.buttonQuiz
                                                      }
                                                      value={
                                                        <Image
                                                          src={
                                                            imgUrl +
                                                            answerOption.url
                                                          }
                                                          rounded
                                                        />
                                                      }
                                                      control={
                                                        <Checkbox color="primary" />
                                                      }
                                                      label={
                                                        <Image
                                                          src={
                                                            imgUrl +
                                                            answerOption.url
                                                          }
                                                          rounded
                                                        />
                                                      }
                                                      onChange={(e) => {
                                                        setCheckbox(
                                                          index,
                                                          e.target.checked
                                                        );
                                                      }}
                                                      labelPlacement="end"
                                                    />
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <FormControlLabel
                                                    className={
                                                      assessStyle.buttonQuiz
                                                    }
                                                    value={answerOption.content}
                                                    control={
                                                      <Checkbox color="primary" />
                                                    }
                                                    label={answerOption.content}
                                                    onChange={(e) => {
                                                      setCheckbox(
                                                        index,
                                                        e.target.checked
                                                      );
                                                    }}
                                                    labelPlacement="end"
                                                  />
                                                </>
                                              )}
                                            </>
                                          )
                                        )}
                                      </FormGroup>
                                    </FormControl>
                                  </div>
                                ) : (
                                  <>
                                    {quizQuestion[currentQuestion]?.atype ==
                                    8 ? (
                                      <>
                                        <form onSubmit={formik.handleSubmit}>
                                          <div className="file-input">
                                            <input
                                              type="file"
                                              id="file"
                                              name="file"
                                              className="file"
                                              onChange={(event) => {
                                                formik.setFieldValue(
                                                  "file",
                                                  event.currentTarget.files[0]
                                                );
                                              }}
                                              multiple
                                              required
                                            />
                                            <label for="file">
                                              Select file
                                              <p className="file-name"></p>
                                            </label>
                                          </div>

                                          {/* {currentQuestion + 1 ===
                                          quizQuestion.length ? (
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              fullWidth
                                              type="submit"
                                            >
                                              {formik.isSubmitting
                                                ? "Loading..."
                                                : "Submit"}
                                            </Button>
                                          ) : (
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              fullWidth
                                              type="submit"
                                            >
                                              {formik.isSubmitting
                                                ? "Loading..."
                                                : "Next"}
                                            </Button>
                                          )} */}
                                        </form>
                                      </>
                                    ) : (
                                      <>
                                        {quizQuestion[currentQuestion]?.atype ==
                                        6 ? (
                                          <>
                                            <p
                                              className={assessStyle.matchThe}
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  quizQuestion[currentQuestion]?.istem,
                                              }}
                                            ></p>{" "}
                                            {quizQuestion[
                                              currentQuestion
                                            ].iopts.map(
                                              (
                                                answerOption,
                                                index,
                                                arrayobj
                                              ) => (
                                                <>
                                                  {quizQuestion[currentQuestion]?.atype == 4 ? (
                                                    <>
                                                      <div className={assessStyle.btncheck}>
                                                        <input
                                                          type="radio"
                                                          className="btn-check"
                                                          name="options"
                                                          id={
                                                            answerOption.content
                                                          }
                                                        />
                                                        <label
                                                          className={
                                                            assessStyle.labelstyle +
                                                            " " +
                                                            assessStyle.buttonQuiz
                                                          }
                                                          for={
                                                            answerOption.content
                                                          }
                                                          onClick={() =>
                                                            handleAnswerOptionClick(
                                                              answerOption.correct,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <Image
                                                            src={
                                                              imgUrl +
                                                              answerOption.url
                                                            }
                                                            rounded
                                                          />
                                                        </label>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div className={assessStyle.btncheck}>
                                                      <input
                                                        type="radio"
                                                        className="btn-check"
                                                        name="options"
                                                        id={
                                                          answerOption.content
                                                        }
                                                      />
                                                      <label
                                                        className={
                                                          assessStyle.labelstyle +
                                                          " " +
                                                          assessStyle.buttonQuiz
                                                        }
                                                        for={
                                                          answerOption.content
                                                        }
                                                        onClick={() =>
                                                          handleAnswerOptionClick(
                                                            answerOption.correct,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {answerOption.content}
                                                      </label>
                                                      {/* <Button className={assessStyle.buttonQuiz + ' ' +className}
                                                            onClick={() => 
                                                              handleAnswerOptionClick(answerOption.correct,index)
                                                            } >
                                                            {answerOption.content}
                                                            {quizQuestion[currentQuestion]?.atype }
                                                          </Button> */}
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>d</>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {quizQuestion[currentQuestion]?.atype == 8 ? (
                          <></>
                        ) : (
                          <>
                          
                          </>
                        )}
                        <div style={{ height: '50px', display:'flex',justifyContent:'space-evenly'}}>

                                                  
                          <Button
                              variant="contained"
                              color="primary"
                              className={assessStyle.nextbtn}
                              disabled={currentQuestion == 0}
                              onClick={() => handlePrevBtnClick()}
                            >
                              Prev
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              className={assessStyle.nextbtn}
                              disabled={(currentQuestion == quizQuestion.length - 1)}
                              onClick={() => handleNextBtnClick()}
                            >
                              Next
                            </Button>
                            {currentQuestion == quizQuestion.length - 1 ? 
                            <Button
                              variant="contained"
                              color="primary"
                              className={assessStyle.nextbtn}
                              // disabled={submitDis === true}
                              onClick={() => handleAnswerBtnClick()}
                            >
                              Submit
                              {/* {currentQuestion == quizQuestion.length - 1
                                ? "Submit"
                                : "Next"} */}
                            </Button>
                            : null}
                            </div>
                            <div style={{ display:'grid',gridTemplateColumns:'auto auto auto auto auto auto auto auto' ,justifyContent:'space-evenly'}}>
                              {quizQuestion.map((it,qi)=>{
                                let tem = [...userReponse];
                                let flag = -1;
                                for(let i = 0; i < tem.length; i++){
                                  if(tem[i].iid == quizQuestion[qi].iid){
                                    flag = tem[i];
                                  }
                                }
                                if(flag == -1){
                                  // tem.push(tmpobj);
                                  return (
                                    <div className={assessStyle.numstyle1} onClick={()=>handleQNumBtnClick(qi)}>
                                       {qi +1}
  
                                      </div>
                                  );
                                } else {
                                  // tem[flag] = tmpobj;
                          
                                  return (
                                    <div className={assessStyle.numstyle2} onClick={()=>handleQNumBtnClick(qi)}>
                                       {qi +1}
  
                                      </div>
                                  );
                                
                                }
                                
                              })}
                            </div>
                      </>
                    </div>
                  ) : (
                    <>
                      {quizQuestion.length === 0 ? (
                        <>
                          <Typography component="div" key="h2" variant="h2">
                            <Skeleton  style={{width: "20rem", height: "20rem"}}/>
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Grid container spacing={1}>
                            <Box
                              component="div"
                              display="inline"
                              p={1}
                              m={1}
                              className={assessStyle.closestyle1}
                            >
                              <Button
                                className={assessStyle.closebtn}
                                onClick={handleClose}
                              >
                                X
                              </Button>
                            </Box>
                            <Grid item xs={12} className={assessStyle.grid1}>
                              <List
                                component="nav"
                                className={classes.root}
                                aria-label="contacts"
                              >
                                <h1 className={assessStyle.heading}>
                                  Instruction
                                </h1>
                                {instructions.map((arritem) => (
                                  <>
                                    <ListItem
                                      className={assessStyle.MuiListItembutton}
                                    >
                                      <ListItemIcon>
                                        <StarOutlineIcon
                                          style={{
                                            color: green[500],
                                            height: 20,
                                          }}
                                        />
                                      </ListItemIcon>
                                      <p>{arritem}</p>
                                    </ListItem>
                                  </>
                                ))}
                              </List>
                            </Grid>
                            <Grid item xs={12} className={assessStyle.grid2}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={assessStyle.nextbtn}
                                onClick={() => {
                                  setBtnClick(true);
                                }}
                                style={{
                                  paddingBottom: "10px",
                                  marginRight: "20px",
                                }}
                              >
                                Start Quiz
                              </Button>

                              <Button
                                variant="contained"
                                color="primary"
                                className={assessStyle.nextbtn}
                                onClick={handleClose}
                              >
                                Close
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal>
      <Typography component="list" variant="h1">
        {isLoading ? <Skeleton /> : null}
      </Typography>
      <Typography component="list" variant="h1">
        {isLoading ? <Skeleton /> : null}
      </Typography>
      <div className={assessStyle.unitAssesment} style={{ width: "100%" }}>
        {assessmentData === undefined || assessmentData.length === 0 ? (
          <>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "center",
                margin: "revert",
              }}
            >
              No Assessments yet
            </p>
          </>
        ) : (
          assessmentData.map((assess) => (
            <div className={assessStyle.assesslist}>
              <p
                className={assessStyle.assessmentHeader}
                alignItems="flex-start"
                style={{
                  fontSize: "16px",
                  marginLeft: "18px",
                  marginTop: "10px",
                }}
              >
                {assess.title}
              </p>

              <Box display="flex" p={1}>
                <Box p={1} flexGrow={1}>
                  <p className={assessStyle.startAssessment}>
                    Start :{" "}
                    {moment(assess.startdate).format("DD/MM/YYYY  h:mm:ss a")}
                  </p>
                  <p className={assessStyle.endAssessment}>
                    End :{" "}
                    {moment(assess.enddate).format("DD/MM/YYYY  h:mm:ss a")}
                  </p>
                </Box>
                {startButtonView(assess)}
              </Box>
            </div>
          ))
        )}
       
        
      </div>
      <Modal
          disableBackdropClick
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={openAnswer}
          className={classes.modal}
          backdrop="static"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <div className={assessStyle.bodyAns}>
              {/*  <pre style={{ color: "white" }}>
                {quizQuestion === undefined || quizQuestion.length === 0 ? (
                  <p>p </p>
                ) : quizQuestion.istem === undefined ? null : (
                  <pre>
                    {console.log("ll", quizQuestion[currentQuestion]?.istem)}
                  </pre>
                )}
              </pre> */}
              {assessAns.qitems === undefined ||
              assessAns.qitems.length === 0 ? (
                <div style={{ fontSize: "15px" }}>Loading.. </div>
              ) :  (
                <div>
                  <div className={assessStyle.questionsection}>
                    <div
                      style={{
                        fontWeight: "bold",
                        float: "right",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenAnswer(false);
                        setCurrentQuestion(0);

                        setAssessAns([]);
                      }}
                    >
                      {" "}
                      X{" "}
                    </div>
                    <div className={assessStyle.questioncount}>
                      <span>Question {currentQuestion + 1}</span>/
                      {assessAns.qitems.length}
                    </div>
                    <div className={assessStyle.questiontext}>
                      <div style={{ fontSize: "15px" }}>
                        {" "}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: assessAns.qitems[currentQuestion].istem,
                          }}
                        ></p>
                      </div>
                      {/* {assessAns.qitems[currentQuestion].istem} */}
                      {assessAns.qitems[currentQuestion].img ? (
                        <div className="img_container">
                          <img
                            src={`${`https://${
                              Constants.DOMAIN
                            }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${courseId}/${assesID}/`}${
                              assessAns.qitems[currentQuestion].img
                            }`}
                            alt="item"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={assessStyle.answersection}>
                 { assessAns.qitems[currentQuestion].atype == "2" ?(
                    <div
                      className={"btn-group " + assessStyle.btngroupvertical}
                    >
                      {assessAns.qitems[currentQuestion].iopts.map(
                        (answerOption, index, arrayobj) => (
                          <>
                            <div style={{}} className={assessStyle.btncheck}>
                              <div>
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="options"
                                  id={answerOption.content}
                                />
                                <div style={{ display: "flex" }}>
                                  <label
                                    className={
                                      assessStyle.labelstyleAns +
                                      " " +
                                      assessStyle.buttonQuizAns
                                    }
                                    for={answerOption.content}
                                  >
                                    {answerOption.content}
                                  </label>
                                  <span>
                                    {answerOption.correct === "true" ||
                                    answerOption.correct === true ? (
                                      <Done
                                        className="right_size"
                                        cls1="cls1_D"
                                        cls2="cls2_D"
                                      />
                                    ) : (
                                      <Reject
                                        className="Wrong_size"
                                        cls1="cls1_D"
                                        cls2="cls2_D"
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                 ):null}
                  </div>
                  <br />

                  <div className={assessStyle.yourAnswer}>
                    Your Answer :{" "}
                    <div className={assessStyle.yourAnswerScore}>
                      {
                        viewAns()
                        /*  */
                      }
                    </div>
                  </div>
                  {currentQuestion > 0 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      className="btn-size"
                      onClick={() => answerPrev()}
                      style={{ fontSize: "12px" }}
                    >
                      Prev
                    </Button>
                  ) : null}
                  {currentQuestion + 1 === assessAns.qitems.length ? (
                    <div></div>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      className="btn-siz"
                      onClick={() => answerNext()}
                      style={{ float: "right", fontSize: "12px" }}
                    >
                      Next 
                    </Button>
                  )}
                </div>
              ) 
              // : (
              //   <div>
              //     <div
              //       style={{
              //         fontWeight: "bold",
              //         float: "right",
              //         fontSize: "14px",
              //         cursor: "pointer",
              //       }}
              //       onClick={() => {
              //         setOpenAnswer(false);
              //         setCurrentQuestion(0);
              //         setAssessAns([]);
              //       }}
              //     >
              //       {" "}
              //       X{" "}
              //     </div>
              //     <div style={{}}>
              //       <p
              //         style={{
              //           textAlign: "center",
              //           fontSize: "18px",
              //         }}
              //       >
              //         {" "}
              //         No Answers
              //       </p>
              //     </div>
              //   </div>
              // )
              }
            </div>
          </div>
        </Modal>
      <hr />
    </div>
  );
};

export default Assessment;
