import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import UserHeader from "../Header/UserHeader/UserHeader";
import { authData } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Announcements from "../../assets/svgjs/Announcements";
import PdfIcon from "../../assets/svgjs/Pdf";
import Video from "../../assets/svgjs/Video";
import Audio from "../../assets/svgjs/Audio";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ImageIcon from "@material-ui/icons/Image";
import { DialogTitle, useMediaQuery } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ReactPlayer from "react-player";
import dashboardStyle from "../../components/HomeScreen/Dashboard/Dashboard.module.scss";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .slick-arrow": {
      color: "black",
    },
    "& .slick-prev:before": {
      color: "black",
      fontSize: 27,
    },
    "& .slick-next:before": {
      color: "black",
      fontSize: 27,
    },
    "& .MuiGrid-root": {},
    "& .MuiGrid-item": {},
    "& .MuiGrid-grid-xs-6": {},
  },
  paperAnnounce: {
    textAlign: "start",
    color: theme.palette.text.secondary,
    width: "100%",
  },
  paperEvents: {
    textAlign: "start",
    color: theme.palette.text.secondary,
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      height: "80%",
      width: "90%",
      padding: "50px 0px 0px",
      "@media (min-height: 800px)": {
        height: "72rem",
      },
    },
  },
  content: {
    ".MuiDialogContent-root": {
      overflowY: "hidden",
    },
  },
  mainGrid: {
    width: "100%",
    margin: 0,
    "@media (max-width: 520px)": {
      "& > div": {
        padding: "15px!important",
      },
    },
  },
  listContents: {
    marginLeft: "1rem",
    justifyContent: "left",
    "& > p": {
      overflow: "auto",
    },
  },
  modalHeading: {
    position: "absolute",
    top: "0",
    left: "30px",
    maxWidth: "800px",
    "@media (max-width: 520px)": {
      padding: "16px 0px",
    },
  },
}));
const Announcement = ({ handleToggleSidebar }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [announcementsAll, setAnnouncementsAll] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [obj, setObj] = useState({});

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  let userDetails = useSelector(authData);
  useEffect(() => {
    getAnnouncements();
  }, []);

  const classes = useStyles();

  async function getAnnouncements() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.data.bpids,
        rtype: "getall",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.POST_ANNOUNCEMENT,
        bodyParam
      );
      const AnnouncementsJSON = response.Response;
      setAnnouncementsAll(AnnouncementsJSON);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (panel) => (isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function programNameAnc(prog) {
    for (let i = 0; i < userDetails.data.bpdata.length; i++) {
      if (announcementsAll[prog].tid === userDetails.data.bpdata[i].bpid) {
        return <h2>{userDetails.data.bpdata[i].ptitle}</h2>;
      }
    }
  }

  const handleClickOpen = (full) => {
    setOpen(true);
    setObj(full);
  };

  function renderObjects(curObject) {
    let objType = curObject.type;
    switch (objType) {
      case "video":
        return VideoView(curObject); // loadVideoView(curObject);
      case "audio":
        return AudioView(curObject); // loadAudioView(curObject);
      case "pdf":
        return PdfView(curObject);
      case "image":
        return imageView(curObject); // loadMultimediaView(curObject);
      case "html":
        return WebView(curObject); // loadMultimediaView(curObject);
      case "Interactivity" || "interactivity":
        return WebView(curObject); // loadInteractivityView(curObject);

      case "vimeo":
        return VimeoView(curObject); // loadVimeoView(curObject);
      case "youtube":
        return youtubeView(curObject); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }
  function VideoView(curObject) {
    return (
      <div
        className="player-wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          height: "90%",
          alignItems: "center",
        }}
      >
        <ReactPlayer
          url={`https://${
            Constants.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${
            curObject.mfname
          }`}
          controls={true}
          width="100%"
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    );
  }

  function AudioView(curObject) {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={`https://${
              Constants.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${
              curObject.mfname
            }`}
            controls={true}
          />
        </div>
      </div>
    );
  }

  function VimeoView(curObject) {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "1000px",
            height: "600px",
          }}
        >
          <ReactPlayer
            url={
              curObject.otype !== "vimeo"
                ? `https://youtu.be/${curObject.embeddedcode}`
                : `https://player.vimeo.com/video/${curObject.embeddedcode}?title=0&loop=0`
            }
            controls={true}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    );
  }

  function youtubeView(curObject) {
    return (
      <div
        className="youtube-wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          height: "660px",
          width: "1100px",
          alignItems: "center",
        }}
      >
        <ReactPlayer
          url={`https://youtu.be/${curObject.embeddedcode}`}
          controls={true}
          width="100%"
          height="100%"
          style={{ paddingTop: "10px", paddingBottom: "5px" }}
        />
      </div>
    );
  }

  function PdfView(curObject) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <iframe
          src={`https://${
            Constants.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${
            curObject.mfname
          }`}
          height="100%"
          width="100%"
          title="pdf"
        />
      </div>
    );
  }
  function imageView(curObject) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <iframe
          src={`https://${
            Constants.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${
            curObject.mfname
          }`}
          height="100%"
          width="100%"
          title="pdf"
        />
      </div>
    );
  }
  function WebView(curObject) {
    return (
      <div
        style={{
          display: "flex",
          width: "1100px",

          height: "660px",
          justifyContent: "center",
        }}
      >
        <iframe
          src={`https://${
            Constants.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${
            curObject.mfname
          }`}
          width={"100%"}
          height="100%"
          title="WebView"
        />
      </div>
    );
  }

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div>
        <div>
          <UserHeader />
        </div>

        <div>
          <div className={classes.root}>
            <h2
              style={{
                marginBottom: "30px",
                color: "#3372B5",
                fontWeight: "bold",
                fontSize: "19px",
              }}
            >
              Announcements
            </h2>
            <Paper className={classes.paperAnnounce}>
              <Typography component="list" variant="h1">
                {isLoading ? <Skeleton /> : null}
              </Typography>
              <Typography component="list" variant="h1">
                {isLoading ? <Skeleton /> : null}
              </Typography>
              <Typography component="list" variant="h1">
                {isLoading ? <Skeleton /> : null}
              </Typography>
              <Typography component="list" variant="h1">
                {isLoading ? <Skeleton /> : null}
              </Typography>

              {announcementsAll.length !== 0 ? (
                <div>
                  {Object.keys(announcementsAll).map((prog, idx) => (
                    <Accordion
                      key={idx}
                      expanded={expanded === `${idx}`}
                      onChange={handleChange(`${idx}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {programNameAnc(prog)}
                        </Typography>
                      </AccordionSummary>
                      {announcementsAll[prog].json.map((full) => (
                        <div>
                          {full.type === "text" ? (
                            <div>
                              <ListItem alignItems="flex-start">
                                <div>
                                  <Announcements
                                    className={dashboardStyle.announcementIcon}
                                  />
                                </div>
                                <ListItemText
                                  className={classes.listContents}
                                  primary={
                                    <p
                                      style={{
                                        fontSize: "small",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      {moment(full.sdate * 1000).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  }
                                  secondary={
                                    <React.Fragment
                                      styles={{ overflow: "auto" }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "medium",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {full.title}
                                      </p>
                                      <br />
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                      >
                                       

                                        <p
                                          style={{
                                            fontSize: "medium",
                                            whiteSpace: "pre-line",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: full.description,
                                          }}
                                        ></p>
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                              <hr style={{ marginBottom: "20px" }} />
                            </div>
                          ) : null || full.type === "file" ? (
                            <div>
                              <ListItem alignItems="flex-start">
                                <div>
                                  {" "}
                                  <Announcements
                                    className={dashboardStyle.announcementIcon}
                                  />{" "}
                                </div>
                                <ListItemText
                                  className={classes.listContents}
                                  primary={
                                    <p
                                      style={{
                                        fontSize: "small",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      {moment(full.sdate * 1000).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <p
                                        style={{
                                          fontSize: "medium",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {full.title}
                                      </p>
                                      <br />
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                      >
                                        <p
                                          style={{
                                            fontSize: "medium",
                                            whiteSpace: "pre-line",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: full.description,
                                          }}
                                        ></p>

                                        <span
                                          onClick={() => {
                                            setOpen(true);
                                            setObj(full);
                                          }}
                                          style={{
                                            fontSize: "small",
                                            float: "right",
                                          }}
                                        >
                                          <PdfIcon
                                            className={dashboardStyle.pdfIcon}
                                          />
                                        </span>
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                              <hr style={{ marginBottom: "20px" }} />
                            </div>
                          ) : null || full.type === "web" ? (
                            <div>
                              <ListItem alignItems="flex-start">
                                <div>
                                  <Announcements
                                    className={dashboardStyle.announcementIcon}
                                  />{" "}
                                </div>
                                <ListItemText
                                  className={classes.listContents}
                                  primary={
                                    <p
                                      style={{
                                        fontSize: "small",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      {moment(full.sdate * 1000).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <p
                                        style={{
                                          fontSize: "medium",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {full.title}
                                      </p>
                                      <br />
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                      >
                                        <p style={{ fontSize: "medium" }}>
                                          {full.webtext}{" "}
                                        </p>
                                        <p style={{ fontSize: "medium" }}>
                                          Web url :{" "}
                                          <a href={full.weburl}>
                                            {full.weburl}
                                          </a>
                                        </p>
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                              <hr style={{ marginBottom: "20px" }} />
                            </div>
                          ) : null || full.type === "video" ? (
                            <div>
                              <ListItem
                                style={{ cursor: "pointer" }}
                                alignItems="flex-start"
                              >
                                <div>
                                  {" "}
                                  <Announcements
                                    className={dashboardStyle.announcementIcon}
                                  />{" "}
                                </div>
                                <ListItemText
                                  className={classes.listContents}
                                  primary={
                                    <p
                                      style={{
                                        fontSize: "small",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      {moment(full.sdate * 1000).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <p
                                        style={{
                                          fontSize: "medium",
                                          marginTop: "10px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span>{full.title}</span>
                                        <span
                                          onClick={() => {
                                            handleClickOpen(full);
                                          }}
                                        >
                                          <Video
                                            className="video-size"
                                            cls1="cls-1--v"
                                            cls2="cls-2--v"
                                          />
                                        </span>
                                      </p>
                                      <br />
                                      <p>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          color="textPrimary"
                                        >
                                          <p
                                            style={{
                                              fontSize: "medium",
                                              whiteSpace: "pre-line",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: full.description,
                                            }}
                                          ></p>
                                        </Typography>
                                      </p>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                              <hr style={{ marginBottom: "20px" }} />
                            </div>
                          ) : null || full.type === "audio" ? (
                            <div>
                              <ListItem
                                style={{ cursor: "pointer" }}
                                alignItems="flex-start"
                              >
                                <div>
                                  <Announcements
                                    className={dashboardStyle.announcementIcon}
                                  />
                                </div>
                                <ListItemText
                                  className={classes.listContents}
                                  primary={
                                    <p
                                      style={{
                                        fontSize: "small",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      {moment(full.sdate * 1000).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <p
                                        style={{
                                          fontSize: "medium",
                                          marginTop: "10px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span>{full.title}</span>
                                        <span
                                          onClick={() => {
                                            handleClickOpen(full);
                                          }}
                                        >
                                          <Audio
                                            className="video-size"
                                            cls1="cls-1--v"
                                            cls2="cls-2--v"
                                          />
                                        </span>
                                      </p>
                                      <br />
                                      <p>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          color="textPrimary"
                                        >
                                          <p
                                            style={{
                                              fontSize: "medium",
                                              whiteSpace: "pre-line",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: full.description,
                                            }}
                                          ></p>
                                        </Typography>
                                      </p>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                              <hr style={{ marginBottom: "20px" }} />
                            </div>
                          ) : null || full.type === "image" ? (
                            <div>
                              <ListItem
                                style={{ cursor: "pointer" }}
                                alignItems="flex-start"
                              >
                                <div>
                                  {" "}
                                  <Announcements
                                    className={dashboardStyle.announcementIcon}
                                  />{" "}
                                </div>
                                <ListItemText
                                  className={classes.listContents}
                                  primary={
                                    <p
                                      style={{
                                        fontSize: "small",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      {moment(full.sdate * 1000).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <p
                                        style={{
                                          fontSize: "medium",
                                          marginTop: "10px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span>{full.title}</span>
                                        <span>
                                          <ImageIcon
                                            style={{
                                              fill: "#f18121",
                                              height: "2.5rem",
                                            }}
                                            onClick={() => {
                                              handleClickOpen(full);
                                            }}
                                          />
                                        </span>
                                      </p>
                                      <br />
                                      <p>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          color="textPrimary"
                                        >
                                          <p
                                            style={{
                                              fontSize: "medium",
                                              whiteSpace: "pre-line",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: full.description,
                                            }}
                                          ></p>
                                        </Typography>
                                      </p>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                      ></Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                              <hr style={{ marginBottom: "20px" }} />
                            </div>
                          ) : null || full.type === "pdf" ? (
                            <div>
                              <ListItem
                                style={{ cursor: "pointer" }}
                                alignItems="flex-start"
                              >
                                <div>
                                  <Announcements
                                    className={dashboardStyle.announcementIcon}
                                  />
                                </div>
                                <ListItemText
                                  className={classes.listContents}
                                  primary={
                                    <p
                                      style={{
                                        fontSize: "small",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      {moment(full.sdate * 1000).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <p
                                        style={{
                                          fontSize: "medium",
                                          marginTop: "10px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span>{full.title}</span>
                                        <span
                                          onClick={() => {
                                            handleClickOpen(full);
                                          }}
                                        >
                                          <PdfIcon className="pdfAct" />
                                        </span>
                                      </p>
                                      <br />
                                      <p>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          color="textPrimary"
                                        >
                                          <p
                                            style={{
                                              fontSize: "medium",
                                              whiteSpace: "pre-line",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: full.description,
                                            }}
                                          ></p>
                                        </Typography>
                                      </p>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                              <hr style={{ marginBottom: "20px" }} />
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </Accordion>
                  ))}
                </div>
              ) : (
                <div>
                  <Typography component="list" variant="h1">
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <p
                        style={{
                          fontSize: "medium",
                          textAlign: "center",
                          padding: "1rem",
                        }}
                      >
                        No Announcements
                      </p>
                    )}
                  </Typography>
                </div>
              )}
            </Paper>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              className={classes.paper}
            >
              <div>
                <DialogTitle className={classes.modalHeading}>
                  {obj.title}
                </DialogTitle>
                <IconButton
                  style={{ position: "absolute", top: "0", right: "0" }}
                  component="span"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <DialogContent className={classes.content}>
                {obj.type != "pdf" ? (
                  <p
                    style={{
                      fontSize: "12px",
                      whiteSpace: "pre-line",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: obj.description,
                    }}
                  ></p>
                ) : null}
                {renderObjects(obj)}
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Announcement;
