import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { saveAs } from "file-saver";
import { setCookie } from "react-use-cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const DownloadDocs = ({ handleAddClose, docFileName, uploadType, data, docCurlFileName }) => {
  const classes = useStyles();
  const userDetails = useSelector(authData);

  const handleDownload = async (sData) => {
    if (data !== "") {
      const expires = new Date().getTime() + 60 * 60 * 1000;
      setCookie("CloudFront-Expires", expires);
      setCookie("CloudFront-Policy", data.Policy);
      setCookie("CloudFront-Signature", data.Signature);
      setCookie("CloudFront-Key-Pair-Id", data.KeyPairId);
    }

    if (uploadType !== "markscardlist") {
      const downloadUrl = `https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/profile/${userDetails.eid}/${uploadType}/${sData}`;
      saveAs(downloadUrl, sData);
    } else {
      try {
        const response = await fetch(docCurlFileName);
        if (!response.ok) {
          throw new Error("Network response was not ok: " + response.statusText);
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = sData;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
      }
    }

    handleAddClose();
  };

  return (
    <div
      style={{
        width: "80%",
        background: "transparent",
        backgroundColor: "white",
        display: "grid",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "max-content",
        }}
      >
        <h3>{docFileName}</h3>
        <button onClick={handleAddClose}>X</button>
      </div>
      <div style={{ textAlign: "center", margin: "20px" }}>
        <button
          style={{ fontSize: "16px", fontWeight: "bold" }}
          onClick={() => handleDownload(docFileName)}
        >
          Click Here to Download The File
        </button>
      </div>
    </div>
  );
};

export default DownloadDocs;
