import React, { useState, useEffect } from "react";
import { API, JS } from "aws-amplify";
import Complaints from "./Complaints";
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UserHeader from "../Header/UserHeader/UserHeader";
import { FaBars } from "react-icons/fa";
import { Backdrop, makeStyles, CircularProgress } from "@material-ui/core";
import axios from "axios";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  "& > *": {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Help = (handleToggleSidebar) => {
  let userDetails = useSelector(authData);
  let navigate = useNavigate();
  const classes = useStyles();
  const [programName, setProgramName] = useState([]);
  const [bpid, setbpid] = useState("");
  const [istLoading, setIstLoading] = useState(false);
  const [title, setTitel] = useState("");
  const [description, setDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [pgmErr, setPgmErr] = useState("");

  useEffect(() => {
    //alert(JSON.stringify(userDetails))
    get();
    console.log("userDetails", userDetails);
    //alert(JSON.stringify(userDetails));
  }, []);

  async function get() {
    setIstLoading(true);
    let userdata = { ...userDetails };

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userdata.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_PROGRESS,
        bodyParam
      );
      console.log("response", response.bpdata);
      setProgramName(response.bpdata);

      setIstLoading(false);
    } catch (error) {
      //alert(JSON.stringify(error))
      console.error(error);
    }
  }

  const handleOptionChange = (event) => {
    setbpid(event.target.value);
  };

  const onChangeHandlerDescription = (event) => {
    setDescription(event.target.value);
  };

  async function handleFile(e) {
    if (e.target.files !== undefined && e.target.files[0]) {
      //setPIssueTextUpload("");
      let file = e.target.files[0];
      let fileUploadType = "complents";
      uploadDocsToServer(file, fileUploadType);
      setFileName(file.name);
      console.log("file Name", file.name);
    }
  }

  async function uploadDocsToServer(file, fileUploadType) {
    // setSpin(true);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        email: userDetails.email,
        type: fileUploadType,
        filetype: file.type,
        filename: file.name,
        bpid: bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      console.log(" bodyParam", bodyParam);

      fileUpload(file, response, fileUploadType);
    } catch (error) {
      // setSpin(false);
      console.log("getCategoryError", error);
    }
  }

  async function fileUpload(file, url, fileUploadType) {
    // setSpin(true);
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
        console.log("fileUploadType", fileUploadType);
        //  console.log(profile, "fileUpload==", res);
        //  setSpin(false);
      })
      .catch((err) => {
        console.error(err);
        //setSpin(false);
      });
  }

  async function handleSubmit() {
    let formIsValid = true;

    if (
      description === "" ||
      description === null ||
      (description != undefined && description.trim() === "")
    ) {
      formIsValid = false;
      setDescriptionErr("*Please Enter the issue description.");
      return;
    }

    if (
      bpid === "" ||
      bpid === null ||
      (bpid != undefined && bpid.trim() === "")
    ) {
      formIsValid = false;
      setPgmErr("*Please Select the Program.");
      return;
    }

    if (formIsValid == true) {
      //alert("ok");
      addcomplent();
    }

    async function addcomplent() {
      setIstLoading(true);
      const bodyParam = {
        body: {
          description: description,
          bpid: bpid,
          oid: config.aws_org_id,
          fileName: fileName,
          emailid: userDetails.email,
          eid: userDetails.eid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        let response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/addcomplent",
          bodyParam
        );
        setIstLoading(false);
        swal({
          title: "Success",
          text: "Submitted successfully",
          icon: "success",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            //setSpin(false);
          }
        });
        console.log("response", response.bpdata);
      } catch (error) {
        //alert(JSON.stringify(error))
        console.error(error);
      }

      navigate("/complaints");
    }
  }
  const fileInputStyle = {
    display: "none",
  };

  const fileButtonStyle = {
    display: "inline-flex",
    alignItems: "center",
    padding: "0px 15px",
    backgroundColor: "rgb(38 104 183)",
    color: "white",
    cursor: "pointer",
    border: "none",
    borderRadius:"4px",
  };

  const fileIconStyle = {
    marginRight: "8px",
  };

  
  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div>
        <div>
          <UserHeader />
        </div>
        <h3
          style={{
            color: "black",
            fontSize: "20px",
            paddingTop: "30px",
            paddingBottom: "-10px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          Report A Query
        </h3>
        <hr
          style={{
            width: "14.5rem",
            margin: "auto",
            borderColor: "#3372b5",
            borderWidth: "3px",
            marginBottom:"4rem"
          }}
        />

        {istLoading && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <div
          className="liststyle"
          align="left"
          style={{ margin: "0rem auto 9rem" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3rem",
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <h3
              style={{
                flexBasis: "30%",
                fontSize: "1.6rem",
                fontWeight: "500",
              }}
            >
              Select A Program:
            </h3>
            <select
              className="boxselect"
              onChange={(e) => handleOptionChange(e)}
              style={{
                flexGrow: "1",
                height: "30px",
                outline: "none",
                fontSize: "1.5rem",
                paddingLeft: "1rem",
                border: "1px solid #01498e",
                boxShadow: "0px 0px 5px 0px lightgrey",
              }}
            >
              <option value="">--Select--</option>
              {programName.map((pgm, index) => (
                <option key={index} value={pgm.bpid}>
                  {pgm.ptitle}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3rem",
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <h3
              style={{
                flexBasis: "30%",
                fontSize: "1.6rem",
                fontWeight: "500",
              }}
            >
              Query Description:
            </h3>
            <textarea
              type="text"
              onChange={onChangeHandlerDescription}
              value={description}
              style={{
                flexGrow: "1",
                height: "200px",
                outline: "none",
                fontSize: "1.5rem",
                paddingLeft: "1rem",
                border: "1px solid #01498e",
                boxShadow: "0px 0px 5px 0px lightgrey",
              }}
              // defaultValue={title}
              // onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div
            className="User_field"
            style={{
              display: "flex",
              // justifyContent: "space-between",
              marginBottom: "3rem",
            }}
          >
            <h3
              style={{
                flexBasis: "35%",
                fontSize: "1.6rem",
                fontWeight: "500",
              }}
            >
              Upload File :
            </h3>

            <label htmlFor="file" style={fileButtonStyle}>
              Choose File
              <input
                type="file"
                id="file"
                accept="image/*,application/pdf,.docx,"
                style={fileInputStyle}
                onChange={(e) => {
                  handleFile(e);
                }}
              />
            </label>

            {fileName != "" || fileName != undefined ? (
              <h3
                style={{
                  marginLeft: "20px",
                }}
              >
                {fileName}
              </h3>
            ) : null}
          </div>

          <div style={{ width: "100%", textAlign: "center" }}>
            <button
              style={{
                // position: "relative",
                //right: "40px",
                //top: "70px",
                color: "#3372B5",
                backgroundColor: "white",
                borderRadius: "8px",
                fontSize: "15px",
                height: "28px",
                lineHeight: "13px",
                // float: "right",
                width: "100px",
                border: "2px solid #3372B5",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          </div>

          <div
            style={{ color: "#cc0000", fontSize: "14px", textAlign: "center" }}
          >
            {pgmErr}
          </div>
          <div
            style={{ color: "#cc0000", fontSize: "14px", textAlign: "center" }}
          >
            {descriptionErr}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Help;
