import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import UserHeader from "../Header/UserHeader/UserHeader";
import "../HomeScreen/Dashboard/dashboard.scss";
import { FaBars } from "react-icons/fa";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import dashboardStyle from "../../components/HomeScreen/Dashboard/Dashboard.module.scss";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .slick-arrow": {
      color: "black",
    },
    "& .slick-prev:before": {
      color: "black",
      fontSize: 27,
    },
    "& .slick-next:before": {
      color: "black",
      fontSize: 27,
    },
    "& .MuiGrid-root": {},
    "& .MuiGrid-item": {},
    "& .MuiGrid-grid-xs-6": {},
  },
  paperAnnounce: {
    textAlign: "start",
    color: theme.palette.text.secondary,
    width: "100%",
  },
  paperEvents: {
    textAlign: "start",
    color: theme.palette.text.secondary,
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      height: "80%",
      width: "90%",
      padding: "50px 0px 0px",
      "@media (min-height: 800px)": {
        height: "72rem",
      },
    },
  },
  content: {
    ".MuiDialogContent-root": {
      overflowY: "hidden",
    },
  },
  mainGrid: {
    width: "100%",
    margin: 0,
    "@media (max-width: 520px)": {
      "& > div": {
        padding: "15px!important",
      },
    },
  },
  listContents: {
    marginLeft: "1rem",
    justifyContent: "left",
    "& > p": {
      overflow: "auto",
    },
  },
  modalHeading: {
    position: "absolute",
    top: "0",
    left: "30px",
    maxWidth: "800px",
    "@media (max-width: 520px)": {
      padding: "16px 0px",
    },
  },
}));

const Test = ({ handleToggleSidebar }) => {
  let navigate = useNavigate();
  const [assignedTests, setAssignedTests] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let userDetails = useSelector(authData);

  const dispatch = useDispatch();
  useEffect(() => {

    get();

  }, []);



  async function get() {
    let userdata = { ...userDetails };
    setIsLoading(true);
    try {

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        `${Constants.GET_ASSIGNED_TESTS}?loginId= ${userdata.email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      
      response?.assignedTests?.reverse();
      setAssignedTests(response);
      setIsLoading(false);

      dispatch(awsSignIn(userdata));
    } catch (error) {
      console.error(error);
    }
  }
  const handleTestClick = (item) => {
    let sdata = { ...userDetails };
    sdata.testData = item;
    sdata.sideactive = "/tests";
    dispatch(awsSignIn(sdata));
    navigate("/testresult");
  }
  const classes = useStyles();

  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div>
        {isLoading === true ? <div className={{
          position: "fixed",
          backgroundColor: "#000000a1",
          width: "100vw",
          height: "100vh",
          display: "flex",
          top: "0",
          left: "0",
          zIndex: "2000",
        }}><CircularProgress style={{ position: "fixed", top: "48%", left: "48%", background: "transparent", zIndex: "2001" }} /> </div> : null}
        <UserHeader />
        <div style={{ height: "84vh" }}>
          <div className={classes.root}>
            {!isLoading && assignedTests.assignedTests && <div style={{ cursor: "pointer", display: 'flex', gap: '20px',flexWrap:'wrap' }} >
              {assignedTests.assignedTests.map((item) => (
                <Card
                  style={{ cursor: "pointer", width: '30%', height: '130px',opacity: item.testStatus === 'Deactivated' ? 0.7 : 1 }}
                  onClick={() => item.testStatus !== 'Deactivated' && handleTestClick(item)}
                  disabled={item.testStatus === 'Deactivated'}
                >
                  <p className={dashboardStyle.topicNameDashboard}>
                    {item.test_name}
                  </p>
                  <div>
                    <p style={{ marginTop: "82px" }}>
                      <span style={{ marginLeft: "10px" }}>
                        Start Time: {moment(item.start_time).format("YYYY-MM-DD h:mm a")}
                      </span>

                    </p>
                    <p style={{ marginTop: "8px" }}>
                      <span style={{ marginLeft: "10px" }}>
                        End Time: {moment(item.end_time).format("YYYY-MM-DD h:mm a")}
                      </span>
                    </p>
                   
                  </div>
                </Card>
              ))}
            </div>}
            {!isLoading && !assignedTests.assignedTests && <div
              style={{
                display: "grid",
                placeItems: "center",
                display: "flex", justifyContent: 'center', alignItems: 'center'
              }}
            >
              <div style={{
                width: "400px",
                height: "200px",
                background: "#1974b5",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: ".8rem",
              }}
              >
                <p style={{
                  color: "#fff",
                  fontSize: "1.75rem",
                  fontWeight: "600",
                }}
                >
                  There is nothing here to display
                </p>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Test;
