import React, { useState, useEffect, useRef } from "react";
import config from "../../config/aws-exports";
import { API, Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Image from "react-bootstrap/Image";
import moment from "moment";
import assessStyle from "../Assignment/Assignment.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  TextareaAutosize,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
  Grid,
  Modal,
  Button,
  Box,
} from "@material-ui/core";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import Skeleton from "@material-ui/lab/Skeleton";
import { green } from "@material-ui/core/colors";
import { useFormik } from "formik";
import { Constants } from "../../config/constants";
import axios from "axios";
import "./Assign.scss";
import { ImageSearchTwoTone } from "@material-ui/icons";
import Reject from "../../assets/svgjs/Reject";
import Done from "../../assets/svgjs/Done";

const Assignment = ({ courseId, progId }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: "80%",
      backgroundColor: "transparent",
      padding: 0,
      height: "auto",
      overflowY: "auto",
      '@media (max-width: 520px)': {
        width: "90%",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    root: {
      width: "100%",
      backgroundColor: "transparent",
    },
  }));
  //const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD h:mm:ss a'));
  const [formatCurDate, setFormatCurDate] = useState(0);
  //const [time, setTime] = useState('20:00:00');
  //const [finishedAssessment, setFinishedAssessment] = useState(false);
  const [assessmentData, setAssessmentData] = useState([]);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userReponse, setUserReponse] = useState([]);
  const [score, setScore] = useState(0);
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [btnClick, setBtnClick] = useState(false);
  const [assess, setAssess] = useState({});
  const [imgUrl, setImgUrl] = useState("");
  const [correct, setCorrect] = useState();
  const [instructions, setInstructions] = useState([]);
  const [hoursv, setHoursv] = useState("");
  const [minutev, setMinutev] = useState("");
  const [start, setStart] = useState("");
  const [qsubmitted, setQsubmitted] = useState(false);
  const setIntervalRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  //view answers
  const [assesID, setAssesID] = useState(0);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [assessAns, setAssessAns] = useState({});

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [checkboxes, setCheckboxes] = useState([]);
  const classes = useStyles();
  let newDate = new Date();
  let currentTime = newDate.getTime();
  const [timerDelay, setTimerDelay] = useState(1000);
  const apiTime = useRef("");

  useEffect(() => {
    getAssessmentData(userDetails);
    getTime();
    let sdata = { ...userDetails };

    dispatch(awsSignIn(sdata));
  }, []);
  async function getTime() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        bpid: progId,
        assessid: courseId,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      //console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.INDIAN_TIME,
        bodyParam
      );
      let date = new Date().getTime();

      console.log("date", date);

      console.log("time", response);
      setFormatCurDate(response.itime);
      apiTime.current = response.itime;
      if (timerDelay != response.delay) {
        console.log(response.delay);
        setTimerDelay(response.delay);
      }

    } catch (error) {
      console.error(error);
    }
  }
  const formik = useFormik({
    initialValues: {
      response: "",
      file: null,
    },

    onSubmit: (values, { setSubmitting, resetForm }) => {
      async function getPreSignedUrl(value) {
        var folder = "assignment";
        console.log(assess);
        const bodyParam = {
          body: {
            type: "workbook",
            wbtype: "js",
            filename: value.file.name,
            filetype: value.file.type,
            oid: config.aws_org_id,
            eid: userDetails.eid,
            quizid: courseId,
            assessid: assess.assessid,
            folder: folder,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
        console.log(bodyParam.body);
        try {
          const response = await API.post(
            config.aws_cloud_logic_custom_name,
            Constants.GET_PRESIGNED_URL,
            bodyParam
            //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
          );

          console.log(response);
          console.log(value.file.name);
          fileUpload(value.file, response);
          console.log(value.file);
          quizQuestion[currentQuestion].orgname = value.file.name;
          quizQuestion[currentQuestion].name = value.file.name;
          setSubmitting(false);
          resetForm();
        } catch (error) {
          console.log("getCategoryError", error);
        }
      }
      getPreSignedUrl(values);
    },
  });
  async function fileUpload(file, url) {
    console.log(url);
    console.log(file);
    console.log(file.type);
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
        console.log(res);
        handleAnswerBtnClick();
      })
      .catch((err) => {
        console.error(err);
      });
  }
  async function getAssessmentData(userDetails) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        bpid: progId,
        assessid: courseId,
        quiztype: "assign",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESSMENT,
        bodyParam
      );
      const { assessment } = response;
      console.log(JSON.stringify(assessment));
      setAssessmentData(assessment);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const handleOpen = (assess) => {
    setOpen(true);
    setAssess(assess);
    getQuiz(assess);
  };

  const handleClose = () => {
    setOpen(false);
    setUserReponse([]);
    setQuizQuestion([]);
    setCheckboxes([]);
    setCurrentQuestion(0);
    setBtnClick(false);
  };
  const handleAnswerOptionClick = (correct, index) => {
    quizQuestion[currentQuestion].response = index;
    console.log(quizQuestion[currentQuestion].response);
    setCorrect(correct);
  };
  function setCheckbox(index, checked) {
    console.log(checkboxes);
    const newCheckboxes = [...checkboxes];

    if (newCheckboxes[index].checked === undefined) {
      newCheckboxes[index].checked = false;
    }
    newCheckboxes[index].checked = checked;
    setCheckboxes(newCheckboxes);
    let tmp = 0;
    for (let k = 0; k < checkboxes.length; k++) {
      if (
        checkboxes[k].checked !== undefined &&
        checkboxes[k].checked === true
      ) {
        tmp = 1;
        setCorrect(true);
      }
      if (tmp === 0 && k == checkboxes.length - 1) {
        setCorrect(undefined);
      }
    }
  }
  const handleAnswerBtnClick = () => {
    if (correct === "true" || correct === true) {
      setScore(score + 1);
    }
    ansaddedtmp();
    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
      if (
        quizQuestion[nextQuestion].atype == 3 ||
        quizQuestion[nextQuestion].atype == 5
      ) {
        setCheckboxes(quizQuestion[nextQuestion].iopts);
      }
    } else {
      handleClose();
    }
    setCorrect(undefined);
  };

  async function ansaddedtmp() {
    let tmpobj = {};
    if (
      quizQuestion[currentQuestion].atype == 5 ||
      quizQuestion[currentQuestion].atype == 3
    ) {
      tmpobj.response = [];
      checkboxes.forEach(function (element, index, array) {
        if (element.checked === true || element.checked === "true") {
          tmpobj.iid = quizQuestion[currentQuestion].iid;
          tmpobj.response.push(index);
        }
      });
    }

    if (quizQuestion[currentQuestion].atype == 7) {
      tmpobj.iid = quizQuestion[currentQuestion].iid;
      tmpobj.response = quizQuestion[currentQuestion].response;
    }
    if (
      quizQuestion[currentQuestion].atype == 2 ||
      quizQuestion[currentQuestion].atype == 1
    ) {
      tmpobj.iid = quizQuestion[currentQuestion].iid;
      tmpobj.response = quizQuestion[currentQuestion].response;
    }

    if (quizQuestion[currentQuestion].atype == 8) {
      tmpobj.iid = quizQuestion[currentQuestion].iid;
      tmpobj.fname = quizQuestion[currentQuestion].name;
      tmpobj.orgname = quizQuestion[currentQuestion].orgname;
    }
    tmpobj.atype = quizQuestion[currentQuestion].atype;
    console.log("tmpobj" + JSON.stringify(tmpobj));
    userReponse.push(tmpobj);

    setUserReponse(userReponse);
    responsesave(userReponse);
  }
  async function responsesave(jsondata) {
    var obj = {};
    obj.response = jsondata;

    if (quizQuestion.length === jsondata.length) {
      obj.status = 1;
    } else {
      obj.status = 0;
    }

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: progId,
        cid: courseId,
        quizid: assess.assessid,
        eid: userDetails.eid,
        quizdata: obj,
        quiztype: "assign",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log(JSON.stringify(bodyParam.body));
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      Constants.UPDATE_ASSESSMENT,
      bodyParam
    );
    console.log(response);
  }
  async function getQuiz(assess1) {
    setQsubmitted(false);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        assessid: courseId,
        quizid: assess1.assessid,
        eid: userDetails.eid,
        quiztype: "assign",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body));

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESSMENT_QUIZ,
        bodyParam
      );
      imagSet(assess1.assessid);
      setInstructions(assess1.instructions);
      setQuizQuestion(response.qitems);
      if (response.qitems[0].atype == 3 || response.qitems[0].atype == 5) {
        setCheckboxes(response.qitems[0].iopts);
      }
      console.log(response.qitems);
      if (response.response !== undefined && response.response !== "") {
        var resp = JSON.stringify(response.response);

        resp = JSON.parse(resp);
        //currentQuestion = response.length  + 1;

        if (resp.status == 1) {
          setQsubmitted(true);
        } else {
          setQsubmitted(false);
        }

        setUserReponse(resp.response === undefined ? [] : resp.response);
        setCurrentQuestion(
          resp.response === undefined ? 0 : resp.response.length
        );
        //setBtnClick(true);

        if (resp.response != undefined &&
          response.qitems[resp.response.length].atype == 3 ||
          response.qitems[resp.response.length].atype == 5
        ) {
          setCheckboxes(response.qitems[resp.response.length].iopts);
        }
        //let img = config.aws_content_delivery_cloudfront_domain+"jssaher-resources/images/assessment-images/"+userDetails.data.bcids[0]+"/"+assess1.assessid+"/";
        /* const imgUrl = `https://${
          Constants.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-resources/images/assignment-images/${courseId}/${
          assess1.assessid
        }/`;
        setImgUrl(imgUrl);
        console.log(imgUrl); */
        imagSet(assess1.assessid);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function imagSet(assess) {
    console.log("ggg", assess);
    const imgUrl = `https://${Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/images/assignment-images/${courseId}/${assess}/`;
    setImgUrl(imgUrl);
  }
  const handleAnswerModal = (assessAns) => {
    getAssignmentAns(assessAns);
    setOpenAnswer(true);
  };

  async function getAssignmentAns(assessAnsR) {
    setAssesID(assessAnsR.assessid);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        quizid: assessAnsR.assessid,
        assessid: courseId,
        quiztype: "assign",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESSMENT_ANSWERS,
        bodyParam
      );

      console.log("assessA", response);
      setAssessAns(response);
    } catch (error) {
      console.error(error);
    }
  }
  function answerPrev() {
    console.log("curr", currentQuestion);
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    // console.log("curr", currentQuestion);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < assessAns.qitems.length) {
      setCurrentQuestion(nextQuestion);
    }
    console.log("curr", currentQuestion);
  }
  function viewAns() {
    if (
      assessAns.response !== undefined &&
      assessAns.response.response !== undefined
    ) {
      let l = assessAns.response.response.length - 1;
      let questionsA = assessAns.qitems.length - 1;
      if (questionsA >= currentQuestion) {

        let tem = [...assessAns.response.response];
        let flag = -1;
        for (let i = 0; i < tem.length; i++) {
          if (tem[i].iid == assessAns.qitems[currentQuestion].iid) {
            flag = i;
          }
        }
        if (
          flag != -1
        ) {
          if (assessAns.qitems[currentQuestion]?.atype != 7 && assessAns.qitems[currentQuestion]?.atype != 8) {
            return assessAns.qitems[currentQuestion].iopts[
              assessAns.response.response[flag].response
            ].content;
          } else {
            return assessAns?.response?.response[flag]?.response;
          }

        } else {
          return <div style={{ color: "red" }}> You have not answerd </div>;
        }
      } else {
        return <div style={{ color: "red" }}> You have not answerd </div>;
      }
    } else {
      return <div style={{ color: "red" }}> You have not answerd </div>;
    }
  }
  function startButtonView(assess) {
    var options = {
      timeZone: "Asia/Kolkata",
      hour12: false,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    let endndianTimeZoneVal = new Date(assess.answerdate).toLocaleString(
      "en-US",
      options
    );
    if (formatCurDate <= endndianTimeZoneVal) {
      assess.start = true;
    }

    return (

      <Box display="flex" p={1} style={{
        position: 'absolute',
        right: '30px',
        alignItems: 'center'
      }}>

        {assess.userlist !== undefined ? (
          <>
            <Box component="div" display="inline" p={1} m={1}>
              <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                Score : {assess.userlist[0].score}
              </p>
            </Box>
          </>
        ) : (
          <> </>
        )}
        {assess.start ? (
          <>
            <Button
              variant="contained"
              color="primary"
              className="startAssessmentButton"
              onClick={() => {
                handleOpen(assess);
              }}
            >
              Start
            </Button>
          </>
        ) : (
          null
        )}

        {formatCurDate >= endndianTimeZoneVal ? (
          <Box component="div" display="inline" p={1} m={1}>
            <p
              style={{
                fontSize: "15px",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={() => {
                handleAnswerModal(assess);
              }}
            >
              Answers
            </p>
          </Box>
        ) : null}

      </Box>
    );
  }

  return (
    <div className={"container " + assessStyle.assesscon}>
      <Typography component="list" variant="h1">
        {isLoading ? <Skeleton /> : null}
      </Typography>
      <Typography component="list" variant="h1">
        {isLoading ? <Skeleton /> : null}
      </Typography>
      <div className={assessStyle.unitAssesment} style={{ width: "100%" }}>
        {assessmentData === undefined || assessmentData.length === 0 ? (
          <>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "center",
                margin: "revert",
              }}
            >
              No Assignments yet
            </p>
          </>
        ) : (
          assessmentData.map((assess) => (
            <div className={assessStyle.assesslist} style={{ display: 'flex', alignItems: 'center' }}>
              <p
                className={assessStyle.assessmentHeader}
                alignItems="flex-start"
                style={{
                  fontSize: "16px",
                  padding: "10px",
                  width: '65%',
                }}

              >
                {assess.title}
              </p>
              {/* {startButtonView(assess)} */}

            </div>
          ))
        )}
        <Modal
          disableBackdropClick
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={open}
          className={classes.modal}
          backdrop="static"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <div className={assessStyle.body}>
              {qsubmitted ? (
                <>
                  <Box
                    component="div"
                    display="inline"
                    p={1}
                    m={1}
                    className={assessStyle.closestyle1}
                  >
                    <Button
                      className={assessStyle.closebtn}
                      onClick={handleClose}
                    >
                      X
                    </Button>
                  </Box>
                  <Box
                    component="div"
                    display="inline"
                    p={1}
                    m={1}
                    style={{ "font-size": "25px" }}
                  >
                    Assignment already submitted.
                  </Box>
                </>
              ) : (
                <>
                  {btnClick ? (
                    <div className={assessStyle.app1}>
                      <>
                        {/* <p className={assessStyle.assesstimertext}><Box component="div" display="inline" p={1} m={1} bgcolor="background.paper" >{hoursv} </Box>
                        <Box component="div" display="inline" p={1} m={1} bgcolor="background.paper">{minutev}</Box>
                        <Box component="div" display="inline" p={1} m={1} bgcolor="background.paper">{secv}</Box>

                      </p> */}
                        <Box
                          component="div"
                          display="inline"
                          p={1}
                          m={1}
                          className={assessStyle.closestyle}
                        >
                          <Button
                            className={assessStyle.closebtn}
                            onClick={handleClose}
                          >
                            X
                          </Button>
                        </Box>
                        <div className={assessStyle.questionsection}>
                          <div className={assessStyle.questioncount}>
                            <span>Question {currentQuestion + 1}</span>/
                            {quizQuestion.length}
                          </div>
                          <div className={assessStyle.questiontext}>
                            {quizQuestion[currentQuestion].atype == 6 ? (
                              <></>
                            ) : (
                              quizQuestion[currentQuestion].istem
                            )}

                            {quizQuestion[currentQuestion].img ? (
                              <div className="img_container">
                                <img
                                  src={`${imgUrl}${quizQuestion[currentQuestion].img}`}
                                  alt="item"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {quizQuestion[currentQuestion].atype == 7 ? (
                          <>
                            <TextareaAutosize
                              className={assessStyle.textarea}
                              rowsMax={6}
                              aria-label="maximum height"
                              onChange={(e) => {
                                setCorrect("true");
                                quizQuestion[currentQuestion].response =
                                  e.target.value;
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {quizQuestion[currentQuestion].atype == 1 ||
                              quizQuestion[currentQuestion].atype == 2 ||
                              quizQuestion[currentQuestion].atype == 4 ? (
                              <>
                                <div className={assessStyle.answersection}>
                                  <div
                                    className={
                                      "btn-group " +
                                      assessStyle.btngroupvertical
                                    }
                                  >
                                    {quizQuestion[currentQuestion].iopts.map(
                                      (answerOption, index, arrayobj) => (
                                        <>
                                          {quizQuestion[currentQuestion]
                                            .atype == 4 ? (
                                            <>
                                              <div className={assessStyle.btncheck}>
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name="options"
                                                  id={answerOption.content}
                                                />
                                                <label
                                                  className={
                                                    assessStyle.labelstyle +
                                                    " " +
                                                    assessStyle.buttonQuiz
                                                  }
                                                  for={answerOption.content}
                                                  onClick={() =>
                                                    handleAnswerOptionClick(
                                                      answerOption.correct,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <Image
                                                    src={
                                                      imgUrl + answerOption.url
                                                    }
                                                    rounded
                                                  />
                                                </label>
                                              </div>
                                            </>
                                          ) : (
                                            <div className={assessStyle.btncheck}>
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                name="options"
                                                id={answerOption.content}
                                              />
                                              <label
                                                className={
                                                  assessStyle.labelstyle +
                                                  " " +
                                                  assessStyle.buttonQuiz
                                                }
                                                for={answerOption.content}
                                                onClick={() =>
                                                  handleAnswerOptionClick(
                                                    answerOption.correct,
                                                    index
                                                  )
                                                }
                                              >
                                                {answerOption.content}
                                              </label>
                                              {/* <Button className={assessStyle.buttonQuiz + ' ' +className}
                                                            onClick={() => 
                                                              handleAnswerOptionClick(answerOption.correct,index)
                                                            } >
                                                            {answerOption.content}
                                                            {quizQuestion[currentQuestion].atype }
                                                          </Button> */}
                                            </div>
                                          )}
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {quizQuestion[currentQuestion].atype == 3 ||
                                  quizQuestion[currentQuestion].atype == 5 ? (
                                  <div className={assessStyle.answersection}>
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        {quizQuestion[
                                          currentQuestion
                                        ].iopts.map(
                                          (answerOption, index, arrayobj) => (
                                            <>
                                              {quizQuestion[currentQuestion]
                                                .atype == 5 ? (
                                                <>
                                                  <div>
                                                    <FormControlLabel
                                                      className={
                                                        assessStyle.buttonQuiz
                                                      }
                                                      value={
                                                        <Image
                                                          src={
                                                            imgUrl +
                                                            answerOption.url
                                                          }
                                                          rounded
                                                        />
                                                      }
                                                      control={
                                                        <Checkbox color="primary" />
                                                      }
                                                      label={
                                                        <Image
                                                          src={
                                                            imgUrl +
                                                            answerOption.url
                                                          }
                                                          rounded
                                                        />
                                                      }
                                                      onChange={(e) => {
                                                        setCheckbox(
                                                          index,
                                                          e.target.checked
                                                        );
                                                      }}
                                                      labelPlacement="end"
                                                    />
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <FormControlLabel
                                                    className={
                                                      assessStyle.buttonQuiz
                                                    }
                                                    value={answerOption.content}
                                                    control={
                                                      <Checkbox color="primary" />
                                                    }
                                                    label={answerOption.content}
                                                    onChange={(e) => {
                                                      setCheckbox(
                                                        index,
                                                        e.target.checked
                                                      );
                                                    }}
                                                    labelPlacement="end"
                                                  />
                                                </>
                                              )}
                                            </>
                                          )
                                        )}
                                      </FormGroup>
                                    </FormControl>
                                  </div>
                                ) : (
                                  <>
                                    {quizQuestion[currentQuestion].atype ==
                                      8 ? (
                                      <>
                                        <form onSubmit={formik.handleSubmit}>
                                          <div className="file-input">
                                            <input
                                              type="file"
                                              id="file"
                                              name="file"
                                              className="file"
                                              onChange={(event) => {
                                                formik.setFieldValue(
                                                  "file",
                                                  event.target.files[0]
                                                );
                                              }}
                                              multiple
                                              required
                                            />
                                            <label for="file">
                                              Select file
                                              <p className="file-name"></p>
                                            </label>
                                          </div>

                                          {currentQuestion + 1 ===
                                            quizQuestion.length ? (
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              fullWidth
                                              type="submit"
                                            >
                                              {formik.isSubmitting
                                                ? "Loading..."
                                                : "Submit"}
                                            </Button>
                                          ) : (
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              fullWidth
                                              type="submit"
                                            >
                                              {formik.isSubmitting
                                                ? "Loading..."
                                                : "Next"}
                                            </Button>
                                          )}
                                        </form>
                                      </>
                                    ) : (
                                      <>
                                        {quizQuestion[currentQuestion].atype ==
                                          6 ? (
                                          <>
                                            <p
                                              className={assessStyle.matchThe}
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  quizQuestion[currentQuestion]
                                                    .istem,
                                              }}
                                            ></p>{" "}
                                            {quizQuestion[
                                              currentQuestion
                                            ].iopts.map(
                                              (
                                                answerOption,
                                                index,
                                                arrayobj
                                              ) => (
                                                <>
                                                  {quizQuestion[currentQuestion]
                                                    .atype == 4 ? (
                                                    <>
                                                      <div className={assessStyle.btncheck}>
                                                        <input
                                                          type="radio"
                                                          className="btn-check"
                                                          name="options"
                                                          id={
                                                            answerOption.content
                                                          }
                                                        />
                                                        <label
                                                          className={
                                                            assessStyle.labelstyle +
                                                            " " +
                                                            assessStyle.buttonQuiz
                                                          }
                                                          for={
                                                            answerOption.content
                                                          }
                                                          onClick={() =>
                                                            handleAnswerOptionClick(
                                                              answerOption.correct,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <Image
                                                            src={
                                                              imgUrl +
                                                              answerOption.url
                                                            }
                                                            rounded
                                                          />
                                                        </label>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div className={assessStyle.btncheck}>
                                                      <input
                                                        type="radio"
                                                        className="btn-check"
                                                        name="options"
                                                        id={
                                                          answerOption.content
                                                        }
                                                      />
                                                      <label
                                                        className={
                                                          assessStyle.labelstyle +
                                                          " " +
                                                          assessStyle.buttonQuiz
                                                        }
                                                        for={
                                                          answerOption.content
                                                        }
                                                        onClick={() =>
                                                          handleAnswerOptionClick(
                                                            answerOption.correct,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {answerOption.content}
                                                      </label>
                                                      {/* <Button className={assessStyle.buttonQuiz + ' ' +className}
                                                            onClick={() => 
                                                              handleAnswerOptionClick(answerOption.correct,index)
                                                            } >
                                                            {answerOption.content}
                                                            {quizQuestion[currentQuestion].atype }
                                                          </Button> */}
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>d</>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {quizQuestion[currentQuestion].atype == 8 ? (
                          <></>
                        ) : (
                          <>
                            <div style={{ margin: "2rem", textAlign: "center" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={assessStyle.nextbtn}
                                disabled={correct === undefined}
                                onClick={() => handleAnswerBtnClick()}
                              >
                                {currentQuestion == quizQuestion.length - 1
                                  ? "Submit"
                                  : "Next"}
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  ) : (
                    <>
                      {quizQuestion.length === 0 ? (
                        <>
                          <Typography component="div" key="h2" variant="h2">
                            <Skeleton />
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Grid container spacing={1}>
                            <Grid item xs={12} className={assessStyle.grid1}>
                              <List
                                component="nav"
                                className={classes.root}
                                aria-label="contacts"
                              >
                                <h1 className={assessStyle.heading}>
                                  Instruction
                                </h1>
                                {instructions.map((arritem) => (
                                  <>
                                    <ListItem
                                      className={assessStyle.MuiListItembutton}
                                    >
                                      <ListItemIcon>
                                        <StarOutlineIcon
                                          style={{ color: green[500] }}
                                        />
                                      </ListItemIcon>
                                      <p>{arritem}</p>
                                    </ListItem>
                                  </>
                                ))}
                              </List>
                            </Grid>
                            <Grid item xs={12} className={assessStyle.grid2}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={assessStyle.nextbtn}
                                onClick={() => {
                                  setBtnClick(true);
                                }}
                              >
                                Start Quiz
                              </Button>
                              <br />
                              <br />
                              <Button
                                variant="contained"
                                color="primary"
                                className={assessStyle.nextbtn}
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                Close
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal>
      </div>
      <Modal
        disableBackdropClick
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openAnswer}
        className={classes.modal}
        backdrop="static"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={assessStyle.bodyAns}>

            {assessAns.qitems === undefined ||
              assessAns.qitems.length === 0 ? (
              <div style={{ fontSize: "15px" }}>Loading.. </div>
            ) : (
              <div>
                <div className={assessStyle.questionsection}>
                  <div
                    style={{
                      fontWeight: "bold",
                      float: "right",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenAnswer(false);
                      setCurrentQuestion(0);

                      setAssessAns([]);
                    }}
                  >
                    {" "}
                    X{" "}
                  </div>
                  <div className={assessStyle.questioncount}>
                    <span>Question {currentQuestion + 1}</span>/
                    {assessAns.qitems.length}
                  </div>
                  <div className={assessStyle.questiontext}>
                    <div style={{ fontSize: "15px" }}>
                      {" "}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: assessAns.qitems[currentQuestion].istem,
                        }}
                      ></p>
                    </div>
                    {/* {assessAns.qitems[currentQuestion].istem} */}
                    {assessAns.qitems[currentQuestion].img ? (
                      <div className="img_container">
                        <img
                          src={`${`https://${Constants.DOMAIN
                            }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${courseId}/${assesID}/`}${assessAns.qitems[currentQuestion].img
                            }`}
                          alt="item"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={assessStyle.answersection}>
                  {assessAns.qitems[currentQuestion].atype == "2" ? (
                    <div
                      className={"btn-group " + assessStyle.btngroupvertical}
                    >
                      {assessAns.qitems[currentQuestion].iopts.map(
                        (answerOption, index, arrayobj) => (
                          <>
                            <div style={{}} className={assessStyle.btncheck}>
                              <div>
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="options"
                                  id={answerOption.content}
                                />
                                <div style={{ display: "flex" }}>
                                  <label
                                    className={
                                      assessStyle.labelstyleAns +
                                      " " +
                                      assessStyle.buttonQuizAns
                                    }
                                    for={answerOption.content}
                                  >
                                    {answerOption.content}
                                  </label>
                                  <span>
                                    {answerOption.correct === "true" ||
                                      answerOption.correct === true ? (
                                      <Done
                                        className="right_size"
                                        cls1="cls1_D"
                                        cls2="cls2_D"
                                      />
                                    ) : (
                                      <Reject
                                        className="Wrong_size"
                                        cls1="cls1_D"
                                        cls2="cls2_D"
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
                <br />

                <div className={assessStyle.yourAnswer}>
                  Your Answer :{" "}
                  <div className={assessStyle.yourAnswerScore}>
                    {
                      viewAns()
                      /*  */
                    }
                  </div>
                </div>
                {currentQuestion > 0 ? (
                  <Button
                    color="primary"
                    variant="contained"
                    className="btn-size"
                    onClick={() => answerPrev()}
                    style={{ fontSize: "12px" }}
                  >
                    Prev
                  </Button>
                ) : null}
                {currentQuestion + 1 === assessAns.qitems.length ? (
                  <div></div>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    className="btn-siz"
                    onClick={() => answerNext()}
                    style={{ float: "right", fontSize: "12px" }}
                  >
                    Next
                  </Button>
                )}
              </div>
            )
            }
          </div>
        </div>
      </Modal>
      <hr />
    </div>
  );
};

export default Assignment;
