import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData, awsSignOut } from "../../redux/auth/authSlice";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import SideNav from "../../modules/SideNav/SideNav";
import UserHeader from "../Header/UserHeader/UserHeader";
import Button from "@material-ui/core/Button";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import {
  Inject,
  ScheduleComponent,
  Day,
  Month,
  Week,
  WorkWeek,
  EventSettingsModel,
} from "@syncfusion/ej2-react-schedule";

import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

import "./Events.scss";
import EventStyle from "./Events.module.scss";
import image from "./Events 1.png";
import { FaBars } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    '& > div': {
      '@media (max-width: 1100px)': {
        display: "block",
        '& > div': {
          maxWidth: "100%",
        },
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    marginLeft: "50px",
    '@media (max-width: 1100px)': {
      marginLeft: "0",
    },
    '@media (max-width: 426px)': {
      padding: "0",
    },
  },
  paperEvents: {
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    marginRight: "50px",
    overflow: "scroll",
    height: "50%",
    '@media (max-width: 1100px)': {
      marginRight: "0",
    },
  },
}));

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate("prev");
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate("next");
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("current");
  };

  const label = () => {
    const date = moment(toolbar.date);
    return (
      <span>
        <b>{date.format("MMMM")}</b>
        <span> {date.format("YYYY")}</span>
      </span>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", width: "100%" }}>
        <Button onClick={goToBack}>&#8249;</Button>
        <Button onClick={goToCurrent}>today</Button>
        <Button onClick={goToNext}>&#8250;</Button>
        <label className={EventStyle.monthLabel}>{label()}</label>
      </div>
    </div>
  );
};
const localizer = momentLocalizer(moment);
moment.locale("en-GB");
const Event = ({ handleToggleSidebar }) => {
  const [events, setevents] = useState([]);
  const [upEvents, setupEvents] = useState([]);
  const userDetails = useSelector(authData);
  const [allEvents, setAllEvents] = useState([]);
  const [upcomingEve, setUpcomingEve] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    upcoming();
    getEvents();
    return () => {};
  }, []);

  async function getEvents() {
    //alert(JSON.stringify(userDetails))

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        rtype: "get",
        batchjson: userDetails.data.bpids,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_EVENTS,
        bodyParam
      );

      //alert(JSON.stringify(response))
      convertEventData(response.result.events);
      setAllEvents(response.result.events);
      setIsLoading(false);
      //upcoming();
      //alert("Events "+JSON.stringify(EventsJSON));
    } catch (error) {
      console.error(error);
    }
  }

  function convertEventData(data) {
    let upEvents = data;
    if (data.length > 5) {
      upEvents = data.slice(-3);
      // setupEvents(upEvents);
    }

    filter(upEvents);
    function filter(data) {
      let m = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        let res = data[i].start.split("-");

        let d = new Date();
        let month = 0;
        if (parseInt(res[1]) != 0) {
          month = parseInt(res[1]) - 1;
        }

        if (
          parseInt(res[0]) == d.getFullYear() &&
          month >= d.getMonth() &&
          parseInt(res[2]) >= d.getDate()
        ) {
          let obj = {
            title: data[i].title,
            link: data[i].link,
            month: m[month],
            day: res[2],
          };
          temp.push(obj);
        }
      }
      setupEvents(temp);
    }
    //alert(JSON.stringify(data))
    let temp = [];

    for (let i = 0; i < data.length; i++) {
      let res = data[i].start.split("-");
      if (res[1] != 0) {
        res[1] = res[1] - 1;
      }

      let obj = {
        title: data[i].title,
        //description: 'Summer vacation planned for outstation.',
        startTime: new Date(res[0], res[1], res[2]),
        endTime: new Date(res[0], res[1], res[2]),
      };
      temp.push(obj);
    }
    setevents(temp);
  }

  function toTimestamp(a) {
    var datum = Date.parse(a);
    return datum / 1;
  }
  let empty = [];
  function upcoming() {
    let upeve = allEvents;
    let a;
    for (let i = 0; i < upeve.length; i++) {
      console.log("for1", upeve[i].start);
      a = upeve[i].start;
      console.log("A1", toTimestamp(a));
      upeve[i].start = toTimestamp(a);

      console.log("up1", upeve);
    }
    setUpcomingEve(upeve);
  }

  console.log("finally", upcomingEve);
  const classes = useStyles();

  let fields = {
    subject: { name: "title", default: "Event" },
    //description: { name: 'description' },
    startTime: { name: "startTime" },
    endTime: { name: "endTime" },
  };

  console.log("events", events);
  console.log("eventsa", allEvents);
  console.log("up", upEvents);
  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader />
      <div>
        <div className={classes.root}>
          <Grid container spacing={5}>
            <Grid item xs={7}>
              <p className={EventStyle.titleStyle}>All Events</p>
              <Paper className={classes.paper}>
                <ScheduleComponent
                  currentView="Month"
                  width="100%"
                  readonly={true}
                  //timeScale={{ enable: false}}
                  eventSettings={{
                    dataSource: events,
                    fields: fields,
                    enableTooltip: true,
                  }}
                >
                  <Inject services={[Month, Day, Week, WorkWeek]} />
                </ScheduleComponent>
              </Paper>
            </Grid>

            <Grid item xs={5}>
              <p
                style={{ marginLeft: "-0px" }}
                className={EventStyle.titleStyle}
              >
                Upcoming Events
              </p>
              <Paper className={classes.paperEvents}>
                {upEvents.length === 0 ? (
                  <p style={{ fontSize: "16px", textAlign: "center" }}>
                    {" "}
                    No Upcoming Events
                  </p>
                ) : (
                  upEvents.map((event) => (
                    <div style={{ marginTop: "30px", padding: "1rem", }}>
                      <Typography component="list" variant="h1">
                        {isLoading ? <Skeleton /> : null}
                      </Typography>
                      <Typography component="list" variant="h1">
                        {isLoading ? <Skeleton /> : null}
                      </Typography>
                      <div>
                        <div className={EventStyle.iconTop}>{event.month}</div>
                        <div className={EventStyle.iconBottom}>{event.day}</div>
                      </div>
                      <div
                        style={{
                          fontSize: "medium",
                          whiteSpace: "normal",
                          minHeight: "10px",
                          marginLeft: "61px",
                        }}
                      >
                        <div style={{ marginTop: "-22px" }}>{event.title}</div>
                        {event.link === undefined ||
                        event.link === "" ? null : (
                          <div>
                            Link :{" "}
                            <a target="_blank" href={event.link}>
                              {event.link}
                            </a>
                          </div>
                        )}
                      </div>
                      <hr className={EventStyle.eventsDivider} />
                    </div>
                  ))
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </main>
  );
};
export default Event;
