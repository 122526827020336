//Dependency Imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Modal from "@material-ui/core/Modal";

import Fade from "@material-ui/core/Fade";

import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";

import { ReactComponent as PodCastIcon } from '../../assets/svg/Icon fa-solid-podcast.svg';
//Local Imports
import config from "../../config/aws-exports";
import { authData } from "../../redux/auth/authSlice";
import ClockGray from "../../assets/svgjs/ClockGray";
import AudioPlayer from "./AudioPlayer";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "20px",

        overflowY: "auto",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "fit-content",
        flexShrink: 0,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },

    tooltipFull: {
        fontSize: 30,
    },
    bottomIcons: {
        color: "#000",
        "&:hover": {
            color: "#777",
        },
    },
    volumeButton: {
        color: "#000",
        fontSize: 40,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#777",
            transform: "scale(1.03)",
            transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
        },
    },
    volumeButtonExit: {
        color: "lightgray",
        fontSize: 40,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#777",
            transform: "scale(1.03)",
            transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
        },
    },
    videoSection: {
        padding: "2%",
    },
    myIframe: {
        height: "100%",
        width: "100%",
    },
    myIframeContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "96%",
        overflowX: "hidden",
    },
}));


const Podcast = ({ pid, bpid, courseid, bcourseid }) => {

    const classes = useStyles();


    const [nuggets, setNuggets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [curObject, setcurObject] = useState();
    const [disableOpen, setDisableOpen] = useState(false);


    const handleOpenClose = (state) => {
        setOpen(false)
    };

    useEffect(() => {
        getPodcast()
    }, []);

    async function getPodcast() {
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                pid: pid,
                bpid: bpid,
                courseid: courseid,
                bcourseid: bcourseid,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        setIsLoading(true)
        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                "/getpodcast",
                bodyParam
            );
            if (response && response.length !== 0) {
                const topicsJSON = response?.nuggets;
                function groupByKey(array, key) {
                    return array.reduce((hash, obj) => {
                        if (obj[key] === undefined) return hash;
                        return Object.assign(hash, {
                            [obj[key]]: (hash[obj[key]] || []).concat(obj),
                        });
                    }, {});
                }
                var result = groupByKey(topicsJSON, "unit");
                let temp = [];
                for (let i = 0; i < topicsJSON.length; i++) {
                    for (let j = 0; j < topicsJSON[i].objects.length; j++) {
                        temp.push(topicsJSON[i].objects[j]);
                    }
                }


                console.log("result", result)
                setNuggets(result);


            } else {

            }
            setIsLoading(false)
        } catch (error) {
            console.error(error);
        }
    }

    function onClickObject(item) {

        if (item.nenabled == false) {
            setDisableOpen(true)
        }

        else {
            setOpen(true);
            setcurObject(item)
        }

    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
            >
                <Fade in={open}>


                    <AudioPlayer curObject={curObject} handleOpenClose={handleOpenClose} />

                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={disableOpen}
                closeAfterTransition
            >
                <Fade in={disableOpen}>
                    <div
                        style={{
                            width: "50%",
                            height: "12rem",
                            background: "transparent",
                            justifycontent: "center",
                            backgroundColor: "white",
                            textAlign: "center",
                            padding: "2rem",
                            borderRadius: "5px",
                        }}
                    >
                        <div style={{ textAlign: "right", cursor: "pointer" }}>
                            <CloseIcon onClick={() => { setDisableOpen(false) }} />
                        </div>
                        <h2>Disabled by admin</h2>

                    </div>

                </Fade>
            </Modal>

            <div className={classes.root}>
                <Typography component="div" variant="h1">
                    {isLoading ? <Skeleton /> :
                        <div>
                            {Object.keys(nuggets).length > 0 ? (
                                Object.keys(nuggets).map((nugget, idx) => (
                                    <Accordion key={idx}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel-${idx}-content`}
                                            id={`panel-${idx}-header`}
                                        >
                                            <Typography className="size-m-b">
                                                <h2>{nugget}</h2>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                {nuggets[nugget].map(({ nid, unit, ntitle, nduration, objects, nenabled }, idx) => (
                                                    <div key={nid}>
                                                        <div className="display-flex padding2">
                                                            <h3 className="item" style={{ fontWeight: 'bold' }}>
                                                                {ntitle}
                                                            </h3>
                                                            <p style={{ fontSize: '14px' }}>
                                                                <span>
                                                                    <ClockGray className="clock-size--s" cls1="cls1-s" cls2="cls2-s" />
                                                                </span>
                                                                {nduration} Minutes
                                                            </p>
                                                        </div>
                                                        {objects
                                                            .filter(({ otype }) => otype === 'podcast')
                                                            .map((object, index) => (
                                                                <div
                                                                    key={object.oid}
                                                                    className="flex size"
                                                                    style={{
                                                                        justifyContent: 'space-between',
                                                                        width: 'calc(100% - 4.5%)',
                                                                    }}
                                                                >
                                                                    <p style={{ cursor: 'pointer' }}>
                                                                        <PodCastIcon style={{ height: '2rem', width: '2rem' }} />
                                                                        <a
                                                                            style={{ paddingLeft: '10px' }}
                                                                            onClick={() => {
                                                                                onClickObject(object);
                                                                            }}
                                                                        >
                                                                            {object.otitle}
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            ))}
                                                    </div>
                                                ))}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            ) : (
                                <h3 style={{ textAlign: "center", fontWeight: "600" }}>No Data</h3>
                            )}
                        </div>

                    }
                </Typography>



            </div>
        </div>


    )
}

export default Podcast