import React from "react";
import Footer from "../../../components/Footer/Footer";
import AppHeader from "../../../components/Header/AppHeader";
import "./ContactUs.scss";
import { Link } from "react-router-dom";
function ContactUs() {
  return (
    <div>
      <AppHeader />
      <div align="left" style={{ padding: "10rem 5%" }}>
        <Link to={`/`}>
          <p
            style={{
              color: "#01498e",
              fontSize: "small",
              marginBottom: "30px",
              marginTop: "10px",
            }}
          >
            {" "}
            {`< Back To Home`}
          </p>
        </Link>

        <p className="hedingStyle">Contact us</p>
        <div className="liststyle">
          <p className="programTitle">
            <strong>Registrar, </strong>
          </p>
          <p className="programTitle">Online Education Program</p>
          <br />

          <p className="institutionName">
            <strong>JSS Medical Institutions Campus</strong>
            <br />
            Sri Shivarathreeshwara Nagara <br />
            Mysuru - 570 015
            <br />
            Karnataka
            <br />
            INDIA
            <br />
            <br />
            <b>Phone:</b>&nbsp;9972697662
            <br />
            <b>Fax:</b>&nbsp;+91-821-2548394
            <br />
            <br />
            <b>Admissions:</b>
            <p>onlineadmissions@jssuni.edu.in</p>
          </p>
        </div>
      </div>

      <div style={{ width: "100%", position: "absolute", bottom: "0" }}>
        <Footer />
      </div>
    </div>
  );
}
export default ContactUs;
