import "./Formative.scss";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../../modules/Window/Window";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import FolderIcon from "@material-ui/icons/Folder";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
const useStyles = makeStyles(() => ({
  noteSection: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 1fr",
    gridGap: "1.5rem",
    "@media (max-width: 500px)": {
      display: "block",
    },
  },
  paddingHeading: {
    padding: "30px 30px 0px 30px",
    fontSize: "20px",
    "@media (max-width: 768px)": {
      padding: "1rem 1rem 0rem 3rem",
    },
  },
  paddingBody: {
    backgroundColor: "white",
    height: "19rem",
    margin: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    "@media (max-width: 426px)": {
      width: "24rem",
    },
  },
  buttonConfirm: {
    position: "relative",
    right: "40px",
    top: "70px",
    color: "#3372B5",
    backgroundColor: "white",
    borderRadius: "8px",
    fontSize: "15px",
    height: "28px",
    lineHeight: "13px",
    float: "right",
    width: "100px",
    border: "2px solid #3372B5",
    "@media (max-width: 570px)": {
      width: "fit-content",
    },
  },
  buttonCancle: {
    position: "relative",
    right: "-28px",
    top: "55px",
    color: "lightcoral",
    backgroundColor: "white",
    borderRadius: "8px",
    fontSize: "15px",
    height: "28px",
    lineHeight: "13px",
    float: "left",
    width: "100px",
    border: "2px solid lightcoral",
    "@media (max-width: 570px)": {
      width: "fit-content",
    },
  },
  textarea11: {
    "& > div > textarea": {
      fontSize: "1.4rem",
      lineHeight: "2rem",
    },
  },
}));

const MiniAssignment = (props) => {
  let {
    editmini,
    getAssignmentDataPost1,
    autoScorCal,
    miniloader,
    setMiniloader,
    saveResponse,
    Button,
    imgUrl,
    produce,
    getQuiz,
    btnClick,
    setScore,
    curObject,
    showScore,
    miniScore,
    sMiniScore,
    handleClose,
    quizQuestion,
    setShowScore,
    isSubmittingg,
    setQIsLoading,
    assignmentLoad,
    currentQuestion,
    setAssignmentLoad,
    setCurrentQuestion,
    handleImageChange,
    setQuizQuestion,
    totalminiScore,
    answerNext,
    miniopen,
    setMiniopen,
    answerPrev,
    editOpenConfirm,
    setEditOpenConfirm,
    pissueTextUpload,
    feedback,
    openMiniFeedback,
    setOpenMiniFeedback,
  } = props;

  const { height } = useWindowDimensions();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [obj, setObj] = useState();
  const [showSubmiteed, setShowSubmitted] = useState(false);
  const hiddenFileInput = React.useRef(null);

  useEffect(() => {}, []);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  function confirmSave() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalConfirm}
          open={miniopen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={miniopen}>
            <div className={classes.paddingBody}>
              <h3 className={classes.paddingHeading}>
                Are you sure you want to submit?
              </h3>
              <button
                className={classes.buttonConfirm}
                onClick={() => {
                  setMiniloader(true);
                  if (
                    obj.miniassignmenttype != undefined &&
                    (obj.miniassignmenttype == "1" ||
                      obj.miniassignmenttype == 1)
                  ) {
                    autoScorCal(data, obj);
                  } else {
                    saveResponse(data, obj);
                  }
                }}
              >
                Confirm
              </button>
              <br />
              <button
                className={classes.buttonCancle}
                onClick={() => {
                  setMiniopen(false);
                  setScore(0);
                  setShowScore(true);
                  setCurrentQuestion(0);
                }}
              >
                Cancel
              </button>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }

  function confirmEditSave() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalConfirm}
          open={editOpenConfirm}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={editOpenConfirm}>
            <div className={classes.paddingBody} style={{ height: "25rem" }}>
              <h3
                className={classes.paddingHeading}
                style={{ textAlign: "center" }}
              >
                Only one attempt is given to Edit the Assignment , the score
                received after the attempt will be final , Click "OK" to
                continue , Click "Cancel" to retain the previous score
              </h3>
              <button
                className={classes.buttonConfirm}
                onClick={() => {
                  setEditOpenConfirm(false);
                  getAssignmentDataPost1(curObject.oid);
                  setQIsLoading(true);
                  setAssignmentLoad(true);
                }}
              >
                Ok
              </button>
              <br />
              <button
                className={classes.buttonCancle}
                onClick={() => {
                  setAssignmentLoad(false);
                  setMiniopen(false);
                  setEditOpenConfirm(false);
                  setScore(0);
                  setShowScore(true);
                  setCurrentQuestion(0);
                }}
              >
                Cancel
              </button>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }

  function showFeedbackmodal() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalConfirm}
          open={openMiniFeedback}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openMiniFeedback}>
            <div
              className={classes.paddingBody}
              style={{ height: "70%", width: "70%", overflow: "auto" }}
            >
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    textAlign: "right",
                    margin: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenMiniFeedback(false);
                  }}
                >
                  X
                </p>
              </div>

              {feedback != undefined ? (
                <div>
                  {feedback.map((feedback) => (
                    <div>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <div>
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  marginBottom: "9px",
                                  color: "#333333",
                                  overflow: "auto",
                                }}
                              >
                                {feedback.givenby}
                              </p>

                              <p
                                style={{
                                  whiteSpace: "pre-line",
                                  fontSize: "1.4rem",
                                  marginBottom: "9px",
                                  color: "#333333",
                                  overflow: "auto",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: feedback.message,
                                }}
                              ></p>
                            </div>
                          }
                          secondary={
                            <div>
                              <div style={{ fontSize: "12px" }}>
                                {feedback.timeStamp}
                              </div>
                            </div>
                          }
                        />
                      </ListItem>
                      <hr />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </Fade>
        </Modal>
      </>
    );
  }

  return (
    <div
      className="body"
      style={{ height: btnClick || showScore ? height - 400 : "" }}
    >
      {confirmSave()}
      {confirmEditSave()}
      {showFeedbackmodal()}
      {editmini ? (
        <div className="app1">
          {showSubmiteed ? (
            <div className="score-section">
              <Backdrop className={classes.backdrop} open={miniloader}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <h3>Goto Home Page</h3>
              <div>
                <button
                  className="buttonQuizCt"
                  onClick={() => {
                    handleClose();
                    setScore(0);
                    setCurrentQuestion(0);
                    getQuiz(curObject);
                  }}
                >
                  Exit Assignment
                </button>
              </div>
            </div>
          ) : (
            <div className="scrollQuiz">
              <div className="question-section">
                <div className="question-count">
                  <span>Question {currentQuestion + 1}</span>/
                  {quizQuestion.length}
                </div>
                <div className="question-text">
                  <p
                    style={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{
                      __html: quizQuestion[currentQuestion]?.istem,
                    }}
                  ></p>
                </div>
              </div>
              {
                <div>
                  {!quizQuestion[currentQuestion]?.iopts ||
                  quizQuestion[currentQuestion]?.iopts.length === 0 ? (
                    <div className={classes.root}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: `${
                            quizQuestion[currentQuestion]?.imageurl
                              ? "4fr"
                              : "1fr"
                          } `,
                          gridGap: "1.5rem",
                        }}
                      >
                        <center>
                          <div style={{ width: "auto" }}>
                            {quizQuestion[currentQuestion]?.imageurl ? (
                              <div className="img_container">
                                <img
                                  src={`${imgUrl}${quizQuestion[currentQuestion]?.imageurl}`}
                                  alt="item"
                                />
                              </div>
                            ) : null}
                          </div>
                        </center>
                        <br></br>

                        <div className={classes.root}>
                          <TextField
                            fullWidth
                            className={classes.textarea11}
                            value={
                              quizQuestion[currentQuestion]?.response ==
                                undefined ||
                              quizQuestion[currentQuestion]?.response.length ==
                                0
                                ? ""
                                : quizQuestion[currentQuestion]?.response
                            }
                            placeholder="Type your response here..."
                            multiline
                            rows={4}
                            name="response"
                            label="Insert some text"
                            onChange={(e) => {
                              setQuizQuestion((currentQues) =>
                                produce(currentQues, (v) => {
                                  v[currentQuestion].response = e.target.value;
                                  v[currentQuestion].miniType = 2;
                                })
                              );
                            }}
                            variant="outlined"
                            color="primary"
                          />
                          <div className={classes.noteSection}>
                            <div
                              style={{
                                padding: "5%",
                                fontSize: "14px",
                              }}
                            >
                              <p style={{ textAlign: "justify" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Note:
                                </span>
                                1. Please upload files in the format of pdf,
                                jpg, jpeg, png only.
                                <p>
                                  2. Please do not upload any images or PDFs
                                  that contain handwritten text.
                                </p>
                              </p>
                            </div>
                            <div style={{ paddingTop: "5%", fontSize: "14px" }}>
                              <input
                                className={classes.hidden}
                                accept="image/*,application/pdf"
                                type="file"
                                onChange={handleImageChange}
                                ref={hiddenFileInput}
                                style={{ display: "none" }}
                              />

                              <Avatar
                                style={{
                                  background: "#3f51b5",
                                  height: "56px",
                                  width: "56px",
                                }}
                                onClick={handleClick}
                              >
                                <FolderIcon />
                              </Avatar>
                              <Typography
                                className={classes.title}
                                variant="h6"
                              ></Typography>
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  paddingLeft: "3%",
                                }}
                              >
                                {quizQuestion[currentQuestion]
                                  ?.uploadedfilename == undefined ||
                                quizQuestion[currentQuestion]?.uploadedfilename
                                  .length == 0
                                  ? "Browse"
                                  : quizQuestion[currentQuestion]
                                      ?.uploadedfilename}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <p style={{ color: "red", fontWeight: "bold" }}>
                            {pissueTextUpload}
                          </p>
                        </div>

                        <div
                          style={{
                            marginTop: "-1%",
                            paddingLeft: "3%",
                            paddingRight: "3%",
                          }}
                        >
                          {currentQuestion > 0 ? (
                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              className="btn-size"
                              onClick={() => {
                                answerPrev();
                              }}
                              style={{ fontSize: "12px" }}
                            >
                              Prev
                            </Button>
                          ) : null}
                          {currentQuestion + 1 === quizQuestion.length ? (
                            <Button
                              disabled={
                                quizQuestion[currentQuestion]?.response ==
                                  undefined ||
                                (quizQuestion[currentQuestion]?.response
                                  .length == 0 &&
                                  isSubmittingg == true)
                              }
                              color="primary"
                              variant="contained"
                              fullWidth
                              className="btn-siz"
                              onClick={() => {
                                setData(quizQuestion);
                                setObj(curObject);
                                setMiniopen(true);
                                setShowSubmitted(true);
                              }}
                              style={{ float: "right", fontSize: "12px" }}
                            >
                              {isSubmittingg ? "Loading..." : "Submit"}
                            </Button>
                          ) : (
                            <Button
                              disabled={
                                quizQuestion[currentQuestion]?.response ==
                                  undefined ||
                                quizQuestion[currentQuestion]?.response
                                  .length == 0 ||
                                isSubmittingg == true
                              }
                              color="primary"
                              variant="contained"
                              fullWidth
                              className="btn-siz"
                              onClick={() => {
                                answerNext();
                              }}
                              style={{ float: "right", fontSize: "12px" }}
                            >
                              {isSubmittingg ? "Loading..." : "Next"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    "null"
                  )}
                </div>
              }
            </div>
          )}
        </div>
      ) : (
        <div>
          {assignmentLoad ? (
            <CircularProgress color="default" size={30} />
          ) : navigator.onLine ? (
            <div>
              <button
                className="btnQuizLD"
                style={{ width: "fit-content" }}
                onClick={() => {
                  // getAssignmentDataPost1(curObject.oid);
                  if (feedback != undefined) {
                    setEditOpenConfirm(false);
                    getAssignmentDataPost1(curObject.oid);
                    setQIsLoading(true);
                    setAssignmentLoad(true);
                  } else {
                    setQIsLoading(true);
                    setAssignmentLoad(true);
                    setEditOpenConfirm(true);
                  }
                }}
              >
                Edit Assignment
              </button>
              {feedback != undefined ? (
                <p
                  onClick={() => {
                    setOpenMiniFeedback(true);
                  }}
                  style={{
                    fontSize: "12px",
                    color: "#234668",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Click here to view feedback
                </p>
              ) : null}
            </div>
          ) : (
            <p>you are offline</p>
          )}
        </div>
      )}
    </div>
  );
};

export default MiniAssignment;
