import React, { useState, useRef, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { awsSignOut, authData } from "../../../redux/auth/authSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";
import "./UserHeader.scss";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/constants";
import { makeStyles } from "@material-ui/core/styles";
import Notifications from "../../../assets/images/imageedit_5_9734520186 (1).png";
import Avatar from "@material-ui/core/Avatar";
import session from "redux-persist/lib/storage/session";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  userName: {
    fontSize: "15px",
    color: "#333333",
    "@media (max-width: 375px)": {
      width: "8rem",
    },
  },
  dropdownOptions: {
    "& > div > div": {
      width: "10rem",
      textAlign: "center!important",
      background: "#f7f7f7!important",
      border: "1px solid #ebebeb!important",
      "& > div > a": {
        textDecoration: "none",
        color: "black",
      },
      "& > div": {
        fontSize: "12px",
      },
    },
  },
}));

const trigger = (
  <span>
    <Avatar style={{ marginBottom: "-7px" }} name="user" />
  </span>
);

const UserHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userDetails = useSelector(authData);
  let bpid1;
  if (userDetails.curprg != undefined) {
    bpid1 = userDetails.curprg.bpid;
  }
  //console.log("user", userDetails);
  let location = useLocation();
  const [result, setResult] = useState(false);
  const resultVal = useRef(null);
  const resultUsn = useRef(null);
  const curTime = useRef(Math.floor(Date.now() / 1000));
  const sessionTime = useRef(
    Math.floor(Date.now() / 1000) + 4 * 60 * 60 * 1000
  );
  useEffect(() => {
    if (location.pathname == "/MyPrograms/programs") {
      //let bpid1 = userDetails.curprgcou.bpid;
      getUsn();
    }
  }, []);

  async function getUsn() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USN,
        bodyParam
      );
      console.log("usn", response);

      resultUsn.current = response[bpid1].usn;
      console.log("usn1", resultUsn.current);
      let key = "y4w7sUpFysaA1PiVUrYz_qJNXjiF4NVSRCVDIJcMZk3CyrXV";
      let oid = config.aws_org_id;
      let t1 = curTime.current;
      let t2 = sessionTime.current;
      let eid = resultUsn.current;
      /* alert(curTime.current);
      alert(sessionTime.current); */

      let createdKey = key.concat(oid, t1, t2, eid);
      console.log("resultF1", createdKey);
      resultVal.current = sha256(createdKey);
      console.log("resultF", resultVal.current);
      setResult(true);
    } catch (error) {
      console.error(error);
    }
  }

  const options = [
    {
      key: "profile",
      text: (
        <div><Link to="/profile">Profile</Link></div>
      ),
    },
    {
      key: "Help",
      text: (
        <div><Link to="/complaints">Complaints</Link></div>
      ),
    },
    {
      key: "sign-out",
      text: (
        <div onClick={() => {signingOut();}}>Sign-out</div>
      ),
    },
  ];
  function signingOut() {
    Auth.signOut();
    dispatch(awsSignOut());
  }
  function sha256(ascii) {
    function rightRotate(value, amount) {
      return (value >>> amount) | (value << (32 - amount));
    }

    var mathPow = Math.pow;
    var maxWord = mathPow(2, 32);
    var lengthProperty = "length";
    var i, j; // Used as a counter across the whole file
    var result = "";

    var words = [];
    var asciiBitLength = ascii[lengthProperty] * 8;

    //* caching results is optional - remove/add slash from front of this line to toggle
    // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
    // (we actually calculate the first 64, but extra values are just ignored)
    var hash = (sha256.h = sha256.h || []);
    // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
    var k = (sha256.k = sha256.k || []);
    var primeCounter = k[lengthProperty];
    /*/
    var hash = [], k = [];
    var primeCounter = 0;
    //*/

    var isComposite = {};
    for (var candidate = 2; primeCounter < 64; candidate++) {
      if (!isComposite[candidate]) {
        for (i = 0; i < 313; i += candidate) {
          isComposite[i] = candidate;
        }
        hash[primeCounter] = (mathPow(candidate, 0.5) * maxWord) | 0;
        k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
      }
    }

    ascii += "\x80"; // Append Ƈ' bit (plus zero padding)
    while ((ascii[lengthProperty] % 64) - 56) ascii += "\x00"; // More zero padding
    for (i = 0; i < ascii[lengthProperty]; i++) {
      j = ascii.charCodeAt(i);
      if (j >> 8) return; // ASCII check: only accept characters in range 0-255
      words[i >> 2] |= j << (((3 - i) % 4) * 8);
    }
    words[words[lengthProperty]] = (asciiBitLength / maxWord) | 0;
    words[words[lengthProperty]] = asciiBitLength;

    // process each chunk
    for (j = 0; j < words[lengthProperty]; ) {
      var w = words.slice(j, (j += 16)); // The message is expanded into 64 words as part of the iteration
      var oldHash = hash;
      // This is now the undefinedworking hash", often labelled as variables a...g
      // (we have to truncate as well, otherwise extra entries at the end accumulate
      hash = hash.slice(0, 8);

      for (i = 0; i < 64; i++) {
        var i2 = i + j;
        // Expand the message into 64 words
        // Used below if
        var w15 = w[i - 15],
          w2 = w[i - 2];

        // Iterate
        var a = hash[0],
          e = hash[4];
        var temp1 =
          hash[7] +
          (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) + // S1
          ((e & hash[5]) ^ (~e & hash[6])) + // ch
          k[i] +
          // Expand the message schedule if needed
          (w[i] =
            i < 16
              ? w[i]
              : (w[i - 16] +
                  (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) + // s0
                  w[i - 7] +
                  (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10))) | // s1
                0);
        // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
        var temp2 =
          (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) + // S0
          ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

        hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
        hash[4] = (hash[4] + temp1) | 0;
      }

      for (i = 0; i < 8; i++) {
        hash[i] = (hash[i] + oldHash[i]) | 0;
      }
    }

    for (i = 0; i < 8; i++) {
      for (j = 3; j + 1; j--) {
        var b = (hash[i] >> (j * 8)) & 255;
        result += (b < 16 ? 0 : "") + b.toString(16);
      }
    }
    return result;
  }

  //setResult(result1);

  const classes = useStyles();
  return (
    <div className="header">
      <div>
        {location.pathname == "/MyPrograms/programs" ? (
          result === true ? (
            <form
              name="autoForm"
              action="https://wsrctt.pearsonvue.com/testtaker/sso/Integration.htm"
              method="post"
            >
              {/*  <!-- <noscript><input type="submit" name="submit" value="Continue"/></noscript> --> */}
              ​{" "}
              {/* <input
                style={{
                  color: "white",
                  borderStyle: "groove ",
                  borderColor: "#E35F14 ",
                  height: "30px ",
                  lineHeight: "0px ",
                  width: "150px",

                  backgroundColor: "#E35F14",
                  fontSize: "15px ",
                  borderRadius: "3px ",

                  fontWeight: "bold",
                }}
                type="submit"
                name="submit"
                value="Take exam here"
              /> */}
              {/* <div
                style={{ width: "800px", height: "300px", paddingTop: "100px" }}
              >
                <pre>
                  {resultUsn.current}"a" {curTime.current}"b"{" "}
                  {sessionTime.current}"c"{resultVal.current}"k"
                </pre>
              </div> */}
              <input type="hidden" name="CLIENT_CODE" value="JSSAHER" />
              <input
                type="hidden"
                name="CLIENT_CANDIDATE_ID"
                value={resultUsn.current}
              />
              {/* <!-- <input type="hidden" name="VUE_CANDIDATE_ID" value="<vueCandidateId>" /> --> */}
              <input
                type="hidden"
                name="EXTERNAL_PAGE_TIMESTAMP"
                value={curTime.current}
              />
              <input
                type="hidden"
                name="EXTERNAL_ERROR_URL"
                value="https://www.jssaher.enhanzed.com/"
              />
              <input
                type="hidden"
                name="EXTERNAL_LOGOUT_URL"
                value="https://www.jssaher.enhanzed.com/"
              />
              <input
                type="hidden"
                name="EXTERNAL_RETURN_URL"
                value="https://www.jssaher.enhanzed.com/"
              />
              <input
                type="hidden"
                name="EXTERNAL_TIMEOUT_URL"
                value="https://www.jssaher.enhanzed.com/"
              />
              <input
                type="hidden"
                name="EXTERNAL_SESSION_TIMEOUT"
                value={sessionTime.current}
              />
              <input type="hidden" name="ACTION" value="scheduleExam" />
              <input
                type="hidden"
                name="EXTERNAL_AUTH_HASH"
                value={resultVal.current}
              />
            </form>
          ) : null
        ) : null}
      </div>
      <div>
        {location.pathname == "/dashboard" ? (
          <Link to="/quickTour">
            <Button
              className="headerButton"
              basic
              content="Quick tour"
              color="blue"
            />
          </Link>
        ) : null}
      </div>

      <Link to="/notification">
        <img src={Notifications} alt="headerIcon" className="header__icon" />
      </Link>
      <div className={classes.userName}>{userDetails.name}</div>
      <Dropdown className={classes.dropdownOptions} trigger={trigger} options={options} />
    </div>
  );
};

export default UserHeader;
