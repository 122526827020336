import React, { useState} from "react";
import AppHeader from "../Header/AppHeader";
import Footer from "../Footer/Footer";
import config from "../../config/aws-exports";
import { API, JS } from "aws-amplify";
import swal from "sweetalert";
import { Backdrop, makeStyles, CircularProgress } from "@material-ui/core";

import { Constants } from "../../config/constants";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  "& > *": {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PostQuires = () => {
  const [fileName, setFileName] = useState("");
  const [istLoading, setIstLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [userNameErr, setUserNameErr] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const classes = useStyles();

  const fileInputStyle = {
    display: "none",
  };

  const fileButtonStyle = {
    display: "inline-flex",
    alignItems: "center",
    padding: "0px 15px",
    backgroundColor: "rgb(38 104 183)",
    color: "white",
    cursor: "pointer",
    border: "none",
    borderRadius: "4px",
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setName("");
    setDescription("");
    setFileName("");
    setEmailErr("");
    setDescriptionErr("");
    setUserNameErr("");
  };
  const onChangeHandlerName = (event) => {
    setName(event.target.value);
  };

  const onChangeHandlerEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangeHandlerDescription = (event) => {
    setDescription(event.target.value);
  };
  async function handleFile(e) {
    if (e.target.files !== undefined && e.target.files[0]) {
      let file = e.target.files[0];
      let fileUploadType = "complentsPreLogin";
      uploadDocsToServer(file, fileUploadType);
      setFileName(file.name);
    }
  }

  async function uploadDocsToServer(file, fileUploadType) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        email: email,
        type: fileUploadType,
        filetype: file.type,
        filename: file.name,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      fileUpload(file, response, fileUploadType);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  async function fileUpload(file, url, fileUploadType) {
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
        console.log("fileUploadType", fileUploadType);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function handleSubmit() {
    let formIsValid = true;

    if (
      name === "" ||
      name === null ||
      (name != undefined && name.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter user name");
      return;
    }
    if (
      email === "" ||
      email === null ||
      (email != undefined && email.trim() === "")
    ) {
      formIsValid = false;
      setEmailErr("*Please enter your email-ID.");
      return;
    }

    if (
      description === "" ||
      description === null ||
      (description != undefined && description.trim() === "")
    ) {
      formIsValid = false;
      setDescriptionErr("*Please Enter the issue description.");
      return;
    }

    if (name !== "") {
      if (!name.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setUserNameErr("*Please enter alphabet characters only.");
        return;
      }
    }
    if (email !== "") {
      //regular expression for email validation
      let r = new RegExp(
        /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i
      );

      if (!r.test(email)) {
        formIsValid = false;
        setEmailErr("*Please enter valid email-ID.");
        return;
      }
    }

    if (formIsValid == true) {
      addcomplent();
    }

    async function addcomplent() {
      setIstLoading(true);
      const bodyParam = {
        body: {
          name: name,
          emailid: email,
          description: description,
          oid: config.aws_org_id,
          fileName: fileName,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        let response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/addcomplent",
          bodyParam
        );
        setIstLoading(false);
        swal({
          title: "Success",
          text: "Submitted successfully",
          icon: "success",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      } catch (error) {
        console.error(error);
      }

      resetForm();
    }
  }

  return (
    <div>
      {istLoading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <AppHeader />

      <div
        className="liststyle"
        align="left"
        style={{ padding: "9.7rem 5% 3rem", margin: "0rem auto 0rem" }}
      >
        <div>
          {/* <Link to={`/`}>
          <p
            style={{ color: "#01498e", marginTop: "10px", fontSize: "small" }}
          >{`< Back To Home`}</p>
        </Link> */}
          <h3
            style={{
              color: "gray",
              fontSize: "24px",
              paddingTop: "30px",
              paddingBottom: "-10px",
              textAlign: "center",
              marginBottom: "4rem",
              fontWeight: "bold",
            }}
          >
            Report A Query
          </h3>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3rem",
            width: "100%",
            marginBottom: "4rem",
          }}
        >
          <h3
            style={{
              flexBasis: "30%",
              fontSize: "1.6rem",
              fontWeight: "500",
            }}
          >
            Enter Name :
          </h3>
          <input
            type="text"
            style={{
              flexGrow: "1",
              height: "30px",
              outline: "none",
              fontSize: "1.5rem",
              paddingLeft: "1rem",
              border: "1px solid #01498e",
              boxShadow: "0px 0px 5px 0px lightgrey",
            }}
            value={name}
            onChange={onChangeHandlerName}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3rem",
            width: "100%",
            marginBottom: "2rem",
          }}
        >
          <h3
            style={{
              flexBasis: "30%",
              fontSize: "1.6rem",
              fontWeight: "500",
            }}
          >
            Enter Email ID :
          </h3>
          <input
            type="text"
            style={{
              flexGrow: "1",
              height: "30px",
              outline: "none",
              fontSize: "1.5rem",
              paddingLeft: "1rem",
              border: "1px solid #01498e",
              boxShadow: "0px 0px 5px 0px lightgrey",
            }}
            value={email}
            onChange={onChangeHandlerEmail}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3rem",
            width: "100%",
            marginBottom: "2rem",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3rem",
            width: "100%",
            marginBottom: "2rem",
          }}
        >
          <h3
            style={{
              flexBasis: "30%",
              fontSize: "1.6rem",
              fontWeight: "500",
            }}
          >
            Query Description:
          </h3>
          <textarea
            type="text"
            style={{
              flexGrow: "1",
              height: "200px",
              outline: "none",
              fontSize: "1.5rem",
              paddingLeft: "1rem",
              border: "1px solid #01498e",
              boxShadow: "0px 0px 5px 0px lightgrey",
            }}
            value={description}
            onChange={onChangeHandlerDescription}
          />
        </div>

        <div
          className="User_field"
          style={{
            display: "flex",

            marginBottom: "3rem",
          }}
        >
          <h3
            style={{
              flexBasis: "35%",
              fontSize: "1.6rem",
              fontWeight: "500",
            }}
          >
            Upload File :
          </h3>

          <label htmlFor="file" style={fileButtonStyle}>
            Choose File
            <input
              type="file"
              id="file"
              accept="image/*,application/pdf,.docx,"
              style={fileInputStyle}
              onChange={(e) => {
                handleFile(e);
              }}
            />
          </label>

          {fileName != "" || fileName != undefined ? (
            <h3
              style={{
                marginLeft: "20px",
              }}
            >
              {fileName}
            </h3>
          ) : null}
        </div>

        <div style={{ width: "100%", textAlign: "center" }}>
          <button
            style={{
              color: "#3372B5",
              backgroundColor: "white",
              borderRadius: "8px",
              fontSize: "15px",
              height: "28px",
              lineHeight: "13px",

              width: "100px",
              border: "2px solid #3372B5",
            }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
          <div style={{ color: "#cc0000", fontSize: "14px" }}>
            {userNameErr}
          </div>
          <div style={{ color: "#cc0000", fontSize: "14px" }}>{emailErr}</div>
          <div style={{ color: "#cc0000", fontSize: "14px" }}>
            {descriptionErr}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PostQuires;
