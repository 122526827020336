import React from "react";
import UserHeader from "../../Header/UserHeader/UserHeader";
import { FaBars } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
//import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Card from "@material-ui/core/Card";
import dashboardStyle from "./Dashboard.module.scss";
import { Constants } from "../../../config/constants";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../config/aws-exports";
import ProgressBar from "../../../modules/ProgressBar/ProgressBar";
import ClockGray from "../../../assets/svgjs/ClockGray";

const ViewAll = ({ handleToggleSidebar }) => {
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  console.log("userDetails", userDetails);

  let pgm = userDetails.viewallpgmscou;

  let pgmName = userDetails.curprgcou.ptitle;

  function titleclick(PValue) {
    if (userDetails.viewallpgmscou.page == "MyPrograms") {
      let pdata = userDetails.curprg;
      let sdata = { ...userDetails };
      sdata.curprgcou = { ...PValue };
      sdata.curprgcou.pid = pdata.pid;
      sdata.curprgcou.bpid = pdata.bpid;
      sdata.curprgcou.ptitle = pdata.ptitle;

      // alert(JSON.stringify(sdata.curprgcou));

      dispatch(awsSignIn(sdata));

      navigate("/course");
    } else {
      let sdata = { ...userDetails };
      // console.log("userDetails", userDetails);
      // for(let i = 0; i < pdata.length; i++){
      //   if(data.bpid == pdata[i].bpid){
      //     sdata.curprgcou = pdata[i];
      //   }
      // }
      sdata.curprgcou = { ...PValue };
      sdata.curprgcou.pid = pgm.pid;
      sdata.curprgcou.bpid = pgm.bpid;
      sdata.curprgcou.ptitle = pgm.ptitle;

      // alert(JSON.stringify(sdata));

      dispatch(awsSignIn(sdata));

      navigate("/course");
    }
  }

  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div>
        <div>
          <UserHeader />
        </div>

        <div>
          <h2 style={{ marginBottom:"2rem"}}> {pgmName}</h2>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "2rem" }}>
          {pgm !== undefined ? (
            <>
              {pgm.tcourses.map((Value, index, array) => {
                return (
                  <div
                    onClick={() => {
                      titleclick(Value);
                    }}
                    style={{ width: "30%" }}
                  >
                    <Card
                      className={dashboardStyle.card}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        alt=""
                        className={dashboardStyle.imageCard}
                        src={`https://${
                          Constants.DOMAIN
                        }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
                          Value.tid
                        }.png`}
                      />
                      <div className={dashboardStyle.progressbar}>
                        <ProgressBar color="orange" percent={Value.per} />
                      </div>
                      <p className={dashboardStyle.topicNameDashboard}>
                        {Value.ttitle}
                      </p>
                      <div>
                        <p style={{ marginTop: "100px" /* padding: "10px" */ }}>
                          <span style={{ marginLeft: "10px" }}>
                            <ClockGray
                              className={dashboardStyle.clock}
                              cls1="cls1"
                              cls2="cls2"
                            />
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "rgba(0, 0, 0, 0.6)",
                              marginLeft: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {Value.tdur} weeks
                          </span>
                        </p>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </main>
  );
};

export default ViewAll;
