import React from 'react';
import ReactPlayer from "react-player";
import { FaBars } from "react-icons/fa";
import UserHeader from "../../components/Header/UserHeader/UserHeader";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { Constants } from "../../config/constants";
import AppHeader from '../Header/AppHeader';
import Footer from '../Footer/Footer';
import QuickTourPreStyle from './QuickTour.module.scss'
function QuickTourPreLogin() {
  let userDetails = useSelector(authData);
  return (
    <div>
      <AppHeader />
      <div style={{height:"100vh"}}>
        <h2 className={QuickTourPreStyle.headingStyle} >Quick Tour</h2>
        <ReactPlayer
          url={`https://${Constants.DOMAIN}/jssaher-resources/videos/QUICKTOUR1.mp4`}
          controls={true}
          width="90%"
          height="70%"
          style={{ margin: "0 auto 30%",}}
        />
      </div>
      {/* <div className={QuickTourPreStyle.FooterQ}>
        <Footer />
      </div> */}
       <div style={{ width: "100%", position: "absolute", bottom: "0" }}>
        <Footer />
      </div>

      
    </div>
  )
}

export default QuickTourPreLogin;