import "./Formative.scss";
import Done from "../../../assets/svgjs/Done";
import Reject from "../../../assets/svgjs/Reject";
import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../../modules/Window/Window";
import CircularProgress from "@material-ui/core/CircularProgress";
import Formative from "./Formative";

const FormativeFeedback = (props) => {
let {
    imgUrl,
    Button,
    getQuiz,
    btnClick,
    setScore,
    handleCheck,
    produce,
    showScore,
    curObject,
    answerPrev,
    answerNext,
    qisLoading,
    setBtnClick,
    ansFcount,
    setAnsFcount,
    setTryAgain,
    handleClose,
    markComplete,
    answerReveal,
    quizQuestion,
    setShowScore,
    setQIsLoading,
    setFormativeAns,
    setAnswerReveal,
    feedbackButton,
    currentQuestion,
    setFeedbackButton,
    setCurrentQuestion,
    setQuizQuestion,
    getAssignmentDataPost
} = props;

const { height, width } = useWindowDimensions();
const scollToRef = useRef();

useEffect(() => {
    console.log('Mounted FormativeFeedback Quize');    
}, []);

return (
    <div className="body" style={{ height: (btnClick || showScore) ? height - 400 : '' }}>
        {btnClick ?(
            <div className="app1">
                { answerReveal ? (
                <div>
                    <div className="scrollQuiz">
                        <div className="question-section">
                            <div className="question-count">
                            <span>Question {currentQuestion + 1}</span>/
                            {quizQuestion.length}
                            </div>
                            <div
                            className="question-text" style={{textAlign: 'justify'}}
                            dangerouslySetInnerHTML={{
                                __html: quizQuestion[currentQuestion].istem,
                            }}>
                            </div>

                            {quizQuestion[currentQuestion].imageurl ? (
                            <div className="img_container">
                                <img
                                src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                                alt="item"
                                />
                            </div>
                            ) : null}
                        </div>

                        {currentQuestion > 0 ? (
                            <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            className="btn-size"
                            onClick={() => answerPrev()}
                            style={{ fontSize: "12px" }}>
                            Prev
                            </Button>
                        ) : null}
                        {currentQuestion + 1 === quizQuestion.length ? (
                            <div></div>
                        ) : (
                            <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            className="btn-siz"
                            onClick={() => answerNext()}
                            style={{ float: "right", fontSize: "12px" }}
                            >
                            Next
                            </Button>
                        )}
                        <br /><br /><br />

                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            className="btn-sizeClose"
                            style={{
                            backgroundColor: "white",
                            color: "black",
                            border: "1px solid black",
                            width: "100px",
                            marginLeft: "45%",
                            fontSize: "12px",
                            }}
                            onClick={() => {
                            //handleClose();
                            setCurrentQuestion(0);
                            setShowScore(true);
                            setAnswerReveal(false);
                            }}>Close
                        </Button>
                    </div>
                </div>
                ) :(
                    <div className="scrollQuiz" ref={scollToRef}>
                        <div className="question-section">
                            <div className="question-count">
                                <span>Question{currentQuestion + 1} </span>/
                                {quizQuestion.length}
                            </div>
                            <div
                                className="question-text" style={{textAlign: 'justify'}}
                                dangerouslySetInnerHTML={{
                                __html: quizQuestion[currentQuestion].istem,
                                }}>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: `${
                                quizQuestion[currentQuestion].imageurl ? "4fr": "1fr"} `,
                                gridGap: "2rem",
                            }}>
                            <center>
                                <div style={{ width: "270px" }}>
                                {quizQuestion[currentQuestion].imageurl ? (
                                    <div className="img_container">
                                    <img
                                        src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                                        // src={quizImage}
                                        alt="item"
                                    />
                                    </div>
                                ) : null}
                                </div>
                            </center>
                            <div style={{ width: "100%" }}>
                            {quizQuestion[currentQuestion].iopts.map(
                                (answerOption, idx) => (
                                <React.Fragment key={"aaa" + idx}>
                                    <div style={{ display: "flex" }}>
                                    <button
                                        className={`buttonQuizFb ${
                                        answerOption.Selected ? "ddd" : ""
                                        }`}

                                        style={{ borderColor: (answerOption.Myselection == 'true' && quizQuestion[currentQuestion].userAns == 'true') ?'#0f80a4' : '' }}
                                        onClick={() => {
                                        handleCheck(answerOption, idx);
                                        console.log("ans", answerOption, idx);
                                        }}
                                        // disabled={feedbackButton.submitted}
                                        disabled={quizQuestion[currentQuestion].userAns == 'true' }
                                    >
                                        {answerOption.content}
                                    </button>
                                    {quizQuestion[currentQuestion].userAns == 'true' &&
                                    answerOption.correct == true ? (
                                        <Done
                                        className="right_size"
                                        cls1="cls1_D"
                                        cls2="cls2_D"
                                        />
                                    ) : (
                                        quizQuestion[currentQuestion].userAns == 'true' && (
                                        <Reject
                                            className="Wrong_size"
                                            cls1="cls1_D"
                                            cls2="cls2_D"
                                        />
                                        )
                                    )}
                                    {answerOption.Selected &&
                                    feedbackButton.submitted &&  ( 
                                        <p style={{fontSize:"12px"}}>Your response</p>
                                    )}
                                    </div>
                                    { answerOption.Selected  &&
                                    quizQuestion[currentQuestion].userAns == 'true' &&
                                    (answerOption.feedback === undefined ? (
                                        ""
                                    ) : (
                                        <button className={`buttonQuizSq`}>
                                        {answerOption.feedback}
                                        </button>
                                    ))}
                                    {/* {answerOption.Selected &&
                                    feedbackButton.submitted &&  ( 
                                        <button className={`buttonQuizSq`}>
                                            { answerOption.feedback  }
                                        </button>
                                    )} */}
                                </React.Fragment>
                                )
                            )}
                            </div>
                            <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "3rem",
                            }}>
                            <div style={{display:'flex'}}>
                                {!feedbackButton.hideSubmit && (
                                <button
                                    className="buttonFb"
                                    // disabled={!quizQuestion[currentQuestion].userAns}
                                    disabled={quizQuestion[currentQuestion].Qsubmit == undefined
                                         || quizQuestion[currentQuestion].Qsubmit == 'false' }
                                    onClick={() => {
                                    setQuizQuestion((currentQues) =>
                                    produce(currentQues, (v) => {
                                        v[currentQuestion].userAns = 'true';
                                        v[currentQuestion].Qsubmit = 'false';
                                    })
                                    );
                                    setAnsFcount(ansFcount+1)
                                    feedbackButton.disableSubmit = true;
                                    feedbackButton.hideNext = false;
                                    feedbackButton.hideSubmit = true;
                                    feedbackButton.submitted = true;
                                    setFeedbackButton({
                                        ...feedbackButton,
                                    });
                                    }}>
                                    Submit
                                </button>
                                )}
                                {!feedbackButton.hideNext && (
                                <>
                                    {currentQuestion > 0 ? (
                                    <button
                                        className="buttonFb"
                                        onClick={() => {
                                            setQuizQuestion((currentQues) =>
                                            produce(currentQues, (v) => {
                                                v[currentQuestion].Qsubmit = 'false';
                                            })
                                            );
                                        answerPrev();
                                        setFeedbackButton({
                                            disableSubmit: false,
                                            hideNext: false,
                                            hideSubmit: false,
                                            submitted: true,
                                        });
                                        }}>
                                        Prev
                                    </button>
                                    ) : ('')}
                                    {currentQuestion !== quizQuestion.length - 1 ? (
                                    <button
                                        className="buttonFb"
                                        onClick={() => {
                                        answerNext();
                                        setQuizQuestion((currentQues) =>
                                        produce(currentQues, (v) => {
                                            v[currentQuestion].Qsubmit = 'false';
                                        })
                                        );
                                        setFeedbackButton({
                                            disableSubmit: true,
                                            hideNext: false,
                                            hideSubmit: false,
                                            submitted: false,
                                        });
                                        setQuizQuestion((currentQues) =>
                                            produce(currentQues, (v) => {
                                            v[currentQuestion].iopts.forEach(
                                                (opt) => {
                                                opt.Selected = false;
                                                }
                                            );
                                            })
                                        );
                                        scollToRef.current.scrollIntoView()
                                        }}>
                                        
                                        Next
                                    </button>
                                    ) : (<>{currentQuestion + 1 === ansFcount ? (
                                        <button
                                        className="buttonFb"
                                        onClick={() => {
                                        markComplete();
                                        setScore(0);
                                        setTryAgain(false);
                                        setFormativeAns([]);
                                        }}
                                    >
                                        Complete
                                    </button>
                                    ) 
                                    : ('')}
                                   </> )}
                                </>
                                )}
                            </div>
                            <div>
                                <button
                                className="buttonFb-a"
                                onClick={() => {
                                    if (
                                    currentQuestion !==
                                    quizQuestion.length - 1
                                    ) {
                                    getAssignmentDataPost();
                                    setBtnClick(false);
                                    handleClose();
                                    setScore(0);
                                    setCurrentQuestion(0);
                                    setTryAgain(false);
                                    setFormativeAns([]);
                                    } else {
                                    if (!feedbackButton.hideNext) {
                                        markComplete();
                                        setScore(0);
                                        setTryAgain(false);
                                        setFormativeAns([]);
                                    } else {
                                        getAssignmentDataPost();
                                        setBtnClick(false);
                                        handleClose();
                                        setScore(0);
                                        setCurrentQuestion(0);
                                        setTryAgain(false);
                                        setFormativeAns([]);
                                    }}
                                }}>Exit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
        ): (
        <div style={{ fontSize: "14px", display: 'contents' }}>
            <ol style={{textAlign:'justify'}}>
            <li>
            {" "}
            Interpret the passage from the Author's point of view.
            </li>
            <li> Mentally construct meaning from the text.</li>
            <li>
            {" "}
            Answer a few questions based on the passage reading, by picking the right responses for each question.
            </li>
           </ol>
            {qisLoading ? (
            <CircularProgress color="default" size={30} />
            ) : (
            
            <button
                className="btnQuizLD"
                onClick={() => {
                getQuiz(curObject);
                setShowScore(false);
                setQIsLoading(true);
                setFeedbackButton({
                    disableSubmit: true,
                    hideNext: false,
                    hideSubmit: false,
                    submitted: false,
                });
                }}>Start
            </button>
            
            )}
        </div>
        )}
    </div>
    );
}

export default FormativeFeedback;
