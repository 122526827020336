// Dependencies imports
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FaBars } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Fade, CardMedia } from "@material-ui/core";
import FileViewer from "react-file-viewer";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

//import Modal from "@material-ui/core/Modal";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  "& > *": {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
}));

const Complaints = (handleToggleSidebar) => {
  const classes = useStyles();
  let userDetails = useSelector(authData);
  let navigate = useNavigate();
  const [istLoading, setIstLoading] = useState(false);
  const [complents, setComplents] = useState([]);
  const [openFile, setOpenFile] = useState(false);
  const [bpid, setBpid] = useState("");
  const [filename, setFilename] = useState("");
  const [email, setEmail] = useState("");
  const [fileType, setFileType] = useState("");
  useEffect(() => {
    getComplents();
  }, []);

  async function getComplents() {
    console.log("Status", userDetails);
    let body1;

    setIstLoading(true);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("bodyParam", bodyParam);
    try {
      console.log(bodyParam.body);
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getcomplents",
        bodyParam
      );

      if (response == null || response == undefined) {
        setComplents(undefined);
      } else {
        setComplents(response.complents);
      }

      setIstLoading(false);
      console.log("Complents", complents);
    } catch (error) {
      console.error(error);
    }
  }

  const tableStyle = {
    borderCollapse: "collapse",
    width: "100%",
  };

  const tdStyle1 = {
    padding: "8px",
    textAlign: "left",
    border: "1px solid #ddd",
    fontSize: "14px",
  };

  const thStyle = {
    backgroundColor: "#f2f2f2",
    padding: "8px",
    textAlign: "left",
    border: "1px solid #ddd",
    fontSize: "16px",
  };

  const tdStyle = {
    padding: "8px",
    textAlign: "left",
    border: "1px solid #ddd",
    fontSize: "14px",
    cursor: "pointer",
    textDecoration: "underline",
  };

  const handleFileOpen = () => {
    let extend =
      filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
      filename;
    console.log("extend", extend);

    setFileType(extend);
    setOpenFile(true);
  };

  const handleFileClose = () => {
    setFileType("");
    setOpenFile(false);
  };

  return (
    <main>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalConfirm}
        open={openFile}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFile}>
          <div
            style={{
              backgroundColor: "white",
              width: "80%",
              transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              height: "80vh",
              padding: "2rem",
              top: "50%",
              left: "50%",
              textAlign: "center",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "max-content",
                marginBottom: "2rem",
              }}
            >
              <h3></h3>
              <button onClick={handleFileClose}>X</button>
            </div>

            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                height: "50rem",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              {fileType == "docx" ? (
                <FileViewer
                  fileType="docx"
                  filePath={`https://${
                    Constants.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/complents/${bpid}/${email}/${filename}`}
                  onError={(e) => console.log("Error:", e)}
                />
              ) : (
                <CardMedia
                  style={{ height: "100%", width: "100%" }}
                  className={classes.root}
                  alt="ProfilePage"
                  component="iframe"
                  src={`https://${
                    Constants.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/complents/${bpid}/${email}/${filename}`}
                />
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>

      <div>
        <UserHeader />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>Complaints / Grievances Details</h3>

        <button
          style={{
            backgroundColor: "#3372b5",
            // width: "100px",
            height: "40px",
            fontSize: "14px",
            border: "none",
            color: "white",
            //  marginBottom: "2rem",
            borderRadius: "5px",
            margin: "auto",
            fontWeight: "bold",
          }}
          onClick={() => navigate("/addComplaints")}
        >
          Add Complaints
        </button>
      </div>

      <div>
        {istLoading && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {complents == undefined || complents.length == 0 ? (
          <>
            <div style={{ margin: "3rem" }}>
              <h3 className={classes.heading1}>No Complaints</h3>
            </div>
          </>
        ) : (
          <div style={{ marginTop: "30px" }}>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Sl No</th>
                  <th style={thStyle}>Description</th>
                  <th style={thStyle}>File Uploaded</th>
                </tr>
              </thead>
              <tbody>
                {complents.map((item, index) => (
                  <tr key={index}>
                    <td style={tdStyle1}>{index + 1}</td>
                    <td style={tdStyle1}>{item.description}</td>
                    {item.uploadedFile == "" ||
                    item.uploadedFile == undefined ? (
                      <td style={tdStyle1}>No Files</td>
                    ) : (
                      <td
                        style={tdStyle}
                        onClick={() => {
                          // setDescription(item.description);
                          setFilename(item.uploadedFile);
                          setEmail(item.email);
                          setBpid(item.bpid);
                          handleFileOpen();
                        }}
                      >
                        {item.uploadedFile}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </main>
  );
};

export default Complaints;
