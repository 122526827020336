import { createSlice } from '@reduxjs/toolkit';

export const iLearnSlice = createSlice({
  name: 'ilearn',
  initialState: {
    value: 0,
  },
  reducers: {
    // increment: state => {
      
    //   state.value = "hi";
    // },
    // decrement: state => {
    //   state.value = "hi";
    // },
    // incrementByAmount: (state, action) => {
    //   state.value = action.payload;
    // },
    setIL: (state, action) => {
      state.value = {...action.payload};
    },
    resetIL: state => {
      state.value = 0;
    },
  },
});

// Action creators are generated for each case reducer function
//export const { increment, decrement, incrementByAmount } = iLearnSlice.actions;
export const { setIL, resetIL } = iLearnSlice.actions;


// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

export const iLData = state => state.ilearn.value;

export default iLearnSlice.reducer;
