import React, { useState } from "react";
import ViewAll from "../components/HomeScreen/Dashboard/ViewAll";
import Aside from "../modules/SideNav/Aside";

function ViewAllLayout() {

    const rtl = false;

    const [toggled, setToggled] = useState(false);
  
    const handleToggleSidebar = (value) => {
      setToggled(value);
    };
  return (
    <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
    <Aside
      rtl={rtl}
      toggled={toggled}
      handleToggleSidebar={handleToggleSidebar}
    />
    <ViewAll handleToggleSidebar={handleToggleSidebar} />
  </div>
  )
}

export default ViewAllLayout