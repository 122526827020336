import React from 'react';

const FeedbackIcon = ({ className, cls1, cls2 }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 120"
            className={className}
        >
            <defs></defs>
            <path
                className={cls1}
                d="M51.1,12.83A37.17,37.17,0,1,0,88.21,50.08,37.24,37.24,0,0,0,51.1,12.83Z" />
            <path
                className={cls2}
                d="M47.32,71.19a3.49,3.49,0,0,1-1.67-1.39q-3.39-4.51-6.81-9A3.25,3.25,0,0,1,38,59.1a2.45,2.45,0,0,1,1.3-2.44A2.48,2.48,0,0,1,42,57c.93.77,1.87,1.52,2.82,2.28a1.26,1.26,0,0,0,1.41.21A1.22,1.22,0,0,0,47,58.24c0-5.35,0-10.71,0-16.06a4,4,0,0,1,.12-1.1,2.45,2.45,0,0,1,4.79.66c0,2.75,0,5.5,0,8.25v.45l.12,0c0-.09.12-.18.17-.27a2.46,2.46,0,0,1,4.64,1.18v1.53l.11,0,.19-.3a2.45,2.45,0,0,1,4.61,1.16V55a.53.53,0,0,0,.13.38c.06-.11.12-.22.19-.33a2.45,2.45,0,0,1,4.58,1.2c0,2.3.06,4.6-.11,6.89a28.19,28.19,0,0,1-1.58,6.93,1.75,1.75,0,0,1-1.26,1.11Z" />
            <path
                className={cls2}
                d="M42.73,54.39a1.38,1.38,0,0,1-.72-.2A13.82,13.82,0,1,1,61.47,49a1.36,1.36,0,1,1-2.39-1.28,11.1,11.1,0,1,0-15.63,4.21,1.36,1.36,0,0,1-.72,2.51Z" />
            <path
                className={cls2}
                d="M43.58,48.09a1.38,1.38,0,0,1-1.09-.54,8.53,8.53,0,1,1,14.78-2.17,1.36,1.36,0,1,1-2.54-1,5.8,5.8,0,1,0-11.24-2,5.72,5.72,0,0,0,1.17,3.5,1.36,1.36,0,0,1-.26,1.91A1.33,1.33,0,0,1,43.58,48.09Z" />
        </svg>

    )
}
export default FeedbackIcon;
