import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
} from "semantic-ui-react";
import footerStyle from "./Footer.module.scss";
import logo from "../../assets/images/logo.jpg";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";

import edLogo from "../../assets/images/edlogo.png";
import { ImportantDevices } from "@material-ui/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
function Footer() {
  return (
    <div className="footer">
      <Menu
        secondary
        className={footerStyle.footerStyle}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <div style={{ height: "100%", width: "120px" }}>
          <Image
            size="small"
            src={`https://${
              Constants.DOMAIN
            }/${config.aws_org_id.toLocaleLowerCase()}-resources/images/org-images/logo.jpg`}
            style={{
              marginRight: "1.5em",
              height: "100%",
              width: "100%",
            }}
          />
        </div> */}
        <div
          className={footerStyle.footerContant}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ color: "white", margin: "20px" }}>
            For Any Queries &nbsp;
            <Link
              to={`/postquires`}
              style={{
                color: "white",
                // textDecoration: "underline",

                border: "solid 2px",

                padding: "5px 7px",
                borderRadius:"4px"
              }}
            >
              Click Here
            </Link>
          </div>

          <Menu.Item
            position="right"
            className="sidebar-btn-wrapper"
            style={{
              padding: "8px 24px",
              //float: "right",
              margin: "0px!important",
            }}
          >
            <a
              href="https://enhanzed.com/"
              target="_blank"
              className="sidebar-btn"
              rel="noopener noreferrer"
            >
              <div className="legal" style={{ fontSize: "14px" }}>
                &copy; 2024 Powered by <img src={edLogo} alt="" />
              </div>
            </a>
          </Menu.Item>
        </div>
      </Menu>
    </div>
  );
}

export default Footer;
