import React, { useEffect, useRef, useState } from "react";
// import SharedHeader from "../../components/Header/SharedHeader/SharedHeader";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API, Auth, JS } from "aws-amplify";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
function SharedObject() {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "blue",
    },
  }));
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const params = useParams();
  let query = useQuery();
  const [sharedObject, setSharedObject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let val2 = query.get("val2");
  let val3 = query.get("val3");
  let val1 = query.get("val1");
  let val4 = query.get("val4");
  let val5 = query.get("val5");
  // let val6 = query.get("val6");
  // let val7 = query.get("val7");
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    getSharedObject();
    setIsLoading(true);
  }, [val2, val1]);

  const getSharedObject = async () => {
    // alert(JSON.stringify(params, null, 2));
    const bodyParam = {
      body: {
        val2,
        val1,
        val3,
        val4,
        val5,
        val6: config.aws_org_id,

        // oid: "JSSAHER",
        // eid: "d0c2b2ef-801f-41ab-9619-fce1c0694ea6",
        // courseid: "CR511",
        // val6: "CR511-1-0",
        // nid: "CR511-1",
        // bcourseid: "CR5110038671700",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getSharedObject",
        bodyParam
      );
      //   var obj2 = {};
      //   obj2.objid = val6;
      //   obj2.ur_id = val4;
      //   obj2.op =  val7 ?? 1;
      //   obj2.cid = val1;
      //   obj2.tid = val1;
      //   obj2.atype = 0;
      //   obj2.schema = config.schema;
      //   if(response?.suspenddata?.length > 0){
      //     obj2.sdta = response.suspenddata;
      //   }
      //   window.cudetails = obj2;
      setSharedObject(response);
      //   const expires = new Date().getTime() + 60 * 60 * 1000;
      //   Cookies.set("CloudFront-Expires", expires);
      //   Cookies.set("CloudFront-Policy", response.Policy);
      //   Cookies.set("CloudFront-Signature", response.Signature);
      //   Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
      setIsLoading(false);
      //updateSharedObject();
    } catch (err) {
      console.error(err);
    }
  };
  const updateSharedObject = async () => {
    // alert(JSON.stringify(params, null, 2));
    const bodyParam = {
      body: {
        tenant: val2,
        id: val1,
        oid: config.aws_org_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateSharedAnalytics",
        bodyParam
      );
      console.log(response);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          {sharedObject.ourl ? (
            <div style={{ width: "100vw", height: "92.5vh" }}>
              <iframe
              style={{ height:"100%",width:"100%"}}
                title="This is a unique title"
                src={sharedObject.ourl}
                allowFullScreen
                frameBorder="no"
              />
            </div>
          ) : (
            <div
              style={{
                height: "90vh",
                width: "100vw",
                display: "grid",
                placeItems: "center",
              }}
            >
              <div
                style={{
                  width: "400px",
                  height: "200px",
                  background: "#1974b5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: ".8rem",
                }}
              >
                <p
                  style={{
                    color: "#fff",
                    fontSize: "1.75rem",
                    fontWeight: "600",
                  }}
                >
                  There is nothing here to display
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default SharedObject;
