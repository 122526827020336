import React from "react";
import Footer from "../../../components/Footer/Footer";
import AppHeader from "../../../components/Header/AppHeader";
import { Link } from "react-router-dom";
import "./AboutUs.scss";
function AboutJSSAHER() {
  return (
    <div>
      <AppHeader />

      <div align="left" style={{ padding: "10rem 5%" }}>
        <Link to={`/`}>
          <p
            style={{ color: "#01498e", marginTop: "10px", fontSize: "small" }}
          >{`< Back To Home`}</p>
        </Link>
        <p className="headingStyle">About JSSAHER</p>
        {/* <p className="subHeading"
				>
					The Journey of Quality and Excellence of
					JSS Academy of Higher Education & Research
				</p> */}
        <p className="contentAboutUs">
          The Journey of Quality and Excellence of JSS Academy of Higher
          Education & Research JSS Academy of Higher Education & Research,
          Mysuru since its establishment has significantly strengthened its
          teaching, research and service efforts to realize its vision and
          mission.
          <br /> <br />
          Under the leadership and gracious Patronage and Blessings of His
          Holiness Jagadguru Sri Shivarathri Deshikendra Mahaswamiji, the
          Chancellor of JSS Academy of Higher Education & Research, the guidance
          from a highly distinguished Board of Management, and the stewardship
          of the Pro Chancellor, Dr.B.Suresh, a noted multifaceted leader of
          repute, Vice Chancellor, Dr. Surinder Singh , an acclaimed
          Administrator, JSS Academy of Higher Education & Research, Mysuru has
          made great progress in grooming graduates, postgraduates, and Ph.D.
          researchers by providing effective value based education across the
          institutions by focusing on overall development of an individual
          through state-of-art facilities to make the learner a useful citizen
          to the society.
          <br /> <br />
          JSS Academy of Higher Education & Research, Mysuru is a Health Science
          focused Higher Education Institution and comprising of Faculties of
          Medicine, Dentistry, Pharmacy, Biomedical Sciences, Life Sciences,
          Management Studies and Yoga. It has, as its constituent colleges the
          JSS Medical College, the JSS Dental College& Hospital and the JSS
          College of Pharmacy at its main campus at Mysuru and the JSS College
          of Pharmacy at Ootacamund, Tamil Nadu (off-campus). Besides the four
          constituent colleges, the deemed university has established 7
          departments including the Department of Water and Health, the
          Department of Health System Management Studies, Department of
          Nutrition and Dietetics and Centre for Distance and Online Education.
          <br /> <br />
          National Assessment and Accreditation Council (NAAC) accredited JSS
          Academy of Higher Education & Research (Deemed to be University) with
          “A+” Grade (CGPA of 3.48) in 2019.
          <br />
          <br />
          JSS Academy of Higher Education & Research, Mysuru has been ranked
          among the top 50 universities/Higher Education Institutions in India
          continuously for the last six years by NIRF- National Institutional
          Ranking Framework and was also ranked 4th among Private Higher
          Education Institutions by ARIIA Ranking-Atal Ranking of Institutions
          on Innovation Achievements, initiative by MHRD, Government of India.
        </p>
        <p className="contentAboutUs">
          JSS Academy of Higher Education & Research, Mysuru is ranked in the
          251-300 band globally and first nationally in the subject category of
          Clinical, Preclinical and Health, which includes Medicine, Dentistry,
          Pharmacy and Allied Health Sciences and also made a mark among top 500
          Universities globally with rank band 401-500 and among the top 5
          Higher Education Institutions nationally and ranked third in India and
          ranked 64th in the ranking of higher education institutions amongst
          emerging economies by Times Higher Education (THE) Rankings.
        </p>
        <p className="contentAboutUs">
          JSS Academy of Higher Education & Research was also ranked among top
          200 in Times Higher Education World University Impact Ranking 2019
          with a rank band 101- 200 and ranked 1st in India, The Impact Ranking
          assess universities against the United Nations' Sustainable
          Development Goals. THE Asia university ranking 2019 is 62nd.
        </p>
        <p className="contentAboutUs">
          QS- Quacquarelli Symonds Ranking agency rated JSSAHER with 4 stars in
          Excellence and became the first Health Science institution nationally
          to be awarded with 4 stars
        </p>
        <br></br>

        <p className="subHeading">
          Ranking of JSS Academy of Higher Education & Research
        </p>
        {/* <p className="subHeading_vertical"
				>
					<strong className="sideHedaing">I. NIRF RANKING 2019 :</strong></p><br />
				<p className="contentAboutUs">Honourable President of
				India, Shri.Ram Nath Kovind announced the National Institutional Ranking Framework (NIRF) India
				Rankings 2019 on April 8, 2019. The rankings were announced under nine categories - Overall,
				Universities, Engineering, Colleges, Management, Pharmacy, Medical, Architecture, and Law.
				</p>
				<br />
				<p className="contentAboutUs">
					<strong> JSS Academy of Higher Education & Research, Mysuru and its constituents colleges had been
					participating in NIRF ranking for the last 4 years and sustaining its position under top 50
						Universities/ Higher Education Institutions in India.</strong>
				</p> */}
        <ol className="contentAboutUs" style={{ padding: "0px 0px 0px 20px" }}>
          <li>
            JSS Academy of Higher Education &Research, Mysuru was ranked 34th
            out of 1479 University registered for NIRF 2019.
            <br />
            JSS Academy of Higher Education & Research moved up in ranking from
            2018, which was at the 37 for university.
          </li>
          <li>
            JSS Medical Colleges was ranked 17th out of 113 Medical
            Institutions/colleges in India.
          </li>
          <li>
            JSS College of Pharmacy, Ooty was ranked 8th out of 301 Pharmacy
            Colleges in India, which was at 15th rank in 2018.
          </li>
          <li>
            JSS College of Pharmacy, Mysore was ranked 10th out of 301 Pharmacy
            Colleges in India and sustaining its position for the last four
            years at 10th Rank.
          </li>
          <li>
            JSS Academy of Higher Education & Research is granted E-LEAD
            certification by QS I-GAUGE E-learning excellence for Academic
            Digitization certification with Score 150 out of 150.
          </li>
          <li>
            JSS Academy of Higher Education & Research is the first and only
            college in India to have Accreditation Council for Pharmacy
            Education, USA continues International Certification for Pharm.D
            program.
          </li>
          <li>
            JSS Academy of Higher Education and Research is ranked 31 among the
            top 100 universities in India. The Results were announced during QS
            India Summit on 22nd October 2019.
          </li>
          <li>
            JSS Academy of Higher Education & Research is ranked 4th among all
            the Private Higher Education Institution in India by ARIIA ranking
            Atal Ranking of Institutions on innovation achievements -2019.
            <br />
            'Quality' and 'Excellence' have been the key drivers of the JSSAHER
            which is evident from the various strides it has made in the
            benchmarking initiatives nationally and internationally in a short
            span of ten years. The good governance structure and extraordinary
            leadership of the institution has played a catalyst's role for it to
            progress quickly and continue its journey to the next level of
            advancement. The committed faculty, the diverse student community,
            the strategic national and international co-operations, and infusion
            of right number of resources on right projects have added value
            towards its success journey. The integration of medical sciences
            with the life sciences and the allied health sciences has brought in
            a culture of inter professional education and research which has
            added uniqueness to the education and research environment of the
            institution.
          </li>
        </ol>
        <br />
      </div>
      <div style={{ width: "100%", position: "absolute", bottom: "0" }}>
        <Footer />
      </div>
    </div>
  );
}
export default AboutJSSAHER;
