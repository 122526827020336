import React from "react";
import Footer from "../../../components/Footer/Footer";
import AppHeader from "../../../components/Header/AppHeader";
import onlineLearn from "../../../assets/images/e-VBAB.png";
import "./AboutUs.scss";
import { Link } from "react-router-dom";
function AboutOnlineLearning() {
  return (
    <div>
      <AppHeader />

      <div align="left" style={{ padding: "10rem 5%", align: "center" }}>
        <Link to={`/`}>
          <p
            style={{ color: "#01498e", marginTop: "10px", fontSize: "small" }}
          >{`< Back To Home`}</p>
        </Link>

        <p className="headingStyle">Online Program</p>
        <div align="left" style={{ maxWidth: "100%", textAlign: "justify" }}>
          {/* <img alt="" src={onlineLearn} style={{ objectFit: "cover", margin: "10px 0px 0 15px", width: "40%", height: "100%", float: "right" }}></img> */}
          <p className="paraContent">
            Learning through Online mode has gained momentum in recent years.
            With the advent of innovations in Information Technology and
            regulations updating by the statutory bodies, it has become possible
            to impart degrees, diplomas, and certificate programmes through
            online mode in India. The advantage of flexibility in learning time
            this mode of study, the learner can acquire knowledge from
            ‘Anywhere, Anytime’. <br />
            <br />
            JSS ACADEMY OF HIGHER EDUCATION AND RESEARCH, offers online
            programmes which are in niche areas of learning providing better
            opportunities to graduates for good placements and for working
            professionals to upgrade their qualifications at their convenience
            enabling career advancement and promoting lifelong learning. <br />
            <br />
            <p className="subHeading">Admissions</p>
            <p className="subHeading">Online Program Admissions</p>
            For all online studies with JSS Academy of Higher Education &
            Research (JSS AHER), you will need the following: <br />
            <br />
            Medium of Instruction at bachelor’s degree to be in English.
            Comfortable to work on a computer (Laptop/Desktop/Tablet/Smartphone)
            with broadband internet access. This is vital for online learning.{" "}
            <br /> <br />
            Next you should decide on the course you'd like to study. <br />{" "}
            <br />
            Click on Programs to see Programs that are available based on your
            specialization and Interest to learn further.
            <br />
            <br />
            For the Diploma Programs across different specializations, you need
            to have formal qualifications - a bachelor’s degree in the
            respective specialization from a UGC recognized University. <br />{" "}
            <br />
            For Undergraduate degrees you will be required to hold a 10+2 or any
            equivalent course from a Recognized Schools or Colleges. <br />{" "}
            <br />
            For postgraduate degrees you will be required to hold a bachelor’s
            degree from a UGC Recognized University. <br /> <br />
            <p className="subHeading">Application</p>
            Once you have decided on a course, then register with JSS AHER's
            easy application process. <br /> <br />
            <p className="subHeading">How to apply</p>
            Enrolling with The JSS AHER's Online Education Program is quick and
            easy: <br /> <br />
            <ol
              className="contentAboutUs"
              style={{ padding: "0px 0px 0px 20px" }}
            >
              <li>
                Register on our Online Learning Portal: Click here to register
                on our online-learning portal
              </li>
              <li>
                Choose your Program: View our Programs and choose them, based on
                your qualification and experience.
              </li>
              <li>
                For all Instructor led programs, you can fill the application
                form by clicking the Apply Button on the specific program page
              </li>
              <li>
                Our Program Counsellors would validate your application: You
                would get an e-mail confirmation on your registered mail address
                within 3 working days upon submitting your application. The
                email would have a link to the payment gateway for you to make
                course fees payment
              </li>
            </ol>
            <br />
            Start Learning: Welcome to JSS Academy of Higher Education &
            Research - Online Education Programme! <br />
            <br />
            Start accessing Couse-1 of the Program/s: Next, we'll ask you to
            select your first Unit. (Note, unless completion of previous
            sub-topic, the platform would not allow you to navigate to the next
            sub-topic). <br />
            <br />
            <p className="subHeading">
              e VBAB Project by Ministry of External Affairs
            </p>
            External Affairs Minister of India formally launched the
            e-VidyaBharti (Tele-education) and e-ArogyaBharti (Tele-medicine)
            Project (e-VBAB). <br />
            <br />
            Minister of External Affairs unveiled the tele-education portal
            www.iLearn.gov.in, which provides access for African students in
            various disciplines including technology, education, mathematics and
            sciences, humanities and arts and teacher training. JSS AHER is one
            of the Private universities of India eligible to offer scholarships
            to Africans to pursue Undergraduate and Post Graduate courses under
            e VBAB project. <br />
            <br />
            e-VBAB has been well received by African countries. The e-VBAB
            Network Project will be completely funded by the Government of India
            for its entire duration and will be open for participation to all
            our partner countries in Africa. The Project is an important
            milestone in our development partnership with Africa and seeks to
            equip African youth to meet the challenges of the 21st century
            knowledge economy. <br />
            <br />
            <p className="subHeading">
              Enrolling with The JSS AHER's Online Education Program through e
              VBAB project.
            </p>
            <div className="imageContainerSection">
              <div>
                Prioritizing the education of the people all around the World
                for the 21st century, India has committed scholarships to
                students and professionals in Africa by providing opportunities
                in online education. These include undergraduate and
                postgraduate degrees and continued medical education. The
                scholarship will cover the tuition fees to attend an Indian
                university that will award the degree. <br />
                <br />
                The process and requirements are listed below, based on the
                chosen course of study. <br />
                <br />
              </div>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <img
                  alt=""
                  src={onlineLearn}
                  style={{
                    objectFit: "cover",
                    width: "60%",
                    height: "70%",
                    float: "right",
                  }}
                ></img>
              </div>
            </div>
            <p className="subHeading">How to apply</p>
            <ol
              className="contentAboutUs"
              style={{ padding: "0px 0px 0px 20px" }}
            >
              <li>
                Learners can go to iLearn portal and navigate to JSS Academy of
                Higher Education & Research.
              </li>
              <li>
                Look for the courses you want to pursue and register for the
                course from there.
              </li>
              <li>
                You will be registered for the selected online course in JSS
                AHER
              </li>
            </ol>
            <br />
            Scholarships for undergraduate and postgraduate degree programmes:
            <br />
            <br />
            <ol
              className="contentAboutUs"
              style={{ padding: "0px 0px 0px 20px" }}
            >
              <li>
                The student must register on the i-Learn portal and select the
                JSS Academy of Higher Education & Research and degree programme
                they wish to pursue.
              </li>
              <li>
                The JSS AHER application must be filled, and if the eligibility
                criteria defined by JSS AHER is met, the student will be
                accepted.
              </li>
              <li>
                The scholarship will be awarded to accepted students recommended
                by Indian Missions to the Government of India.
              </li>
              <li>
                The student may contact a Learning Centre in the participating
                country in Africa and/or Indian Mission in their country to get
                more information related to the scholarship.
              </li>
            </ol>
            <br />
            {/* PUT THE GIVEN e VBAB logo in the e VBAB section<br /><br /> */}
          </p>
        </div>
      </div>

      <div style={{ width: "100%", position: "absolute", bottom: "0" }}>
        <Footer />
      </div>
    </div>
  );
}
export default AboutOnlineLearning;
