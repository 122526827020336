import "./Formative.scss";
import Done from "../../../assets/svgjs/Done";
import Reject from "../../../assets/svgjs/Reject";
import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../../modules/Window/Window";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import { API, button, Auth } from "aws-amplify";

const Summative = (props) => {
  let {
    score,
    Button,
    imgUrl,
    getQuiz,
    produce,
    btnClick,
    sumScore,
    summative,
    showScore,
    curObject,
    answerNext,
    answerPrev,
    qisLoading,
    ansFcount,
    setAnsFcount,
    quizQuestion,
    setShowScore,
    setQIsLoading,
    currentQuestion,
    setQuizQuestion,
    setFeedbackButton,
    handleAnswerOptionClick,
    answerReveal,
    setAnswerReveal,
    setCurrentQuestion,
    setBtnClick,
    openAns,
    setOpenAns,
    setSumScore,
    setSummative,
  } = props;

  const summativeCount = useRef([]);
  const answerCount = useRef({});
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    console.log("Mounted Summative Quize");
    console.log("Mounted Summative", summative);
  }, []);

  async function getQuizNew(obj) {
    console.log(obj);
    setAnsFcount(0);
    // setAssignmentLoad(true);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        quizid: obj.oid,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_QUIZ,
        bodyParam
      );

      const { qitems, qtype } = response;
      // console.log("cutoff" + cutoff);
      //setQtype(qtype);
      setQuizQuestion(qitems);

      // if (response.cutoff == undefined) {
      //   let temp = qitems.length / 2;
      //   // let tempcutof =
      //   setCutOff(temp);
      // } else {
      //   setCutOff(response.cutoff);
      // }
      // setCutOff(response.cutoff);
      // alert(response.cutoff);

      // if (answerReveal === true) {
      //   setBtnClick(false);
      // } else {
      //   setBtnClick(true);
      // }
      setQIsLoading(false);
      //  setAssignmentLoad(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }
  return (
    <div
      className="body"
      style={{ 
        // height: btnClick || showScore ? height - 400 : "" 
        
      }}
    >
      {answerReveal ? (
        <>
          {qisLoading ? (
            <></>
          ) : (
            <div>
              <div className="scrollQuiz">
                <div className="question-section">
                  <div className="question-count">
                    <span>Question {currentQuestion + 1}</span>/
                    {quizQuestion.length}
                  </div>
                  <div
                    className="question-text"
                    dangerouslySetInnerHTML={{
                      __html: quizQuestion[currentQuestion].istem,
                    }}
                  ></div>

                  {quizQuestion[currentQuestion].imageurl ? (
                    <div className="img_container">
                      <img
                        src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                        alt="item"
                      />
                    </div>
                  ) : null}
                </div>

                <div className="answer-section">
                  {quizQuestion[currentQuestion].iopts.map(
                    (answerOption, idx) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        {/* {formativeAns[currentQuestion] !== idx ? (
                              <button className={`buttonQuizAR`}>
                                  {answerOption.content}
                              </button>
                              ) : (
                                  <button
                                      className={`buttonQuizAR`}
                                      style={{
                                      backgroundColor: "lightgray",
                                      border: "2px solid #234668",
                                      }}>
                                      {answerOption.content}
                                  </button>
                              )} */}
                        {answerOption.Myselection != "true" ? (
                          <button className={`buttonQuizAR`}>
                            {answerOption.content}
                          </button>
                        ) : (
                          <button
                            className={`buttonQuizAR`}
                            style={{
                              backgroundColor: "lightgray",
                              border: "2px solid #234668",
                            }}
                          >
                            {answerOption.content}
                          </button>
                        )}
                        {answerOption.correct == true ||
                        answerOption.correct == "true" ? (
                          <Done
                            className="right_size"
                            cls1="cls1_D"
                            cls2="cls2_D"
                          />
                        ) : (
                          <Reject
                            className="Wrong_size"
                            cls1="cls1_D"
                            cls2="cls2_D"
                          />
                        )}
                        {answerOption.Myselection != "true" ? null : (
                          <p
                            style={{
                              fontSize: "14px",
                              paddingLeft: "8px",
                              paddingTop: "8px",
                            }}
                          >
                            Your Response
                          </p>
                        )}
                      </div>
                    )
                  )}
                  <br />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {currentQuestion > 0 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="btn-size"
                      onClick={() => answerPrev()}
                      style={{ fontSize: "12px" }}
                    >
                      Prev
                    </Button>
                  ) : null}
                  {currentQuestion + 1 === quizQuestion.length ? (
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="btn-siz"
                        onClick={() => {
                          answerNext();
                          setAnswerReveal(false);
                          setSummative(true);
                        }}
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="btn-siz"
                      onClick={() => {
                        answerNext();
                      }}
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
                <br />
                <br />
                <br />


                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  className="btn-sizeClose"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    width: "100px",
                   // marginLeft: "45%",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    //handleClose();
                    setCurrentQuestion(0);
                    // setShowScore(true);
                    // setAnswerReveal(false);
                    setAnswerReveal(false);
                    setSummative(true);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      {btnClick ? (
        <div className="app1">
          {showScore ? (
            <div className="score-section">
              <div>
                You scored {score} out of {quizQuestion.length}
              </div>
            </div>
          ) : (
            <div className="scrollQuiz">
              <div className="question-section">
                <div className="question-count">
                  <span>Question{currentQuestion + 1}</span>/
                  {quizQuestion.length}
                </div>
                <div
                  className="question-text"
                  dangerouslySetInnerHTML={{
                    __html: quizQuestion[currentQuestion].istem,
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `${
                    quizQuestion[currentQuestion].imageurl ? "4fr" : "1fr"
                  } `,
                  gridGap: "2rem",
                }}
              >
                <center>
                  <div style={{ width: "270px" }}>
                    {quizQuestion[currentQuestion].imageurl ? (
                      <div className="img_container">
                        <img
                          src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                          alt="item"
                        />
                      </div>
                    ) : null}
                  </div>
                </center>
                <div>
                  {quizQuestion[currentQuestion].iopts.map(
                    (answerOption, idx) => (
                      <button
                        key={"a" + idx}
                        // className="buttonQuiz"
                        className={`buttonQuiz`}
                        data-id={`${
                          answerOption.correct == true
                            ? answerOption.correct
                            : null
                        }`}
                        style={{
                          borderColor:
                            answerOption.Myselection == "true" ? "#0f80a4" : "",
                        }}
                        onClick={() => {
                          if (
                            quizQuestion[currentQuestion].Qsubmit == undefined
                          ) {
                            setAnsFcount(ansFcount + 1);
                          }
                          setQuizQuestion((currentQues) =>
                            produce(currentQues, (v) => {
                              v[currentQuestion].Qsubmit = "true";
                              v[currentQuestion].iopts.forEach((opt) => {
                                opt.Myselection = "false";
                              });
                              v[currentQuestion].iopts[idx].Myselection =
                                "true";
                            })
                          );
                          if (
                            !summativeCount.current.includes(currentQuestion)
                          ) {
                            summativeCount.current.push(currentQuestion);
                          }
                          if (
                            answerOption.correct == true ||
                            answerOption.correct == "true"
                          ) {
                            answerCount.current[`a${currentQuestion}`] = 1;
                          } else {
                            answerCount.current[`a${currentQuestion}`] = 0;
                          }
                          let scoreTemp = 0;
                          for (const key in answerCount.current) {
                            if (
                              Object.hasOwnProperty.call(
                                answerCount.current,
                                key
                              )
                            ) {
                              const element = answerCount.current[key];
                              scoreTemp = scoreTemp + element;
                            }
                          }
                          console.log("scoreTemp " + scoreTemp);
                          handleAnswerOptionClick(
                            answerOption,
                            idx,
                            summativeCount.current.length,
                            scoreTemp
                          );
                          console.log("ans", answerOption, idx);
                        }}
                      >
                        {answerOption.content}
                      </button>
                    )
                  )}
                  <div style={{ padding: "3%" }}>
                    {currentQuestion > 0 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="btn-size"
                        onClick={() => answerPrev()}
                        style={{ fontSize: "12px" }}
                      >
                        Prev
                      </Button>
                    ) : null}
                    {currentQuestion + 1 === quizQuestion.length ? (
                      <div></div>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="btn-siz"
                        onClick={() => answerNext()}
                        style={{ float: "right", fontSize: "12px" }}
                      >
                        Next
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div style={{ fontSize: "14px" }}>
          {qisLoading ? (
            <CircularProgress color="default" size={30} />
          ) : (
            <div>
              {summative ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <h1
                    style={{
                      color: "#000",
                      textAlign: "center",
                      margin: "2.5rem 0",
                      boxShadow: "0 0 0 5px #e35f14",
                      borderRadius: "100%",
                      height: "120px",
                      width: "120px",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {sumScore > 100 || sumScore > "100" ? 100 : sumScore} %
                    </span>
                  </h1>
                  <div>
                    <button
                      className="btnQuizLD"
                      style={{
                        width: "200px",
                        border: "3px solid white",
                        fontSize: "15px",
                      }}
                      onClick={() => {
                        setBtnClick(false);
                        setQIsLoading(true);
                        getQuizNew(curObject);
                        //setOpenAns(true);
                        setAnswerReveal(true);
                        setSummative(false);
                      }}
                    >
                      Show Answers
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  {!answerReveal ? (
                    <button
                      className="btnQuizLD"
                      onClick={() => {
                        getQuiz(curObject);
                        setShowScore(false);
                        setQIsLoading(true);
                        setFeedbackButton({
                          disableSubmit: true,
                          hideNext: true,
                          hideSubmit: false,
                          submitted: false,
                        });
                      }}
                    >
                      Start Quiz
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Summative;
