import React, { useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import { FaBars } from "react-icons/fa";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import UserHeader from "../Header/UserHeader/UserHeader";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import axios from "axios";
import ApplicationStyle from "./Application.module.scss";
import CircularProgress from '@material-ui/core/CircularProgress';
import edLogo from "../../assets/images/profilep.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
    marginLeft: "20%",
  },
}));

export default function Application({ handleToggleSidebar }) {
  const [truee, settruee] = useState(false);
  const [spin, setSpin] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [midleName, setMidleName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState({ ccode: 0, telephone: 0 });
  const [gender, setGender] = useState("Male");
  const [dob, setDob] = useState("");
  const [parents, setParents] = useState({ fathername: "", mothername: "" });
  const [internationalStu, setInternationalStu] = useState("Yes");
  const [passport, setPassport] = useState(0);
  const [otherGovt, setOtherGovt] = useState(0);
  const [detailsid, setDetailsid] = useState("");
  const [perAddr, setPerAddr] = useState({
    addr1: "",
    addr2: "",
    addr3: "",
    state: "",
    city: "",
    pin: "",
  });
  const [presAddr, setPresAddr] = useState({
    addr1: "",
    addr2: "",
    addr3: "",
    state: "",
    city: "",
    pin: "",
  });
  const [sameAddr, setSameAddr] = useState(false);
  const [pastNationality, setPastNationality] = useState("");
  const [h, H] = useState(false);
  const [uploadDisable, setUploadDisable] = useState(false);
  const [scholarshipDisable, setScholarshipDisable] = useState(false);
  const [reviewDisable, setReviewDisable] = useState(false);
  const [issueText, setIssueText] = useState("");
  const [issueTextEducation, setIssueTextEducation] = useState("");
  const [issueTextUpload, setIssueTextUpload] = useState("");

  //Issuetexts

  const [nameIsseText, setNameIssueText] = useState("");
  const [emailIsseText, setEmailIssueText] = useState("");
  const [phoneIsseText, setPhoneIssueText] = useState("");
  const [dobIsseText, setDOBIssueText] = useState("");
  const [parentsIsseText, setParentsIssueText] = useState("");
  const [govtIsseText, setGovtIssueText] = useState("");
  const [addressIsseText, setAddressIssueText] = useState("");

  //Education variables declaration

  const [schoolName, setSchoolName] = useState("");
  const [schAddr, setSchAddr] = useState({
    addr1: "",
    addr2: "",
    addr3: "",
    country: "",
    zip: "",
  });
  const [university, setUniversity] = useState("");
  const [uniAddr, setUniAddr] = useState({
    addr1: "",
    addr2: "",
    addr3: "",
    country: "",
    zip: "",
  });
  const [edType, setEdType] = useState("Graduation");
  const [specialization, setSpecialization] = useState("Science");
  const [experiance, setExperiance] = useState("");
  const [experianceDesc, setExperianceDesc] = useState("");

  //Scholarship

  const [scholarship, setScholarship] = useState("");
  const [learningCenter, setLearningCenter] = useState("");

  //files

  const [certificate, setCertificate] = useState(null);
  const [transcripts, setTranscripts] = useState(null);
  const [passportFile, setPassportFile] = useState(null);
  const [otherGovtFile, setOtherGovtFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const [certificateN, setCertificateN] = useState("");
  const [transcriptsN, setTranscriptsN] = useState("");
  const [passportFileN, setPassportFileN] = useState("");
  const [otherGovtFileN, setOtherGovtFileN] = useState("");
  const [profilePhotoN, setProfilePhotoN] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);

  // console.log("get", localStorage.getItem("eVBAB"))
  const classes = useStyles();
  let infoObj = {};

  useEffect(() => {

    if (userDetails.pstatus != 0 && userDetails.pstatus != 1 && userDetails.pstatus != 5) {
      navigate("/applyProgram");
    }

    getUserApplication();

    if (userDetails.apply == true) {

      if (userDetails.evbab == true) {
        setScholarship("Yes");
        setLearningCenter(userDetails.evbabdata?.learning_center?.trim());
        setFirstName(userDetails.evbabdata?.first_name);
        setLastName(userDetails.evbabdata?.last_name);
        setEmail(userDetails.evbabdata?.email);
        let objtype = "scholarship";
        let action = 0;
        let pstatus = 1;
        let obj = {
          scholarship: "Yes",
          learningCenter: userDetails.evbabdata?.learning_center?.trim(),
          learningCentername: userDetails.evbabdata?.learning_center_name?.trim(),
        };
        console.log(JSON.stringify(userDetails.evbabdata));
        updateUserApplication(obj, objtype, action, pstatus, () => { }, 0);

        // setScholarshipDisable(true);
      }
    } else {
      setEmail(userDetails?.email);
    }
  }, []);

  async function getUserApplication() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: userDetails.pid,
        bpid: userDetails.bpid,
        action: 1,
        appid: userDetails.applicationid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PROGRAM,
        Constants.UPDATE_USER_APPLICATION,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      console.log("response " + JSON.stringify(response));
      updateStates(response);
      setIsLoading(false);
      setSpin(false);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  function updateStates(R) {
    if (R.generalinfo != undefined) {
      setFirstName(R.generalinfo["First Name"]);
      setMidleName(R.generalinfo["Middle Name"])
      setLastName(R.generalinfo["Last Name"]);
      setEmail(R.generalinfo["Email"]);
      setPhoneNo({
        ccode: R.generalinfo["Country Code"],
        telephone: R.generalinfo["Contact"],
      });
      setGender(R.generalinfo["Gender"]);
      setDob(R.generalinfo["Date of Birth"]);
      setParents({
        fathername: R.generalinfo["Father Name"],
        mothername: R.generalinfo["Mother Name"],
      });
      setInternationalStu(R.generalinfo["International Student"]);
      setPassport(R.generalinfo["Passport"]);
      setOtherGovt(R.generalinfo["Other Id"]);
      setPerAddr({
        addr1: R.generalinfo["Permanent Address 1"],
        addr2: R.generalinfo["Permanent Address 2"],
        addr3: R.generalinfo["Permanent Address 3"],
        state: R.generalinfo["Permanent State"],
        city: R.generalinfo["Permanent City"],
        pin: R.generalinfo["Permanent Pin"],
      });
      setPastNationality(R.generalinfo["Permanent Nation"]);
      setSameAddr(R.generalinfo["Permanent Same Address"]);
      setPresAddr({
        addr1: R.generalinfo["Present Address 1"],
        addr2: R.generalinfo["Present Address 2"],
        addr3: R.generalinfo["Present Address 3"],
        state: R.generalinfo["Present State"],
        city: R.generalinfo["Present City"],
        pin: R.generalinfo["Present Pin"],
      });

      H(true);
      setActiveIndex(1);
    }

    if (R.education != undefined) {
      setSchoolName(R.education["School Name"]);
      setSchAddr({
        addr1: R.education["School Address 1"],
        addr2: R.education["School Address 2"],
        addr3: R.education["School Address 3"],
        country: R.education["School Address Country"],
        zip: R.education["School Address pincode"],
      });
      setUniversity(R.education["University"]);
      setUniAddr({
        addr1: R.education["University Address 1"],
        addr2: R.education["University Address 2"],
        addr3: R.education["University Address 3"],
        country: R.education["University Country"],
        zip: R.education["University ZIP"],
      });
      setEdType(R.education["Education Type"]);
      setSpecialization(R.education["Specialization"]);
      setExperiance(R.education["Experience"]);
      setExperianceDesc(R.education["Experience Designation"]);

      setUploadDisable(true);
      setActiveIndex(2);
    }

    if (R.uploaddoc != undefined) {
      setCertificateN(R.uploaddoc["Cetificatesurl"]);
      setTranscriptsN(R.uploaddoc["Transcriptsurl"]);
      setPassportFileN(R.uploaddoc["Passporturl"]);
      setOtherGovtFileN(R.uploaddoc["Other Government Proofurl"]);
      setProfilePhotoN(R.uploaddoc["Profile Photourl"]);
      // if (R.scholarship != undefined) {
      //   setScholarship(R.scholarship["Scholarship"]);
      //   setLearningCenter(R.scholarship["Learning Center"]);
      //   setScholarshipDisable(true);
      //   setReviewDisable(true);
      //   setActiveIndex(3);
      // } else {
      setReviewDisable(true);
      setActiveIndex(4);
      // }

    }

    // if (R.scholarship != undefined) {
    //   setScholarship(R.scholarship["Scholarship"]);
    //   setLearningCenter(R.scholarship["Learning Center"]);
    //   setScholarshipDisable(true);
    //   setReviewDisable(true);
    //   setActiveIndex(4);
    // }
  }

  async function updateUserApplication(obj, objtype, action, pstatus, H, ind) {
    setSpin(true);
    let applicationid;
    if (userDetails.applicationid != undefined) {
      applicationid = userDetails.applicationid;
    } else {
      applicationid =
        userDetails.bpid + "-" + Math.round(new Date().getTime() / 1000);

      let sdata = { ...userDetails };
      sdata.applicationid = applicationid;
      dispatch(awsSignIn(sdata));
    }

    setIsLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        emailid: userDetails.email,
        action: action,
        pid: userDetails.pid,
        bpid: userDetails.bpid,
        bname: userDetails.bname,
        pname: userDetails.pname,
        psname: userDetails.psname,
        typeobj: objtype,
        appobj: obj,
        appid: applicationid,
        pstatus: pstatus,
        uname: (firstName + midleName + lastName),
        evbab: userDetails.evbab,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log("response " + JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PROGRAM,
        Constants.UPDATE_USER_APPLICATION,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      console.log("response " + JSON.stringify(response));
      H(true);
      setIsLoading(false);

      if (action == 2 || pstatus == 2) {
        let sdata = { ...userDetails };
        sdata.pstatus = pstatus;
        dispatch(awsSignIn(sdata));
        navigate("/applyProgram");
      } else {
        setActiveIndex(ind);
      }
      setSpin(false);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  function generalInfoValidation() {
    
    if (firstName === "") {
      setIssueText("Name cannot be empty");
      return;
    } else if (email === "") {
      setIssueText("Email cannot be empty");
      return;
    } else if (phoneNo.ccode === "" || phoneNo.telephone === "" || phoneNo.telephone === 0 || phoneNo.telephone === "0") {
      setIssueText("Phone number cannot be empty");
      return;
    } else if (dob === "") {
      setIssueText("Date of Birth cannot be empty");
      return;
    } else if (parents.fathername === "" || parents.mothername === "") {
      setIssueText("Father name or Mother name cannot be empty");
      return;
    } else if (otherGovt === "" || otherGovt == 0 || otherGovt == null) {
      setIssueText("Please enter valid national ID");
      return;
    }
    // else if (otherGovt === "") {
    //   setIssueText("Govt ID  cannot be empty");
    //   return;
    // } 
    else if (
      perAddr.addr1 === "" ||
      perAddr.state === "" ||
      perAddr.city === ""
    ) {
      setIssueText("Please fill Permanent address according to the requirement");
      return;
    } else if (
      perAddr.pin === ""
    ) {
      setIssueText("Please enter Permanent address Pincode");
      return;
    }
    else if (pastNationality == "" || pastNationality == undefined || pastNationality == "NotApplicable") {
      setIssueText("Select Country of residence");
      return;
    } else if (
      presAddr.addr1 === "" ||
      presAddr.state === "" ||
      presAddr.city === ""
    ) {
      setIssueText("Please fill present address according to the requirement");
      return;
    } else if (
      presAddr.pin === ""
    ) {
      setIssueText("Please enter present address Pincode");
      return;
    }
    else {
      setIssueText("");
      infoObj.name = firstName;
      infoObj.mname = midleName;
      infoObj.lname = lastName;
      infoObj.email = email;
      infoObj.phoneNo = phoneNo.telephone;
      infoObj.gender = gender;
      infoObj.dob = dob;
      infoObj.fathername = parents.fathername;
      infoObj.mothername = parents.mothername;
      infoObj.internation = internationalStu;
      infoObj.permanentAddr1 = perAddr.addr1;
      infoObj.permanentAddr2 = perAddr.addr2;
      infoObj.permanentAddr3 = perAddr.addr3;
      infoObj.permanentCity = perAddr.city;
      infoObj.permanentState = perAddr.state;
      infoObj.permanentPin = perAddr.pin;
      infoObj.pastNationality = pastNationality;

      saveGeneralInfo();
    }
  }

  function saveGeneralInfo() {
    // let sdata = {...userDetails};
    // if(sdata.applicationid == undefined){
    //   sdata.applicationid = sdata.bpid+"-"+Math.round((new Date()).getTime() / 1000);
    // }
    // dispatch(awsSignIn(sdata));

    let objtype = "generalinfo";
    let action = 0;
    let pstatus = 1;
    let obj = {
      "First Name": firstName,
      "Middle Name": midleName,
      "Last Name": lastName,
      "Email": email,
      "Country Code": phoneNo.ccode,
      "Contact": phoneNo.telephone,
      "Gender": gender,
      "Date of Birth": dob,
      "Father Name": parents.fathername,
      "Mother Name": parents.mothername,
      "International Student": internationalStu,
      "Passport": passport,
      "Other Id": otherGovt,
      "Permanent Address 1": perAddr.addr1,
      "Permanent Address 2": perAddr.addr2,
      "Permanent Address 3": perAddr.addr3,
      "Permanent State": perAddr.state,
      "Permanent City": perAddr.city,
      "Permanent Pin": perAddr.pin,
      "Permanent Nation": pastNationality,
      "Permanent Same Address": sameAddr,
      "Present Address 1": presAddr.addr1,
      "Present Address 2": presAddr.addr2,
      "Present Address 3": presAddr.addr3,
      "Present State": presAddr.state,
      "Present City": presAddr.city,
      "Present Pin": presAddr.pin,
    };

    updateUserApplication(obj, objtype, action, pstatus, H, 1);
  }

  function EducationValidation() {
    if (schoolName === "") {
      setIssueTextEducation("School Name cannot be empty");
    } else if (schAddr.addr1 === "") {
      setIssueTextEducation("School address cannot be empty");
    } else if (schAddr.country === "") {
      setIssueTextEducation("School country cannot be empty");
    } else if (schAddr.zip === "") {
      setIssueTextEducation("School zip cannot be empty");
    } else if (university === "") {
      setIssueTextEducation("University Name cannot be empty");
    } else if (uniAddr.addr1 === "") {
      setIssueTextEducation("University address cannot be empty");
    } else if (uniAddr.country === "") {
      setIssueTextEducation("University country cannot be empty");
    }
    else if (uniAddr.zip === "") {
      setIssueTextEducation("Please enter the Pincode");
    } else if (experiance === "") {
      setIssueTextEducation("Experience cannot be empty");
    } else if (experianceDesc === "") {
      setIssueTextEducation("Work description cannot be empty");
    } else {
      setIssueTextEducation("");
      saveEducationInfo();
    }
  }

  function saveEducationInfo() {
    let objtype = "education";
    let action = 0;
    let pstatus = 1;
    let obj = {
      "School Name": schoolName,
      "School Address 1": schAddr.addr1,
      "School Address 2": schAddr.addr2,
      "School Address 3": schAddr.addr3,
      "School Address Country": schAddr.country,
      "School Address pincode": schAddr.zip,
      "University": university,
      "University Address 1": uniAddr.addr1,
      "University Address 2": uniAddr.addr2,
      "University Address 3": uniAddr.addr3,
      "University Country": uniAddr.country,
      "University ZIP": uniAddr.zip,
      "Education Type": edType,
      "Specialization": specialization,
      "Experience": experiance,
      "Experience Designation": experianceDesc,
    };

    updateUserApplication(obj, objtype, action, pstatus, setUploadDisable, 2);
  }

  function handleCertificateFile(e) {
    if (e.target.files != undefined) {
      let selectedFile = e.target.files[0];
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length) || selectedFile.name;
      console.log("hiiiiiiii" + extend)
      if (e.target.files[0].size < 50 * 1024) {
        setIssueTextUpload("file size is less than 50kb")
        return;
      }
      else if (e.target.files[0].size > 250 * 1024) {
        setIssueTextUpload("file size is greater than 250kb")
        return;
      } else if (extend == "JPG" || extend == "PNG" || extend == "JPEG" || extend == "jpg" || extend == "png" || extend == "jpeg") {
        console.log(e.target.files);
        console.log("-1h", e.target.files[0]);
        let file = e.target.files[0];
        setCertificate(file);

        uploadDocsToServer(file, setCertificateN);
      }
      else {
        setIssueTextUpload("Certificate Only images with .jpg, .jpeg, .png extension are allowed.")
        return;
      }
    }

  }
  function handleTranscripts(e) {
    if (e.target.files != undefined) {
      let selectedFile = e.target.files[0];
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length) || selectedFile.name;

      if (e.target.files[0].size < 50 * 1024) {
        setIssueTextUpload("file size is less than 50kb")
        return;
      }
      else if (e.target.files[0].size > 250 * 1024) {
        setIssueTextUpload("file size is greater than 250kb")
        return;
      }
      else if (extend == "JPG" || extend == "PNG" || extend == "JPEG" || extend == "jpg" || extend == "png" || extend == "jpeg") {

        console.log(e.target.files);
        console.log("-h", e.target.files[0]);
        let file = e.target.files[0];
        setTranscripts(file);

        uploadDocsToServer(file, setTranscriptsN);
      } else {
        setIssueTextUpload("Transcripts Only images with .jpg, .jpeg, .png extension are allowed.")
        return;
      }
    }

  }
  function handlePassport(e) {
    if (e.target.files != undefined) {
      let selectedFile = e.target.files[0];
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length) || selectedFile.name;

      if (e.target.files[0].size < 50 * 1024) {
        setIssueTextUpload("fle size is less than 50kb")
        return;
      }
      else if (e.target.files[0].size > 250 * 1024) {
        setIssueTextUpload("fle size is greater than 250kb")
        return;
      }
      else if (extend == "JPG" || extend == "PNG" || extend == "JPEG" || extend == "jpg" || extend == "png" || extend == "jpeg") {

        console.log(e.target.files);
        console.log("h", e.target.files[0]);
        let file = e.target.files[0];
        setPassportFile(file);

        uploadDocsToServer(file, setPassportFileN);
      } else {
        setIssueTextUpload("Passport Only images with .jpg, .jpeg, .png extension are allowed.")
        return;
      }
    }

  }
  function handleGovtFiles(e) {
    if (e.target.files != undefined) {
      let selectedFile = e.target.files[0];
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length) || selectedFile.name;

      if (e.target.files[0].size < 50 * 1024) {
        setIssueTextUpload("file size is less than 50kb")
        return;
      }
      else if (e.target.files[0].size > 250 * 1024) {
        setIssueTextUpload("file size is greater than 250kb")
        return;
      }
      else if (extend == "JPG" || extend == "PNG" || extend == "JPEG" || extend == "jpg" || extend == "png" || extend == "jpeg") {

        console.log(e.target.files);
        console.log("h2", e.target.files[0]);
        let file = e.target.files[0];
        setOtherGovtFile(file);

        uploadDocsToServer(file, setOtherGovtFileN);
      } else {
        setIssueTextUpload("National ID Only images with .jpg, .jpeg, .png extension are allowed.")
        return;
      }
    }

  }
  function handleProfilePhoto(e) {

    if (e.target.files != undefined) {
      console.log('ddd', e.target.files[0])
      let selectedFile = e.target.files[0];

      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length) || selectedFile.name;

      if (e.target.files[0].size < 50 * 1024) {
        setIssueTextUpload("file size is less than 50kb")
        return;
      }
      else if (e.target.files[0].size > 250 * 1024) {
        setIssueTextUpload("file size is greater than 250kb")
        return;
      }
      else if (extend == "JPG" || extend == "PNG" || extend == "JPEG" || extend == "jpg" || extend == "png" || extend == "jpeg") {

        let file = e.target.files[0];
        setProfilePhoto(file);
        uploadDocsToServer(file, setProfilePhotoN);
      } else {
        setIssueTextUpload("Profile photo Only images with .jpg, .jpeg, .png extension are allowed.")
        return;
      }
    }

  }

  async function uploadDocsToServer(file, setName) {
    setSpin(true);
    var val = Math.floor(1000 + Math.random() * 9000);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        type: "application",
        filetype: file.type,
        filename: val + "-" + file.name,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      console.log("response " + JSON.stringify(response));
      fileUpload(file, response, setName, val);
      // setSpin(false);
    } catch (error) {
      setSpin(false);
      console.log("getCategoryError", error);
    }
  }

  async function fileUpload(file, url, setName, val) {
    // setSpin(true);
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
        console.log(res);
        setName(val + "-" + file.name);
        setSpin(false);
      })
      .catch((err) => {
        console.error(err);
        setSpin(false);
      });
  }

  function docValidation() {
    if (certificateN == "") {
      setIssueTextUpload("Please upload Certificate");
    } else if (transcriptsN == "") {
      setIssueTextUpload("Please upload Transcripts");
    } else if (otherGovtFileN == "") {
      setIssueTextUpload("Please upload National ID Document");
    }
    // else if (otherGovtFile == "") {
    //   setIssueTextUpload("Please upload Other Govt Files");
    // } 
    else if (profilePhotoN == "") {
      setIssueTextUpload("Please upload Profile photo");
    } else {
      setIssueTextUpload("");
      saveDocuments();
    }
  }

  function saveDocuments() {
    let objtype = "uploaddoc";
    let action = 0;
    let pstatus = 1;
    let obj = {
      "Cetificates": "",
      "Transcripts": "",
      "Passport": "",
      "Other Government Proof": "",
      "Profile Photo": "",
    };

    if (certificate != null) {
      obj["Cetificates"] = certificateN;
    } else {
      obj["Cetificates"] = certificateN;
    }
    if (transcripts != null) {
      obj["Transcripts"] = transcriptsN;
    } else {
      obj["Transcripts"] = transcriptsN;
    }
    if (passportFile != null) {
      obj["Passport"] = passportFileN;
    } else {
      obj["Passport"] = passportFileN;
    }
    if (otherGovtFile != null) {
      obj["Other Government Proof"] = otherGovtFileN;
    } else {
      obj["Other Government Proof"] = otherGovtFileN;
    }
    if (profilePhoto != null) {
      obj["Profile Photo"] = profilePhotoN;
    } else {
      obj["Profile Photo"] = profilePhotoN;
    }

    // if (userDetails.evbab == true) {
    //   updateUserApplication(
    //     obj,
    //     objtype,
    //     action,
    //     pstatus,
    //     setScholarshipDisable,
    //     3
    //   );
    // } else {
    updateUserApplication(obj, objtype, action, pstatus, setReviewDisable, 4);
    // }
  }

  function saveScholarship() {
    let objtype = "scholarship";
    let action = 0;
    let pstatus = 1;
    let obj = {
      "Scholarship": "",
      "Learning Center": "",
    };

    updateUserApplication(obj, objtype, action, pstatus, setReviewDisable, 4);
  }

  function SubmitApplication() {
    let objtype = undefined;
    let action = 2;
    let pstatus = 2;
    let obj = undefined;

    updateUserApplication(obj, objtype, action, pstatus, setReviewDisable, 4);
  }

  const panes = [
    {
      menuItem: "General Information",
      render: () => <Tab.Pane>{GeneralF()}</Tab.Pane>,
    },
    {
      menuItem: "Education",
      render: () => <Tab.Pane disabled={h === false}>{EducationF()}</Tab.Pane>,
    },
    {
      menuItem: "Upload Documents",
      render: () => (
        <Tab.Pane disabled={uploadDisable === false}>{UploadF()}</Tab.Pane>
      ),
    },
    {
      menuItem: "Scholarship",
      render: () => (
        <Tab.Pane disabled={scholarshipDisable === false}>
          {ScholarshipF()}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Review",
      render: () => (
        <Tab.Pane disabled={reviewDisable === false}>{ReviewF()}</Tab.Pane>
      ),
    },
  ];

  function GeneralF() {
    return (
      <div>
        {console.log("name", userDetails)}
        <Grid item xs={10}>
          <Paper className={classes.paper}>
            <form>
              <div>
                <h3>General Info</h3>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span> First Name (As per
                  records):
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span></span>Middle Name (As per
                  records):
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={midleName}
                  onChange={(e) => setMidleName(e.target.value)}
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span></span>Last Name (As per
                  records):
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Email:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled
                />
                <br />
                <br />
              </div>

              <div style={{ display: "flex" }}>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Country code:
                </label>
                <br />
                {/* <input
                  style={{
                    fontSize: "medium",
                    width: "10%",
                    borderColor: "grey",
                    height: "30px",
                    paddingTop: "8px",
                    borderWidth: "thin",
                  }}
                  value={phoneNo.ccode}
                  onChange={(e) =>
                    setPhoneNo({ ...phoneNo, ccode: e.target.value })
                  }
                  required
                /> */}
                <select style={{ border: "none", width: "20%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} name="gender" id="gender" onChange={(e) =>
                  setPhoneNo({ ...phoneNo, ccode: e.target.value })
                }
                  value={phoneNo.ccode}>
                  <option value="">Select Country Code</option>
                  <option value="91">(+91) India</option>
                  <option value="229">(+229) Benin</option>
                  <option value="269">(+269) Comoros</option>
                  <option value="225">(+225) Cote d’lvoire</option>
                  <option value="243">(+243) Democratic Republic of Congo</option>
                  <option value="253">(+253) Djibouti</option>
                  <option value="291">(+291) Eritrea</option>
                  <option value="234">(+234) Federal Republic of Nigeria</option>
                  <option value="252">(+252) Federal Republic of Somalia</option>
                  <option value="233">(+233) Ghana</option>
                  <option value="261">(+261) Madagascar</option>
                  <option value="265">(+265) Malawi</option>
                  <option value="223">(+223) Mali</option>
                  <option value="230">(+230) Mauritius</option>
                  <option value="258">(+258) Mozambique</option>
                  <option value="224">(+224) Republic of Guinea</option>
                  <option value="248">(+248) Republic of Seychelles</option>
                  <option value="249">(+249) Republic of Sudan</option>
                  <option value="232">(+232) Sierra Leone</option>
                  <option value="211">(+211) South Sudan</option>
                  <option value="220">(+220) The Gambia</option>
                  <option value="256">(+256) Uganda</option>
                  <option value="260">(+260) Zambia</option>
                </select>
                <div style={{ height: '50px' }}>
                  <label
                    style={{ marginLeft: "20px" }}
                    className={ApplicationStyle.lableText}
                  >
                    <span style={{ color: "red" }}>*</span>Mobile No:
                  </label>
                  <input
                    type="number"
                    style={{
                      fontSize: "medium",
                      width: "45%",
                      borderColor: "grey",
                      height: "30px",
                      paddingTop: "8px",
                      borderWidth: "thin",
                    }}
                    value={phoneNo.telephone}
                    onChange={(e) =>
                      setPhoneNo({ ...phoneNo, telephone: e.target.value })
                    }
                    required
                  />
                  <div>
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>Note: Please enter the whatsapp number only.</span>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Gender:
                  <br />
                </label>
                <select
                  className={ApplicationStyle.textBox}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Date of Birth(As per
                  records):
                  <br />
                </label>
                <input
                  type="date"
                  className={ApplicationStyle.textBox}
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Father Name (As per
                  records):
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={parents.fathername}
                  onChange={(e) =>
                    setParents({ ...parents, fathername: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Mother Name (As per
                  records):
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={parents.mothername}
                  onChange={(e) =>
                    setParents({ ...parents, mothername: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Are you International
                  student?:
                  <br />
                </label>
                <select
                  className={ApplicationStyle.textBox}
                  onChange={(e) => setInternationalStu(e.target.value)}
                >
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </select>
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  Passport:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={passport}
                  onChange={(e) => setPassport(e.target.value)}
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>National ID:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={otherGovt}
                  onChange={(e) => setOtherGovt(e.target.value)}
                  required
                />
                <br />
              </div>
              <h2>Permanent Address</h2>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Address Line 1:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={perAddr.addr1}
                  onChange={(e) =>
                    setPerAddr({ ...perAddr, addr1: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Address Line 2:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={perAddr.addr2}
                  onChange={(e) =>
                    setPerAddr({ ...perAddr, addr2: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Address Line 3:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={perAddr.addr3}
                  onChange={(e) =>
                    setPerAddr({ ...perAddr, addr3: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>State:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={perAddr.state}
                  onChange={(e) =>
                    setPerAddr({ ...perAddr, state: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>City :<br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={perAddr.city}
                  onChange={(e) =>
                    setPerAddr({ ...perAddr, city: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>PIN:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={perAddr.pin}
                  onChange={(e) =>
                    setPerAddr({ ...perAddr, pin: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Country of residence:
                  <br />
                </label>
                <select
                  className={ApplicationStyle.textBox}
                  onChange={(e) => setPastNationality(e.target.value)}
                >
                  <option value="NotApplicable">Not Applicable</option>
                  <option value="Benin">Benin</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                  <option value="Democratic Republic of Congo">
                    Democratic Republic of Congo
                  </option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Mali">Mali</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mazambique">Mazambique</option>
                  <option value="Republic of Guinea">Republic of Guinea</option>
                  <option value="Republic of Sudan">Republic of Sudan</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="The Gambia">The Gambia</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Zambia">Zambia</option>
                  <option value="india">India</option>
                </select>
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Is the Present Address
                  same as Permanent Address? :<br />
                </label>
                <select
                  className={ApplicationStyle.textBox}
                  onChange={(e) => {
                    setSameAddr(e.target.value);
                    if (e.target.value == "true") {
                      setPresAddr({ ...perAddr });
                    } else if (e.target.value == "false") {
                      setPresAddr({
                        addr1: "",
                        addr2: "",
                        addr3: "",
                        state: "",
                        city: "",
                        pin: "",
                      });
                    }
                  }}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
                <br />
              </div>
              <h2>Present Address</h2>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Address Line 1:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={presAddr.addr1}
                  onChange={(e) =>
                    setPresAddr({ ...presAddr, addr1: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Address Line 2:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={presAddr.addr2}
                  onChange={(e) =>
                    setPresAddr({ ...presAddr, addr2: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>Address Line 3:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={presAddr.addr3}
                  onChange={(e) =>
                    setPresAddr({ ...presAddr, addr3: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>State:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={presAddr.state}
                  onChange={(e) =>
                    setPresAddr({ ...presAddr, state: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>City :<br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={presAddr.city}
                  onChange={(e) =>
                    setPresAddr({ ...presAddr, city: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              <div>
                <label className={ApplicationStyle.lableText}>
                  <span style={{ color: "red" }}>*</span>PIN:
                  <br />
                </label>
                <input
                  className={ApplicationStyle.textBox}
                  value={presAddr.pin}
                  onChange={(e) =>
                    setPresAddr({ ...presAddr, pin: e.target.value })
                  }
                  required
                />
                <br />
              </div>
              {/* <input
                type="button"
                onClick={() =>
                  console.log(
                    "first",
                    firstName,
                    "l",
                    lastName,
                    parents,
                    perAddr
                  )
                }
                value="Next"
                className={ApplicationStyle.nextButton}
                style={{ float: "right" }}
              /> */}
              <input
                type="button"
                onClick={() => generalInfoValidation()}
                value="Save"
                className={ApplicationStyle.saveButton}
                style={{ float: "right" }}
              />
              <span className={ApplicationStyle.issueText}>{issueText}</span>
            </form>
          </Paper>
        </Grid>
      </div>
    );
  }

  function EducationF() {
    return (
      <Grid item xs={10}>
        <Paper className={classes.paper} disabled>
          <form>
            <div>
              <h3>Education</h3>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span> Name of School Attended
                :<br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={schoolName}
                onChange={(e) => setSchoolName(e.target.value)}
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Address of School Line 1:
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={schAddr.addr1}
                onChange={(e) =>
                  setSchAddr({ ...schAddr, addr1: e.target.value })
                }
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                Address of School Line 2:
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={schAddr.addr2}
                onChange={(e) =>
                  setSchAddr({ ...schAddr, addr2: e.target.value })
                }
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                Address of School Line 3:
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={schAddr.addr3}
                onChange={(e) =>
                  setSchAddr({ ...schAddr, addr3: e.target.value })
                }
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Country of School:
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={schAddr.country}
                onChange={(e) =>
                  setSchAddr({ ...schAddr, country: e.target.value })
                }
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>School ZIP/PIN Code :
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={schAddr.zip}
                onChange={(e) =>
                  setSchAddr({ ...schAddr, zip: e.target.value })
                }
                required
              />
              <br />
            </div>

            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Name of University:
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={university}
                onChange={(e) => setUniversity(e.target.value)}
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Address of University
                Line 1 :<br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={uniAddr.addr1}
                onChange={(e) =>
                  setUniAddr({ ...uniAddr, addr1: e.target.value })
                }
                required
              />
              <br />
            </div>

            <div>
              <label className={ApplicationStyle.lableText}>
                Address of University Line 2:
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={uniAddr.addr2}
                onChange={(e) =>
                  setUniAddr({ ...uniAddr, addr2: e.target.value })
                }
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                Address of University Line 3:
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={uniAddr.addr3}
                onChange={(e) =>
                  setUniAddr({ ...uniAddr, addr3: e.target.value })
                }
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Country of University:
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={uniAddr.country}
                onChange={(e) =>
                  setUniAddr({ ...uniAddr, country: e.target.value })
                }
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>ZIP/PIN Code:
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={uniAddr.zip}
                onChange={(e) =>
                  setUniAddr({ ...uniAddr, zip: e.target.value })
                }
                required
              />
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Type of Education :<br />
              </label>
              <select
                className={ApplicationStyle.textBox}
                onChange={(e) => {
                  setEdType(e.target.value);
                }}
                disabled={h === false}
              >
                <option value="Graduation">Graduation</option>
                <option value="Post Graduation">Post Graduation</option>
                <option value="Others">Others</option>
              </select>
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Specialization:
                <br />
              </label>
              <select
                className={ApplicationStyle.textBox}
                onChange={(e) => setSpecialization(e.target.value)}
                disabled={h === false}
              >
                <option value="Science">Science</option>
                <option value="Arts">Arts</option>
                <option value="Commerce">Commerce</option>
                <option value="Medicine">Medicine</option>
                <option value="Dentistry">Dentistry</option>
                <option value="Pharma">Pharma</option>
                <option value="Management">Management</option>
                <option value="Others">Others</option>
              </select>
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Experience : (If You Have
                No Work Experience, Type NIL):
                <br />
              </label>
              <input
                disabled={h === false}
                className={ApplicationStyle.textBox}
                value={experiance}
                onChange={(e) => setExperiance(e.target.value)}
                required
              />
              <br />
            </div>

            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Work Experience :
                (Description) :<br />
              </label>
              <input
                disabled={h === false}
                rows="4"
                className={ApplicationStyle.textBox}
                value={experianceDesc}
                onChange={(e) => setExperianceDesc(e.target.value)}
                required
              />
              <br />
            </div>
            <span className={ApplicationStyle.issueText}>
              {issueTextEducation}
            </span>
            {/* <input
              type="button"
              disabled={h === false}
              onClick={() =>
                console.log("first", firstName, "l", lastName, parents, perAddr)
              }
              value="Next"
              style={{ float: "right" }}
              className={ApplicationStyle.nextButton}
            /> */}
            <input
              type="button"
              disabled={h === false}
              onClick={() => EducationValidation()}
              value="Save"
              style={{ float: "right" }}
              className={ApplicationStyle.saveButton}
            />
          </form>
        </Paper>
      </Grid>
    );
  }

  function UploadF() {
    return (
      <Grid item xs={10} disabled={uploadDisable === false}>
        <Paper className={classes.paper} disabled>
          <form>
            <div>
              <h3>Uplaod files</h3>
              <br />
              <span className={ApplicationStyle.issueText}>
                {issueTextUpload}
              </span>
              <br />
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Highest Degree
                Certificate <small>(Scanned Copy)</small> <br />
              </label>
              <br />
              <input
                disabled={uploadDisable === false}
                style={{ width: "75px" }}
                //className={ApplicationStyle.textBox}
                type="file" accept=".jpg,.jpeg,.png"
                name="file"
                className={ApplicationStyle.browse}
                onChange={(e) => handleCertificateFile(e)}
                required
              />
              <span style={{ color: "green" }}>{certificateN}</span>
              <br />
              <br />
              <br />
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Transcripts: <br />
              </label>
              <br />
              <input
                disabled={uploadDisable === false}
                style={{ width: "75px" }}
                //className={ApplicationStyle.textBox}
                type="file" accept=".jpg,.jpeg,.png"
                name="file"
                className={ApplicationStyle.browse}
                onChange={(e) => handleTranscripts(e)}
                required
              />
              <span style={{ color: "green" }}>{transcriptsN}</span>
              <br />
              <br />
              <br />
              <label className={ApplicationStyle.lableText}>
                Passport{" "}
                <small>(Scanned Copy):</small> <br />
              </label>
              <br />
              <input
                disabled={uploadDisable === false}
                style={{ width: "75px" }}
                //className={ApplicationStyle.textBox}
                type="file" accept=".jpg,.jpeg,.png"
                name="file"
                className={ApplicationStyle.browse}
                onChange={(e) => handlePassport(e)}
                required
              />
              <span style={{ color: "green" }}>{passportFileN}</span>
              <br />
              <br />
              <br />
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>National ID
                <small>(Scanned Copy):</small> <br />
              </label>
              <br />
              <input
                disabled={uploadDisable === false}
                style={{ width: "75px" }}
                //className={ApplicationStyle.textBox}
                type="file" accept=".jpg,.jpeg,.png"
                name="file"
                className={ApplicationStyle.browse}
                onChange={(e) => handleGovtFiles(e)}
                required
              />
              <span style={{ color: "green" }}>{otherGovtFileN}</span>
              <br />
              <br />
              <br />
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Profile Photo:
                <br />
              </label>
              <br />
              <input
                disabled={uploadDisable === false}
                style={{ width: "75px" }}
                //className={ApplicationStyle.textBox}
                type="file" accept=".jpg,.jpeg,.png"
                name="file"
                className={ApplicationStyle.browse}
                onChange={(e) => handleProfilePhoto(e)}
                required
              />
              <span style={{ color: "green" }}>{profilePhotoN}</span>
              <br />
              <br />

              <span style={{ marginLeft: '10%' }}> <span style={{ color: "red" }}>*</span>Please refer to the below Picture to upload the Profile Photo.</span>

              <div style={{ height: '100px', marginLeft: '10%', display: "flex" }}>
                <img src={edLogo} alt="" />
                <span style={{ marginLeft: '2%', marginTop: "2%" }}><span style={{ color: "red" }}>*</span>Only images with .jpg, .jpeg, .png extension are allowed. <br></br><span style={{ color: "red" }}>*</span>Profile and id proof Image size should be uploaded between 50 KB to 250 KB.<br></br><span style={{ color: "red" }}>*</span>Profile photo should be taken in the white background.You should be standing and looking straight.<br></br><span style={{ color: "red" }}>*</span>Same photo will be used for Convocation. Check the sample image.</span>
              </div>
              {/* <input
                type="button"
                disabled={uploadDisable === false}
                value="Next"
                style={{ float: "right" }}
                className={ApplicationStyle.nextButton}
              /> */}
              <input
                type="button"
                disabled={uploadDisable === false}
                onClick={docValidation}
                value="Save"
                style={{ float: "right", marginBottom: "40px" }}
                className={ApplicationStyle.saveButton}

              />

            </div>
            <br />
          </form>
        </Paper>
      </Grid>
    );
  }

  function ScholarshipF() {
    return (
      <Grid item xs={10}>
        <Paper className={classes.paper}>
          <form>
            <div>
              <h3>Scholarship</h3>
            </div>

            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Do you want to apply for
                Scholarship? :<br />
              </label>
              <select
                className={ApplicationStyle.textBox}
                onChange={(e) => setScholarship(e.target.value)}
                disabled={scholarshipDisable === false}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <br />
            </div>
            <div>
              <label className={ApplicationStyle.lableText}>
                <span style={{ color: "red" }}>*</span>Learning Center:
                <br />
              </label>
              <select
                className={ApplicationStyle.textBox}
                onChange={(e) => setLearningCenter(e.target.value)}
                disabled={scholarshipDisable === false}
              >
                <option value="KWAME NKRUMAH UNIVERSITY OF SCIENCE & TECHNOLOGY">
                  KWAME NKRUMAH UNIVERSITY OF SCIENCE & TECHNOLOGY
                </option>
                <option value="Universitéd'Abomey-Calavi (UAC), 01 B.P. 526 Cotonou Bénin">
                  Universitéd'Abomey-Calavi (UAC), 01 B.P. 526 Cotonou Bénin
                </option>
                <option value="Has to be updated in iLearn">
                  Has to be updated in iLearn
                </option>
                <option value="University de Man,BP 20 Man, Kassiapleu, Côte d'Ivoire">
                  University de Man,BP 20 Man, Kassiapleu, Côte d'Ivoire
                </option>
                <option value="University of Kinshasa, Lemba Commune, Kinshasa, DRC BP 127, Kinshasa XI">
                  University of Kinshasa, Lemba Commune, Kinshasa, DRC BP 127,
                  Kinshasa XI
                </option>
                <option value="Orotta College of Medicine and Health Sciences, Mai Bela Avenue, Zoba Michael Street, PO Box No. 8566, Asmara, Eritrea">
                  Orotta College of Medicine and Health Sciences, Mai Bela
                  Avenue, Zoba Michael Street, PO Box No. 8566, Asmara, Eritrea
                </option>
                <option value="Kwame Nkrumah University of Science and Technology">
                  Kwame Nkrumah University of Science and Technology
                </option>
                <option value="National College of IT, PO Box 30133, Blantyre, Malawi">
                  National College of IT, PO Box 30133, Blantyre, Malawi
                </option>
                <option value="Mulungushi University, Kabwe Campus">
                  Mulungushi University, Kabwe Campus
                </option>
                <option value="National College of Information Technology (NACIT), Blantyre">
                  National College of Information Technology (NACIT), Blantyre
                </option>
                <option value="Open University of Mauritius, Réduit, Moka, Republic of Mauritius">
                  Open University of Mauritius, Réduit, Moka, Republic of
                  Mauritius
                </option>
                <option value="Eduardo Mondlane University (UEM), Computer Centre of Eduardo Mondlane University and Medicine Faculty, Av. Julius Nyerere, nr. 3453 Maputo, Moçambique">
                  Eduardo Mondlane University (UEM), Computer Centre of Eduardo
                  Mondlane University and Medicine Faculty, Av. Julius Nyerere,
                  nr. 3453 Maputo, Moçambique
                </option>
                <option value="L'Université Gamal Abdel Nasser de Conakry, Dixinn, Rue 14, 00224 Conakry, Guinea">
                  L'Université Gamal Abdel Nasser de Conakry, Dixinn, Rue 14,
                  00224 Conakry, Guinea
                </option>
                <option value="University of Seychelles (UniSey), P.O. Box 1348, Anse Royale, Mahe, Seychelles">
                  University of Seychelles (UniSey), P.O. Box 1348, Anse Royale,
                  Mahe, Seychelles
                </option>
                <option value="Africa City of Technology, Central Amlak East, Street 2/4A, Blue Nile Bridge, P. Box 13332, Bahri, Khartoum-11111, Sudan">
                  Africa City of Technology, Central Amlak East, Street 2/4A,
                  Blue Nile Bridge, P. Box 13332, Bahri, Khartoum-11111, Sudan
                </option>
                <option value="University of Sierra Leone, University House, Fourah Bay College, Mount Aureol, Freetown, Sierra Leone">
                  University of Sierra Leone, University House, Fourah Bay
                  College, Mount Aureol, Freetown, Sierra Leone
                </option>
                <option value="University of The Gambia, Serrekunda">
                  University of The Gambia, Serrekunda
                </option>
                <option value="College of Computing & Information Science (CoCIS), Makerere University Wandegeya,Makerere Hill, Kampala, Uganda">
                  College of Computing & Information Science (CoCIS), Makerere
                  University Wandegeya,Makerere Hill, Kampala, Uganda
                </option>
                <option value="Mulungushi University, P O Box 0415. Kabwe, Zambia">
                  Mulungushi University, P O Box 0415. Kabwe, Zambia
                </option>
              </select>
              <br />
            </div>

            <span className={ApplicationStyle.issueText}>""</span>
            {/* <input
              type="button"
              disabled={scholarshipDisable === false}
              value="Next"
              style={{ float: "right" }}
              className={ApplicationStyle.nextButton}
            /> */}
            <input
              type="button"
              onClick={saveScholarship}
              disabled={scholarshipDisable === false}
              value="Save"
              style={{ float: "right" }}
              className={ApplicationStyle.saveButton}
            />
          </form>
        </Paper>
      </Grid>
    );
  }

  function ReviewF() {
    return (
      <Grid item xs={10} disabled={reviewDisable === false}>
        <Paper className={classes.paper} disabled={reviewDisable === false}>
          <form>
            <h3>General Info</h3>
            <p className={ApplicationStyle.reviewText}>
              Name :{" "}
              <span
                style={{ marginLeft: "380px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {firstName}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Email :{" "}
              <span
                style={{ marginLeft: "385px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {email}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Phone No :{" "}
              <span
                style={{ marginLeft: "356px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {phoneNo.telephone}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Gender :{" "}
              <span
                style={{ marginLeft: "374px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {gender}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              DOB :{" "}
              <span
                style={{ marginLeft: "394px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {dob}
              </span>
            </p>
            <p className={ApplicationStyle.reviewText}>
              Father Name :{" "}
              <span
                style={{ marginLeft: "334px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {parents.fathername}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Mother Name :{" "}
              <span
                style={{ marginLeft: "328px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {parents.mothername}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Are you International Student :{" "}
              <span
                style={{ marginLeft: "328px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {internationalStu}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Passport :{" "}
              <span
                style={{ marginLeft: "362px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {passport}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Other GovtID :{" "}
              <span
                style={{ marginLeft: "330px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {otherGovt}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Permanent Address, Address Line 1 :{" "}
              <span
                style={{ marginLeft: "168px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {perAddr.addr1}{" "}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Permanent Address, Address Line 2 :{" "}
              <span
                style={{ marginLeft: "168px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {" "}
                {perAddr.addr2}{" "}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Permanent Address, Address Line 3 :{" "}
              <span
                style={{ marginLeft: "168px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {perAddr.addr3}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Permanent Address, City :{" "}
              <span
                style={{ marginLeft: "248px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {perAddr.city}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Permanent Address, State :{" "}
              <span
                style={{ marginLeft: "238px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {perAddr.state}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Permanent Address, Pin :{" "}
              <span
                style={{ marginLeft: "252px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {perAddr.pin}
              </span>
            </p>
            <br />
            <br />
            <br />
            <br />
            <h3>Education</h3>
            <p className={ApplicationStyle.reviewText}>
              Name of School Attended :{" "}
              <span
                style={{ marginLeft: "240px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {schoolName}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              School Address, Address Line 1 :{" "}
              <span
                style={{ marginLeft: "198px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {schAddr.addr1}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              School Address, Address Line 2 :{" "}
              <span
                style={{ marginLeft: "198px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {schAddr.addr2}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              School Address, Address Line 3 :{" "}
              <span
                style={{ marginLeft: "198px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {schAddr.addr3}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              School Address, Country :{" "}
              <span
                style={{ marginLeft: "248px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {schAddr.country}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              School Address, ZIP/PIN :{" "}
              <span
                style={{ marginLeft: "252px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {schAddr.zip}
              </span>
            </p>
            <br />

            <p className={ApplicationStyle.reviewText}>
              Name of University :{" "}
              <span
                style={{ marginLeft: "288px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {university}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Address of University, Address Line 1 :{" "}
              <span
                style={{ marginLeft: "158px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {uniAddr.addr1}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Address of University, Address Line 2 :{" "}
              <span
                style={{ marginLeft: "158px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {uniAddr.addr2}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Address of University, Address Line 3 :{" "}
              <span
                style={{ marginLeft: "158px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {uniAddr.addr3}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Address of University, Country :{" "}
              <span
                style={{ marginLeft: "208px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {uniAddr.country}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Address of University, ZIP/PIN :{" "}
              <span
                style={{ marginLeft: "210px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {uniAddr.zip}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Type of Education :{" "}
              <span
                style={{ marginLeft: "295px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {edType}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Specialization :{" "}
              <span
                style={{ marginLeft: "326px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {specialization}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Experience :{" "}
              <span
                style={{ marginLeft: "346px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {experiance}
              </span>
            </p>
            <br />

            <p className={ApplicationStyle.reviewText}>
              Work Experirnce :{" "}
              <span
                style={{ marginLeft: "308px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {experianceDesc}
              </span>
            </p>
            <br />
            <br />
            <h3>Uploaded Documents</h3>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Highest Degree Certificate :{" "}
              <span
                style={{ marginLeft: "226px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {/* certificateN === null ? "" : */ certificateN}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Transcripts :{" "}
              <span
                style={{ marginLeft: "338px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {/* transcriptsN === null ? "" : */ transcriptsN}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Passport :{" "}
              <span
                style={{ marginLeft: "353px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {passportFileN}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Other Govt File :{" "}
              <span
                style={{ marginLeft: "308px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {otherGovtFileN}
              </span>
            </p>
            <br />
            <p className={ApplicationStyle.reviewText}>
              Profile Photo :{" "}
              <span
                style={{ marginLeft: "324px" }}
                className={ApplicationStyle.reviewTextSpan}
              >
                {profilePhotoN}
              </span>
            </p>
            <br />
            {scholarshipDisable === false ? (
              ""
            ) : (
              <div>
                <h3>Scholarship</h3>
                <br />
                <p className={ApplicationStyle.reviewText}>
                  Do you want to apply for Scholarship? :{" "}
                  <span
                    style={{ marginLeft: "144px" }}
                    className={ApplicationStyle.reviewTextSpan}
                  >
                    {scholarship}
                  </span>
                </p>
                <br />
                <p className={ApplicationStyle.reviewText}>
                  Lerning Center :{" "}
                  <span
                    style={{ marginLeft: "310px" }}
                    className={ApplicationStyle.reviewTextSpan}
                  >
                    {learningCenter}
                  </span>
                </p>
                <br />
              </div>
            )}

            <input
              type="button"
              onClick={SubmitApplication}
              disabled={reviewDisable === false}
              value="Save"
              style={{ float: "right" }}
              className={ApplicationStyle.saveButton}
            />
          </form>
        </Paper>
      </Grid>
    );
  }

  let handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  return (
    <div style={{ height: "100%", width: "100%", overflowY: "auto", padding: "30px", margin: "0px" }}>

      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader />
      {spin === true ? <CircularProgress style={{ position: "fixed", top: "48%", left: "48%", background: "transparent", zIndex: "2" }} /> : null}
      <div style={{ zIndex: "-2" }}>
        <h1>Application</h1>
        <div className={ApplicationStyle.management}>
          <div className={ApplicationStyle.h1}>
            <h1>
              <strong></strong>
            </h1>
          </div>
          <div>
            <Tab
              panes={panes}
              activeIndex={activeIndex}
              onTabChange={handleTabChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
