import "./Formative.scss";
import Done from "../../../assets/svgjs/Done";
import Reject from "../../../assets/svgjs/Reject";
import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../../modules/Window/Window";
import CircularProgress from "@material-ui/core/CircularProgress";

const Formative = (props) => {
  let {
    imgUrl,
    score,
    cutOff,
    Button,
    getQuiz,
    btnClick,
    setScore,
    tryAgain,
    ansFcount,
    setAnsFcount,
    produce,
    showScore,
    curObject,
    answerPrev,
    answerNext,
    qisLoading,
    setBtnClick,
    setTryAgain,
    handleClose,
    markComplete,
    answerReveal,
    quizQuestion,
    setShowScore,
    formativeAns,
    setQIsLoading,
    setFormativeAns,
    setAnswerReveal,
    setQuizQuestion,
    currentQuestion,
    setFeedbackButton,
    setCurrentQuestion,
    getAssignmentDataPost,
    handleAnswerOptionClick,
  } = props;

  const { height, width } = useWindowDimensions();
  useEffect(() => {
    console.log("Mounted Formative Quize");
  }, []);

  return (
    <div className="body" style={{ height: height - 400 }}>
      {btnClick ? (
        <div className="app1">
          {showScore ? (
            <div className="score-section">
              <div>
                You scored {score} out of {quizQuestion.length}
                {(score / quizQuestion.length) * 100 >= parseInt(cutOff) ||
                tryAgain ? (
                  <div>
                    <button
                      className="btnQuizLD"
                      style={{
                        width: "200px",
                        border: "3px solid white",
                        fontSize: "15px",
                      }}
                      onClick={() => {
                        markComplete();
                        setScore(0);
                        setTryAgain(false);
                        setFormativeAns([]);
                      }}
                    >
                      Mark Complete
                    </button>
                    <button
                      className="btnQuizLD"
                      style={{
                        width: "200px",
                        border: "3px solid white",
                        fontSize: "15px",
                      }}
                      onClick={() => {
                        // getQuiz(curObject);
                        setCurrentQuestion(0);
                        setAnswerReveal(true);
                        setShowScore(false);
                        //setQIsLoading(true);
                      }}
                    >
                      {qisLoading ? (
                        <CircularProgress color="default" size={30} />
                      ) : (
                        "Answers"
                      )}
                    </button>
                  </div>
                ) : (
                  <div style={{ fontSize: "16px" }}>
                    {" "}
                    You should score more than {cutOff}% to complete this Quiz.
                  </div>
                )}
              </div>
              {(score / quizQuestion.length) * 100 < parseInt(cutOff) &&
              tryAgain === false ? (
                <div>
                  <button
                    className="buttonQuizCt"
                    onClick={() => {
                      setBtnClick(false);
                      getQuiz(curObject);
                      setScore(0);
                      setShowScore(false);
                      setCurrentQuestion(0);
                      setTryAgain(true);
                      setFormativeAns([]);
                    }}
                  >
                    Try Again
                  </button>
                  <button
                    className="buttonQuizCt"
                    onClick={() => {
                      getAssignmentDataPost();
                      setBtnClick(false);
                      handleClose();
                      setScore(0);
                      setCurrentQuestion(0);
                      setTryAgain(false);
                      setFormativeAns([]);
                    }}
                  >
                    Exit Assignment
                  </button>
                </div>
              ) : null}
            </div>
          ) : answerReveal ? (
            <div>
              <div className="scrollQuiz">
                <div className="question-section">
                  <div className="question-count">
                    <span>Question {currentQuestion + 1}</span>/
                    {quizQuestion.length}
                  </div>
                  <div
                    className="question-text"
                    dangerouslySetInnerHTML={{
                      __html: quizQuestion[currentQuestion].istem,
                    }}
                  ></div>

                  {quizQuestion[currentQuestion].imageurl ? (
                    <div className="img_container">
                      <img
                        src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                        alt="item"
                      />
                    </div>
                  ) : null}
                </div>

                <div className="answer-section">
                  {quizQuestion[currentQuestion].iopts.map(
                    (answerOption, idx) => (
                      <div style={{ display: "flex" }}>
                        {" "}
                        {/* {formativeAns[currentQuestion] !== idx ? (
                                <button className={`buttonQuizAR`}>
                                    {answerOption.content}
                                </button>
                                ) : (
                                    <button
                                        className={`buttonQuizAR`}
                                        style={{
                                        backgroundColor: "lightgray",
                                        border: "2px solid #234668",
                                        }}>
                                        {answerOption.content}
                                    </button>
                                )} */}
                        {answerOption.Myselection != "true" ? (
                          <button className={`buttonQuizAR`}>
                            {answerOption.content}
                          </button>
                        ) : (
                          <button
                            className={`buttonQuizAR`}
                            style={{
                              backgroundColor: "lightgray",
                              border: "2px solid #234668",
                            }}
                          >
                            {answerOption.content}
                          </button>
                        )}
                        {answerOption.correct == true ||
                        answerOption.correct == "true" ? (
                          <Done
                            className="right_size"
                            cls1="cls1_D"
                            cls2="cls2_D"
                          />
                        ) : (
                          <Reject
                            className="Wrong_size"
                            cls1="cls1_D"
                            cls2="cls2_D"
                          />
                        )}
                        {answerOption.Myselection != "true" ? null : (
                          <p
                            style={{
                              fontSize: "14px",
                              paddingLeft: "8px",
                              paddingTop: "8px",
                            }}
                          >
                            Your Response
                          </p>
                        )}
                      </div>
                    )
                  )}
                  <br />
                </div>

                {currentQuestion > 0 ? (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    className="btn-size"
                    onClick={() => answerPrev()}
                    style={{ fontSize: "12px" }}
                  >
                    Prev
                  </Button>
                ) : null}
                {currentQuestion + 1 === quizQuestion.length ? (
                  <div></div>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    className="btn-siz"
                    onClick={() => answerNext()}
                    style={{
                      float: "right",
                      fontSize: "12px",
                      margin: "0rem 0rem 2rem",
                    }}
                  >
                    Next
                  </Button>
                )}
                <br />
                <br />
                <br />

                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  className="btn-sizeClose"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    width: "100px",
                    marginLeft: "45%",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    //handleClose();
                    setCurrentQuestion(0);
                    setShowScore(true);
                    setAnswerReveal(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          ) : (
            <div className="scrollQuiz">
              <div className="question-section">
                <div className="question-count">
                  <span>Question{currentQuestion + 1}</span>/
                  {quizQuestion.length}
                </div>
                <div
                  className="question-text"
                  dangerouslySetInnerHTML={{
                    __html: quizQuestion[currentQuestion].istem,
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `${
                    quizQuestion[currentQuestion].imageurl ? "4fr" : "1fr"
                  } `,
                  gridGap: "8rem",
                }}
              >
                <center>
                  <div style={{ width: "270px" }}>
                    {quizQuestion[currentQuestion].imageurl ? (
                      <div className="img_container">
                        <img
                          src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                          alt="item"
                        />
                      </div>
                    ) : null}
                  </div>
                </center>
                <div>
                  {quizQuestion[currentQuestion].iopts.map(
                    (answerOption, idx) => (
                      <button
                        key={"a" + idx}
                        // className="buttonQuiz"
                        className={`buttonQuiz`}
                        data-id={`${
                          answerOption.correct == true
                            ? answerOption.correct
                            : null
                        }`}
                        style={{
                          borderColor:
                            answerOption.Myselection == "true" ? "#0f80a4" : "",
                        }}
                        onClick={() => {
                          let anscount = ansFcount;
                          if (
                            quizQuestion[currentQuestion].Qsubmit == undefined
                          ) {
                            setAnsFcount(ansFcount + 1);
                            anscount += 1;
                          }
                          setQuizQuestion((currentQues) =>
                            produce(currentQues, (v) => {
                              v[currentQuestion].Qsubmit = "true";
                              v[currentQuestion].iopts.forEach((opt) => {
                                opt.Myselection = "false";
                              });
                              v[currentQuestion].iopts[idx].Myselection =
                                "true";
                            })
                          );
                          handleAnswerOptionClick(answerOption, idx, anscount);
                          console.log("ans", answerOption, idx);
                        }}
                      >
                        {answerOption.content}
                      </button>
                    )
                  )}
                  <div style={{ padding: "3%" }}>
                    {currentQuestion > 0 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="btn-size"
                        onClick={() => answerPrev()}
                        style={{ fontSize: "12px" }}
                      >
                        Prev
                      </Button>
                    ) : null}
                    {currentQuestion + 1 === quizQuestion.length ? (
                      <div></div>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="btn-siz"
                        onClick={() => answerNext()}
                        style={{ float: "right", fontSize: "12px" }}
                      >
                        Next
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div style={{ height: "100%", width: "100%", overflowY: "none" }}>
          <div className="body" style={{ height: height - 400 }}>
            <div style={{ fontSize: "14px" }}>
              <ul>
                <li>
                  <h3>Instructions:</h3>
                </li>
              </ul>
              <ol style={{textAlign:"left"}}>
                <li>
                  
                  Unit formative quiz consists of Multiple Choice Questions.
                </li>
                <li> Click on "START QUIZ" button.</li>
                <li> On starting the quiz, One question appears at a time.</li>
                <li>
                  {" "}
                  Once you select an answer, you will get the next question.
                </li>
                {curObject.cutoff != undefined && (
                  <li>
                    On answering all the questions, you should get a score of{" "}
                    {curObject.cutoff}% or more to view the right answers and
                    then click on "MARK COMPLETE" button.
                  </li>
                )}
                <li>
                  Once you click on "MARK COMPLETE", the system would allow you
                  to move to the next Unit.
                </li>
                {curObject.cutoff != undefined && (
                  <li>
                    If you get {`<`} {curObject.cutoff}% score, you must click
                    on "TRY AGAIN" button to retake the quiz and improve your
                    score.
                  </li>
                )}
              </ol>
              <br />
              {qisLoading ? (
                <CircularProgress color="default" size={30} />
              ) : (
                <button
                  className="btnQuizLD"
                  style={{margin:"auto"}}
                  onClick={() => {
                    getQuiz(curObject);
                    setShowScore(false);
                    setQIsLoading(true);
                    setFeedbackButton({
                      disableSubmit: true,
                      hideNext: true,
                      hideSubmit: false,
                      submitted: false,
                    });
                  }}
                >
                  Start Quiz
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Formative;
